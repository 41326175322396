import React, { useState } from "react";

import {
    Dimensions,
    StyleSheet,
    View,
    Text,
    ActivityIndicator,
    TouchableOpacity,
    Linking,
    Platform
} from 'react-native';
import AppTheme from "../../../shared/utils/Theme";

// SVG
import SVGView from "../../../shared/utils/SvgView";
import { default as IcRefresh } from "../../../shared/assets/svg/icons/solid/redo.svg";
import { default as IcClose } from "../../../shared/assets/svg/icons/solid/times.svg";
import { default as IcPrint } from "../../../shared/assets/svg/icons/solid/print.svg";


import * as Constants from "../../../shared/utils/Constants";
import Style from "../../../shared/utils/Style";
import { app } from "../../services/Specific_Database";

const PDFViewer = (props: any) => {

    const appTheme: AppTheme = new AppTheme();
    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const { pdfUrl, color, title } = props;

    const closeTheDocument = () => {
        if (Platform.OS === "web") {
            document.body.setAttribute("style", "");
        }
        if (props.closeTheDocument !== undefined) {
            props.closeTheDocument();
        }
    }

    const openFile = () => {
        Linking.openURL(pdfUrl);
    }

    const onViewDidAppear = () => {
        if (Platform.OS === "web") {
            document.body.setAttribute("style", "position:fixed;");
        }
        setViewDidAppear(true);
    }

    const renderContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight(), justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator />
            </View>
        }
        return <View style={{ flex: 1 }}>
            <View style={[{ backgroundColor: color, height: appTheme.pixelPerfect(40), width: appTheme.getFullAppWidth(), flexDirection: "row" }]}>
                <View style={{ paddingStart: appTheme.pixelPerfect(40), flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Text style={[Style.navBarTitle, { fontFamily: appTheme.primaryFont, color: appTheme.white, fontSize: appTheme.pixelPerfectForFont(10) }]}>{title.toLocaleUpperCase()}</Text>
                </View>
                <TouchableOpacity onPress={closeTheDocument} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), justifyContent: "center", alignItems: "center" }}>
                    <SVGView Component={IcClose} width={appTheme.pixelPerfect(20)} height={appTheme.pixelPerfect(20)} color={appTheme.white} />
                </TouchableOpacity>
            </View>
            <TouchableOpacity onPress={openFile} style={{ width: appTheme.getFullAppWidth(), height: appTheme.pixelPerfect(20), justifyContent: "center", alignItems: "center", backgroundColor: appTheme.white, }}>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(8) }}>Si vous avez des difficultés à voir le document, cliquez ici.</Text>
            </TouchableOpacity>
            <object width={appTheme.getFullAppWidth()} height={appTheme.getFullAppHeight()} data={pdfUrl} >
            </object>
        </View>
        return <View style={{ flex: 1 }}>
            <View style={[{ backgroundColor: color, height: appTheme.pixelPerfect(40), width: appTheme.getFullAppWidth(), flexDirection: "row" }]}>
                <View style={{ paddingStart: appTheme.pixelPerfect(40), flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Text style={[Style.navBarTitle, { fontFamily: appTheme.primaryFont, color: appTheme.white, fontSize: appTheme.pixelPerfectForFont(10) }]}>{title.toLocaleUpperCase()}</Text>
                </View>
                <TouchableOpacity onPress={closeTheDocument} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), justifyContent: "center", alignItems: "center" }}>
                    <SVGView Component={IcClose} width={appTheme.pixelPerfect(20)} height={appTheme.pixelPerfect(20)} color={appTheme.white} />
                </TouchableOpacity>
            </View>
            <TouchableOpacity onPress={openFile} style={{ width: appTheme.getFullAppWidth(), height: appTheme.pixelPerfect(20), justifyContent: "center", alignItems: "center", backgroundColor: appTheme.white, }}>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(8) }}>Si vous avez des difficultés à voir le document, cliquez ici.</Text>
            </TouchableOpacity>
            <iframe width={appTheme.getFullAppWidth()} height={appTheme.getFullAppHeight()-appTheme.pixelPerfect(60)} src={pdfUrl} >
            </iframe>
        </View>
    }

    return renderContent();

}

export default PDFViewer;