import React, { useState } from "react";

import {
    Image,
    Linking,
    Platform,
    StyleSheet,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import Images from "../../../specific/utils/Images";
import AppTheme from "../../utils/Theme";

import { createAnonymousSession, createUserWithGARData, deleteCurrentSession, deleteCurrentUser, getCurrentSession, getCurrentUser, getUserWithGARIDO, getUserWithSessionId, redirectToCASLogin, updateSessionAndTicketForGarUser, updateUserPrefs, updateUserTicketPref } from "../../../specific/services/AppWrite/AppWriteDatabase";
import Main from "../../../specific/components/Main/Main";
import MainOptions from "../../models/MainOptions";
import Footer from "../../designSystem/Footer/Footer";
import ComponentProps from "../../../specific/utils/Interfaces/ComponentProps";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import { checkCASAuthentication } from "../../../specific/services/CAS/cas";
import User from "../../data/user/User";
import * as DateUtils from "../../utils/DateUtils";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Constants from "../../utils/Constants";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { removeItemFromLocalStorage } from "../../../specific/utils/LocalStorage";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import { handleUrl } from "../../../specific/utils/Deeplink/Deeplink";
import { addContactToGeneralAudience } from "../../services/Mailing";
import { initAnalyticsServices, initUserIdForAnalytics, setUserProperty, trackEvent } from "../../services/Tracking/Analytics";
import { getAppTarget } from "../../../specific/utils/Navigation/Host";

interface CasSplashscreenProps extends ComponentProps {

}

const CasSplashscreen = (props: CasSplashscreenProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const { componentId } = props;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    // MainOptions
    const mainOptions: MainOptions = new MainOptions({
        key: "cas_splashscreen",
        componentId,
        showNavBar: false,
        showMenu: false,
        canGoBack: false,
        safeArea: false
    });

    let navigate: NavigateFunction | null = null;
    let location: Location | null = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
        location = useLocation();
    }

    const checkAuthForCAS = async (forceCasAuth?: boolean) => {
        try {
            const user: User = User.getInstance();
            const appTarget = getAppTarget();
            let currentAccount = await getCurrentUser();
            let needCasAuth = forceCasAuth ?? false;
            if ((currentAccount !== null) && (currentAccount !== undefined)) {
                if ((currentAccount.prefs !== undefined) && (currentAccount.prefs.app !== undefined)) {
                    if (currentAccount.prefs.app !== appTarget) {
                        await deleteCurrentSession();
                        user.deleteUser();
                    }
                }
            }
            let sessionAlreadyExist = true;
            let existingSession = await getCurrentSession();
            let ticket = null;
            let userAttibuted = null;
            let ido = null;
            let pro = null;
            let p_ms2 = null;
            let uai = null;
            let email = "noreply";
            let firstname = "";
            let lastname = "";
            let demoUser = false;
            if ((existingSession === null) || (needCasAuth === true)) {
                sessionAlreadyExist = false;
                //const authResult = await checkCASConnexion();
                const authResult = await checkCASAuthentication();
                userAttibuted = authResult.user;
                ticket = authResult.ticket;
                const { IDO = [], PRO = [], P_MS2 = [], UAI = [], P_MEL = [], NOM = [], PRE = [] } = userAttibuted;
                if (IDO[0] !== undefined) {
                    ido = IDO[0];
                    pro = PRO[0] ?? "";
                    uai = UAI[0] ?? "";
                    p_ms2 = P_MS2;
                    firstname = PRE[0] ?? "";
                    lastname = NOM[0] ?? "";
                    if (P_MEL.length > 0) {
                        for (const aMelIndex in P_MEL) {
                            if (Object.prototype.hasOwnProperty.call(P_MEL, aMelIndex)) {
                                const aMel = P_MEL[aMelIndex];
                                if ((aMel.length > 0) && (aMel.indexOf("noreply") === -1)) {
                                    email = aMel;
                                    break;
                                }
                            }
                        }
                    }
                    if (existingSession === null) {
                        //Aucune sessions en cours. On va en crééer une et la rattacher à l'utilisateur connecté
                        await createAnonymousSession();
                        existingSession = await waitForSessionToBeActive();
                        await updateUserPrefs({ido, ticket, app:appTarget});
                    }
                } else {
                    // On va regarder si on n'est pas dans un compte TNE
                    const { codeUAI = [], idAbonne = [], role = [], roleCode = [], emailUser = [], nomUser = [], prenomUser = [] } = userAttibuted;
                    if (codeUAI[0] !== undefined) {
                        demoUser = true;
                        // On a un utilisateur TNE
                        ido = idAbonne[0];
                        pro = role[0] ?? "";
                        uai = codeUAI[0] ?? "";
                        p_ms2 = roleCode;
                        firstname = prenomUser[0] ?? "";
                        lastname = nomUser[0] ?? "";
                        email = emailUser[0] ?? "";
                        if (existingSession === null) {
                            //Aucune sessions en cours. On va en crééer une et la rattacher à l'utilisateur connecté
                            await createAnonymousSession();
                            existingSession = await waitForSessionToBeActive();
                            await updateUserPrefs({ido, ticket, app:appTarget});
                        }
                    }
                }
            }
            currentAccount = await getCurrentUser();
            let userAccount = null;
            if (ido !== null) {
                userAccount = await getUserWithGARIDO(ido);
            } else {
                userAccount = await getUserWithSessionId(existingSession.$id);
            }
            // On va regarder si l'utilisateur est sur la bonne plateforme
            // Si appTarget === gar, alors userAccount.demo === false
            // Si appTarget === canope, alors userAccount.demo === true
            let forceReauthUser = false;
            if (userAccount === null) {
                if (ido === null) {
                    forceReauthUser = true;
                }
            }

            if (forceReauthUser === true) {
                await deleteCurrentUser();
                user.deleteUser();
                checkAuthForCAS(true);
            } else {
                if (userAccount === null) {
                    // First session
                    // Ajouter un élément qui puisse indiquer que l'utilisateur est dmeo ou non
                    userAccount = await createUserWithGARData({ ido, pro, p_ms2, uai, sessionId: existingSession.$id, ticket, authId: currentAccount.$id, firstname, email, lastname, demo: demoUser });
                } else {
                    // On va mettre à jour les données de la session dans l'objet utilisateur
                    if ((userAccount.gar_ticket === null) || (userAccount.gar_ticket.length === 0)) {
                        if (ticket === null) {
                            const authResult = await checkCASAuthentication();
                            userAttibuted = authResult.user;
                            ticket = authResult.ticket;
                        }
                    }
                    if ((ticket !== null) && (ticket !== userAccount.gar_ticket)) {
                        await updateSessionAndTicketForGarUser({ sessionId: existingSession.$id, ticket, userId: userAccount.$id, authId: currentAccount.$id });
                    }
                }
                // Création de l'instance User-
                user.uid = userAccount.$id;
                user.email = userAccount.email;
                user.demo = userAccount.demo;
                let context = "demo";
                if (userAccount.demo === false) {
                    context = "tne";
                    user.sub = {
                        customer_email: userAccount.email,
                        date: new Date().getTime(),
                        expires_date: DateUtils.getEndOfTheSchoolYearDate(),
                        origin: appTarget,
                        product_id: "cool.lili.subnr.lastwelve",
                        start_date: new Date().getTime(),
                        auto_renewing: false
                    }
                }
                user.tne_ido = userAccount.IDO;
                user.tne_pro = userAccount.PRO;
                user.tne_p_ms2 = userAccount.P_MS2;
                user.tne_uai = userAccount.UAI;
                user.version = userAccount.version;
                user.build = userAccount.build;
                user.creation_version = userAccount.creation_version;
                user.creation_build = userAccount.creation_build;
                user.code = { code: "202211", key: "tne" };
                user.avatar = "base";
                user.profile = "teacher";
                user.favorite_books = [];
                user.favorite_activities = [];
                user.firstname = userAccount.firstname,
                user.lastname = userAccount.lastname;
                if (user.email !== "noreply") {      
                    addContactToGeneralAudience({email:user.email, context, optIn:true, optInCoach:false, uid:user.uid, firstname:user.firstname, lastname:user.lastname});
                }
                // On ajoute les paramètres Amplitude
                initUserIdForAnalytics(user.uid);
                if (user.tne_uai !== undefined) {
                    // On va récupérer les 3 premiers chiffres de l'UAI qui contiennent le code département
                    // On le transforme en int, pour avoir le vrai numéro du département
                    const depAsString: string = user.tne_uai.substring(0, 3);
                    const depAsNumber = parseInt(depAsString);
                    setUserProperty({ key: "school_department", value: depAsNumber.toString() });
                    setUserProperty({ key: "school_uai", value: user.tne_uai });
                }
                setUserProperty({ key: "profile", value: "teacher_" + context });
                setUserProperty({ key: "subscription_status", value: "premium_tne" });
                setUserProperty({ key: "subscription_end_date", value: DateUtils.getNumericDateForAnalyticForTimestamp(DateUtils.getEndOfTheSchoolYearDate()) });
                await AsyncStorage.setItem(Constants.keyUserData, JSON.stringify(user));
                if (ticket !== null) {
                    // On vire le ticket de l'URL
                    if (appTarget === "gar-prod") {
                        window.location.href = "https://app-tne.lili.cool/";
                    } else if (appTarget === "gar-preprod") {
                        window.location.href = "https://app-tne-pp.lili.cool/";
                    } else {
                        window.location.href = "https://app-tne-demo.lili.cool/";
                    }
                } else {
                    enterAppOnceConnected();
                }
                setIsLoading(false);
            }
        } catch (error) {
            await AsyncStorage.removeItem(Constants.keyUserData);
            setIsLoading(false);
        }
    }

    // Voir comment rendre en commun les ordres de navigation entre les 2 splashcreens
    const enterAppOnceConnected = () => {
        // On va regarder si l'URL est splaschreen ou deeplink
        if (Platform.OS === "web") {
            if (location.pathname.indexOf("deeplink") !== -1) {
                let urlToNavigate = location.pathname.replace("/deeplink/", "");
                navigate(decodeURIComponent(urlToNavigate), { state: "deeplink" });
            } else {
                launchApp();
            }
        } else {
            launchApp();
        }
    }

    const launchApp = () => {
        const user: User = User.getInstance();
        let context = Constants.CONTEXT.family;
        if ((user.code !== undefined) && (user.code.code !== undefined)) {
            context = Constants.CONTEXT.teacher;
        }
        removeItemFromLocalStorage({ key: "appMenuContext" });
        let screenTarget = Constants.ScreenAppMenu;
        Navigation.showModal({
            componentId,
            navigate,
            name: screenTarget,
            passProps: {
                context
            }
        });
        // On va regarder si l'utilisateur avait ouvert l'app avec un universal linkg
        Linking.getInitialURL().then((initialUrl) => {
            handleUrl({ url: initialUrl, isLaunchedUrl: true, componentId, navigate });
        });
    }

    const waitForSessionToBeActive = async () => {
        let existingSession = await getCurrentSession();
        if (existingSession === null) {
            setTimeout(() => {
                waitForSessionToBeActive();
            }, 500);
        } else {
            return existingSession;
        }
    }

    const onViewDidAppear = () => {
        initAnalyticsServices();
        checkAuthForCAS();
    }

    const getContentView = () => {
        if (isLoading === false) {
            return <ActivityIndicator color={appTheme.secondaryColor} />;
        }
        return <View style={{ maxWidth: appTheme.getBlockWidth(), marginHorizontal: appTheme.pixelPerfect(20), backgroundColor: appTheme.schoolColor, justifyContent: "center", alignItems: "center", paddingHorizontal: appTheme.pixelPerfect(40), paddingVertical: appTheme.pixelPerfect(10), borderRadius: appTheme.pixelPerfect(10) }}>
            <Image source={images.illTeacherCat} style={{ width: appTheme.pixelPerfect(100), height: appTheme.pixelPerfect(100) }} resizeMode="contain" />
            <Text style={{ fontSize: appTheme.pixelPerfectForFont(14), color: appTheme.white, fontFamily: appTheme.primaryBoldFont, textAlign: "center" }}>Bienvenue sur LILI</Text>
            <Text style={{ fontSize: appTheme.pixelPerfectForFont(14), color: appTheme.white, fontFamily: appTheme.secondaryFont, textAlign: "center" }}>Veuillez passer par votrer ENT pour vous connecter.</Text>
        </View>;
    }

    const top = (appTheme.getFullAppHeight() - appTheme.pixelPerfect(67)) / 2;
    const left = (appTheme.getFullScreenWidth() - appTheme.pixelPerfect(121)) / 2;

    return (
        <Main
            componentId={componentId}
            mainOptions={mainOptions} >
            <View
                onLayout={onViewDidAppear}
                style={{ width: appTheme.getFullScreenWidth(), height: appTheme.getFullScreenHeight(), backgroundColor: appTheme.still, justifyContent: "center", alignItems: "center" }}>
                <Image source={images.icTalk} style={{ left, position: "absolute", top, width: appTheme.pixelPerfect(121), height: appTheme.pixelPerfect(67), opacity: 1 }} resizeMode="contain" />
                {getContentView()}
                <Footer />
            </View>
        </Main>
    )
}

export default CasSplashscreen;