import React, { useEffect, useState } from "react";

import {
    Modal,
    Platform,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import RoundIconButton from "../../../designSystem/RoundIconButton";
import i18n, { getLanguage } from "../../../utils/Localization/Localization";
import Style from "../../../utils/Style";
import AppTheme from "../../../utils/Theme";

import { trackEvent, analyticsEventActivityLaunch, propertiesEventContext, propertiesEventContent, propertiesEventAccess, propertiesEventCategory, propertiesEventCompleted, propertiesEventSection, propertiesEventName, analyticsEventActivityCompleted, analyticsEventActivityDownloaded, propertiesEventProfile, analyticsEventWorkshopLaunched, analyticsEventWorkshopCompleted, analyticsEventPaywallDisplayed, propertiesEventSource, analyticsEventMediaLaunch, propertiesEventContentName } from "../../../services/Tracking/Analytics";
import PremiumButton from "../../../designSystem/PremiumButton";
import PlayButton from "../../../designSystem/PlayButton";
import Activity from "../../../models/Activity";
import User from "../../../data/user/User";
import PDFViewer from "../../../../specific/components/PDF/PDFViewer";
import BookPreview from "../../Preview/BookPreview";
import { onActivityCompleted } from "../../../../specific/services/Specific_Database";
import ActivityIndicator from "../../../designSystem/ActivityIndicator/ActivityIndicator";
import SVGView from "../../../utils/SvgView";
import { default as icDismiss } from "../../../assets/svg/icons/solid/times.svg";
import { getUrlToUse } from "../../../utils/LocalStorage";
import ActivityPlayer from "../../Audio/ActivityPlayer";
import { getAppTarget } from "../../../../specific/utils/Navigation/Host";
import { getAudioUrlForMediaId, getDocumentUrlForMediaId } from "../../../../specific/services/AppWrite/AppWriteDatabase";


type localizedString = {
    fr?: string
}

interface ActivityCellDoubleProps {
    color: string,
    width: number,
    isEnable?: boolean,
    mainAction?: any,
    componentId?: string,
    context: string,
    categoryAnalyticsKey: string,
    sectionAnalyticsKey: string,
    activity: Activity,
    categoryKey: string,
    localImgPath: string,
    starting_point?: "superpower" | "section"
}

const ActivityCellDouble = (props: ActivityCellDoubleProps) => {
    const { color, width, isEnable = false, mainAction, componentId = "", context, categoryAnalyticsKey, sectionAnalyticsKey, activity, categoryKey, localImgPath, starting_point = "superpower" } = props;
    const appTheme: AppTheme = new AppTheme();
    const user: User = User.getInstance();

    const [showModal, setShowModal] = useState(false);
    const [viewDidAppear, setViewDidAppear] = useState(false);
    const [startTime, setStartTime] = useState(0); // On regarde à quel moment le PDF est ouvert pour la gamification
    const [steps, setSteps] = useState([]);
    const [mainUrls, setMainUrls] = useState<{ urls: any, url: string } | undefined>(undefined);
    const [stepIndex, setStepIndex] = useState(-1);
    const [stepAnswer, setStepAnswer] = useState("");
    const [showReplay, setShowReplay] = useState(false);
    const [mediaType, setMediaType] = useState("pdf");
    const [documentData, setDocumentData] = useState({ url: "", title: "", color: appTheme.darkBlue });
    const [previewData, setPreviewData] = useState({ title: "", img: "", age: "", id: "", owner: "", body: "", author: "", color: appTheme.darkBlue });
    const [showMedia, setShowMedia] = useState(false);

    useEffect(() => {
        if (stepIndex !== -1) {
            // On va récupérer les liens
            if (stepIndex === 0) {
                checkUrls();
            }
        }
    }, [stepIndex]);

    useEffect(() => {
        if (mainUrls !== undefined) {
            setSteps(activity.action.steps);
        }
    }, [mainUrls]);

    const checkUrls = async () => {
        let urlsToUpdate = {};
        let urlToUpdate: string = "";
        for (const aStepIndex in activity.action.steps) {
            if (Object.prototype.hasOwnProperty.call(activity.action.steps, aStepIndex)) {
                const stepToExecute = activity.action.steps[aStepIndex];
                if (stepToExecute !== undefined) {
                    if (stepToExecute.type === "audio_qcm") {
                        for (const anUrlIndex in stepToExecute.urls) {
                            if (Object.prototype.hasOwnProperty.call(stepToExecute.urls, anUrlIndex)) {
                                const anUrl: string = stepToExecute.urls[anUrlIndex];
                                if (Platform.OS === "web") {
                                    if (getAppTarget() !== "public") {
                                        // Créer l'url CAS
                                        let audioId = activity.key + "_" + anUrlIndex;
                                        urlsToUpdate[anUrlIndex] = getAudioUrlForMediaId({ media_id: audioId });
                                    } else {
                                        urlsToUpdate[anUrlIndex] = anUrl;
                                    }
                                } else {
                                    const urlToPlay: string = await getUrlToUse({ url: anUrl, activityKey: activity.key });
                                    urlsToUpdate[anUrlIndex] = urlToPlay;
                                }
                            }
                        }
                    } else if (stepToExecute.type === "audio") {
                        const anUrl: string = stepToExecute.url;
                        if (Platform.OS === "web") {
                            if (getAppTarget() !== "public") {
                                // Créer l'url CAS
                                let audioId = activity.key + "_final";
                                urlToUpdate = getAudioUrlForMediaId({ media_id: audioId });
                            } else {
                                const urlToPlay: string = await getUrlToUse({ url: anUrl, activityKey: activity.key });
                                urlToUpdate = urlToPlay;
                            }
                        } else {
                            const urlToPlay: string = await getUrlToUse({ url: anUrl, activityKey: activity.key });
                            urlToUpdate = urlToPlay;
                        }
                    }
                }
            }
        }
        setMainUrls({ urls: urlsToUpdate, url: urlToUpdate });
    }

    useEffect(() => {
        if (steps.length > 0) {
            setViewDidAppear(true);
        }
    }, [steps]);

    const onViewDidAppear = (e) => {
        if (viewDidAppear === false) {
            setStepIndex(0);
        }
    }

    // Main Action
    const onPressMediaCell = (media: any) => {
        setMediaType(media.type);
        if (media.type === "pdf") {
            trackEvent(analyticsEventActivityLaunch, {
                [propertiesEventCategory]: categoryAnalyticsKey,
                [propertiesEventContent]: media.type,
                [propertiesEventContentName]: media.id,
                [propertiesEventAccess]: activity.isPremium() === true ? "premium" : "free",
                [propertiesEventName]: activity.analytics_key
            });
            setStartTime(new Date().getTime());
            let pdfUrl = media.url;
            const appTarget = getAppTarget();
            if (appTarget !== "public") {
                pdfUrl = getDocumentUrlForMediaId({ media_id: media.id });
            }
            setDocumentData({
                url: pdfUrl,
                title: media.title[getLanguage()],
                color
            });
            setShowMedia(true);
        } else if (media.type === "preview") {
            trackEvent(analyticsEventMediaLaunch, {
                [propertiesEventCategory]: categoryAnalyticsKey,
                [propertiesEventContent]: media.type,
                [propertiesEventContentName]: media.id,
                [propertiesEventAccess]: activity.isPremium() === true ? "premium" : "free",
                [propertiesEventName]: activity.analytics_key
            });
            setPreviewData({
                title: media.title[getLanguage()],
                img: media.img,
                age: media.age,
                id: media.id,
                owner: media.owner,
                body: media.body,
                author: media.author,
                color
            })
            setShowMedia(true);
        }
    }

    const getMediaModalContent = () => {
        if (viewDidAppear === false) {
            return <View />;
        }
        if (mediaType === "pdf") {
            return <PDFViewer
                title={documentData.title}
                pdfUrl={documentData.url}
                color={documentData.color}
                sectionId={activity.sectionKey}
                closeTheDocument={closeTheDocument} />;
        } else {
            return <BookPreview
                preview={previewData}
                sectionId={activity.sectionKey}
                closeTheDocument={closeTheDocument} />;
        }
    }

    const closeTheDocument = () => {
        if (mediaType === "pdf") {
            // On va clore la lecture pour la gamification
            const timeOfLeaving = new Date().getTime();
            const readingTime = timeOfLeaving - startTime;
            if (readingTime > 9999) {
                onActivityCompleted({
                    activityKey: activity.key,
                    sectionKey: activity.sectionKey,
                    categoryKey,
                    iteration: 1,
                    detail: activity.sectionKey === 'grow' ? 'philo_notebook' : '',
                    menu: ""
                })
            };
            setDocumentData({
                url: "",
                title: "",
                color
            });
        } else {
            setPreviewData({
                title: "",
                img: "",
                age: "",
                id: "",
                owner: "",
                body: "",
                author: "",
                color: ""
            })
        }
        setShowMedia(false);
    }

    let mainActionButtonView = <View />;
    if (mainAction !== undefined) {
        const { icon, media } = mainAction;
        const { name } = icon;
        const mainActionButton = isEnable === true ? <RoundIconButton onPress={() => onPressMediaCell(media)} iconName={name} backgroundColor={color} /> : <PremiumButton componentId={componentId} />;
        mainActionButtonView = <View style={{ alignItems: "center", marginStart: appTheme.pixelPerfect(20) }}>
            <View style={[Style.shadowed, { marginBottom: appTheme.pixelPerfect(10), borderRadius: appTheme.pixelPerfect(40) }]}>
                {mainActionButton}
            </View>
            <Text style={{ fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(9), color: color, width: appTheme.pixelPerfect(80), textAlign: "center" }}>{media.title[getLanguage()].toLocaleUpperCase()}</Text>
        </View>;
    }


    // Audio
    const togglePlayer = () => {
        setStepAnswer("");
        setStepIndex(0);
        if (showModal === false) {
            trackEvent(analyticsEventActivityLaunch, {
                [propertiesEventCategory]: categoryAnalyticsKey,
                [propertiesEventContent]: "audio",
                [propertiesEventAccess]: activity.isPremium() === true ? "premium" : "free",
                [propertiesEventName]: activity.analytics_key,
                "starting_point": starting_point
            });
        }
        setShowModal(!showModal);
    }

    const cancelTheAction = () => {
        setShowReplay(false);
        setStepAnswer("");
        setStepIndex(0);
        setShowModal(false);
    }

    const selectAnswer = (anAnswer) => {
        setStepAnswer(anAnswer.key);
        setStepIndex(stepIndex + 1);
    }

    const onPlayerEnd = () => {
        setShowReplay(true);
        setTimeout(() => {
            setShowModal(true);
        }, 500);
    }

    const onReplayChoice = (replay: boolean) => {
        if (replay === true) {
            setShowReplay(false);
        } else {
            setStepIndex(stepIndex + 1);
            setShowReplay(false);
        }
    }

    const onActivityCompletedForGamification = () => {
        if (stepIndex + 1 === activity.action.steps.length) {
            onActivityCompleted({
                activityKey: activity.key,
                sectionKey: activity.sectionKey,
                categoryKey: categoryKey,
                iteration: 1,
                detail: "",
                menu: ""
            });

            trackEvent(analyticsEventActivityCompleted, {
                [propertiesEventCategory]: categoryAnalyticsKey,
                [propertiesEventContent]: "audio",
                [propertiesEventAccess]: activity.isPremium() === true ? "premium" : "free",
                [propertiesEventName]: activity.analytics_key,
                "starting_point": starting_point
            });
        }
    }

    const getModalContent = () => {
        if ((viewDidAppear === false) || (mainUrls === undefined) || (showModal === false)) {
            return <ActivityIndicator color={color} />
        }
        let stepToExecute = steps[stepIndex];
        if (stepToExecute !== undefined) {
            if (stepToExecute.type === "qcm") {
                const answers = stepToExecute.ans;
                let answersView = [];
                const answerWidth = appTheme.getBlockWidth() - appTheme.pixelPerfect(20);
                for (const anAnswerIndex in answers) {
                    if (Object.prototype.hasOwnProperty.call(answers, anAnswerIndex)) {
                        const anAnswer = answers[anAnswerIndex];
                        answersView.push(<TouchableOpacity onPress={() => selectAnswer(anAnswer)} style={{ alignItems: "center", justifyContent: "center", backgroundColor: appTheme.white, minHeight: appTheme.pixelPerfect(40), width: answerWidth, borderRadius: appTheme.pixelPerfect(20), marginBottom: appTheme.pixelPerfect(20) }}>
                            <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(14), color: color, textAlign: "center", textTransform: "uppercase" }}>{anAnswer.txt[getLanguage()]}</Text>
                        </TouchableOpacity>
                        )
                    }
                }
                return <View style={[Style.shadowed, { paddingTop: appTheme.pixelPerfect(10), backgroundColor: color, width: appTheme.getBlockWidth(), minHeight: appTheme.pixelPerfect(100), borderRadius: appTheme.pixelPerfect(10), alignItems: "center" }]}>
                    <View style={{ marginBottom: appTheme.pixelPerfect(20), width: appTheme.getBlockWidth(), paddingHorizontal: appTheme.pixelPerfect(10), alignItems: "flex-end" }}>
                        <TouchableOpacity onPress={cancelTheAction} style={{ backgroundColor: appTheme.white, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(15), justifyContent: "center", alignItems: "center" }}>
                            <SVGView Component={icDismiss} size={20} color={color} />
                        </TouchableOpacity>
                    </View>
                    <Text style={{ paddingHorizontal: appTheme.pixelPerfect(20), textAlign: "center", fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(16), color: appTheme.white }}>
                        {stepToExecute.qst[getLanguage()]}
                    </Text>
                    <View style={{ marginVertical: appTheme.pixelPerfect(20) }}>
                        {answersView}
                    </View>
                </View>
            } else if ((stepToExecute.type === "audio_qcm") || (stepToExecute.type === "audio")) {
                if (showReplay === false) {
                    let mainUrlToPlay = stepToExecute.type === "audio_qcm" ? mainUrls?.urls[stepAnswer] : mainUrls?.url;
                    let urls = [];
                    if (stepIndex + 1 === activity.action.steps.length) {
                        // Si on en est à la fin
                        urls = [mainUrlToPlay];
                        if (Platform.OS !== "web") {
                            if (getAppTarget() === "public") {
                                urls = [mainUrlToPlay, require("../../../assets/audio/lili_je_grandis.mp3")];
                            }
                        }
                    } else {
                        if (Platform.OS !== "web") {
                            if (getAppTarget() === "public") {
                                urls = [require("../../../assets/audio/lili_je_grandis.mp3"), mainUrlToPlay];
                            }
                        }
                        urls = [mainUrlToPlay];
                    }
                    
                    return <ActivityPlayer
                        activityKey={activity.key}
                        title={activity.title[getLanguage()]}
                        notificationImage={Platform.OS === "ios" ? activity.img_url : localImgPath}
                        onActivityCompleted={onActivityCompletedForGamification}
                        percentageForCompletion={30}
                        onPlayerEnd={onPlayerEnd}
                        urls={urls}
                        color={color}
                        localImage={localImgPath}
                        remoteImage={activity.img_url}
                        togglePlayer={togglePlayer} />
                } else {
                    return <View style={[Style.shadowed, { paddingTop: appTheme.pixelPerfect(10), backgroundColor: color, width, minHeight: appTheme.pixelPerfect(100), borderRadius: appTheme.pixelPerfect(10), alignItems: "center" }]}>
                        <View style={{ marginBottom: appTheme.pixelPerfect(20), width, paddingHorizontal: appTheme.pixelPerfect(10), alignItems: "flex-end" }}>
                            <TouchableOpacity onPress={cancelTheAction} style={{ backgroundColor: appTheme.white, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(15), justifyContent: "center", alignItems: "center" }}>
                                <SVGView Component={icDismiss} size={20} color={color} />
                            </TouchableOpacity>
                        </View>
                        <Text style={{ paddingHorizontal: appTheme.pixelPerfect(20), textAlign: "center", fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(16), color: appTheme.white }}>
                            Tu peux poursuivre ta réflexion en écoutant la suite ou réécouter ce que tu viens d'entendre
                        </Text>
                        <View style={{ marginVertical: appTheme.pixelPerfect(20) }}>
                            <TouchableOpacity onPress={() => onReplayChoice(true)} style={{ alignItems: "center", justifyContent: "center", backgroundColor: appTheme.white, width: appTheme.pixelPerfect(240), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), marginBottom: appTheme.pixelPerfect(20) }}>
                                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(14), color }}>{"Réécouter".toLocaleUpperCase()}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => onReplayChoice(false)} style={{ alignItems: "center", justifyContent: "center", backgroundColor: appTheme.white, width: appTheme.pixelPerfect(240), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), marginBottom: appTheme.pixelPerfect(20) }}>
                                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(14), color }}>{"Passer à la suite".toLocaleUpperCase()}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>;
                }
            }
        } else {
            //Etape finale
            return <View style={[Style.shadowed, { paddingTop: appTheme.pixelPerfect(10), backgroundColor: color, width, minHeight: appTheme.pixelPerfect(100), borderRadius: appTheme.pixelPerfect(10), alignItems: "center" }]}>
                <View style={{ marginBottom: appTheme.pixelPerfect(20), width, paddingHorizontal: appTheme.pixelPerfect(10), alignItems: "flex-end" }}>
                    <TouchableOpacity onPress={cancelTheAction} style={{ backgroundColor: appTheme.white, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(15), justifyContent: "center", alignItems: "center" }}>
                        <SVGView Component={icDismiss} size={20} color={color} />
                    </TouchableOpacity>
                </View>
                <Text style={{ paddingHorizontal: appTheme.pixelPerfect(20), textAlign: "center", fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(16), color: appTheme.white }}>
                    J'espère que tu as aimé. Tu peux enrichir ta réflexion en téléchargeant un carnet d'activités ou découvrir des histoires sur ce thème.
                </Text>
                <View style={{ marginVertical: appTheme.pixelPerfect(20) }}>
                    <TouchableOpacity onPress={cancelTheAction} style={{ alignItems: "center", justifyContent: "center", backgroundColor: appTheme.white, width: appTheme.pixelPerfect(240), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), marginBottom: appTheme.pixelPerfect(20) }}>
                        <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(14), color: color }}>{"OK".toLocaleUpperCase()}</Text>
                    </TouchableOpacity>
                </View>
            </View>;
        }
    }

    const actionButton = isEnable === true ? <PlayButton onPress={togglePlayer} backgroundColor={color} /> : <PremiumButton componentId={componentId} />;
    let actionButtonView = <View style={{ alignItems: "center" }}>
        <View style={[{ marginBottom: appTheme.pixelPerfect(10) }]}>
            {actionButton}
        </View>
        <Text style={{ fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(9), color: color }}>{i18n.t('media.podcast').toLocaleUpperCase()}</Text>
    </View>;

    return <View onLayout={onViewDidAppear} style={{ width, alignItems: "center", marginTop: appTheme.pixelPerfect(10) }}>
        <View style={{ width, flexDirection: "row", justifyContent: "center" }}>
            {actionButtonView}
            {mainActionButtonView}
        </View>
        <Modal animationType="slide" visible={showModal} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
            <View style={{ flex: 1, alignItems: "center", backgroundColor: appTheme.white + "80", width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, justifyContent: "flex-end", alignItems: "center", width: appTheme.getFullAppWidth() }}>
                    {getModalContent()}
                </View>
            </View>
        </Modal>
        <Modal animationType="slide" visible={showMedia} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
            <View style={{ flex: 1, alignItems: "center", backgroundColor: color + "80", width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, justifyContent: "flex-end", backgroundColor: color + "80", width: appTheme.getFullAppWidth() }}>
                    {getMediaModalContent()}
                </View>
            </View>
        </Modal>
    </View>
}

export default ActivityCellDouble;