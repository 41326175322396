import { CONFIG } from "./model";

const config: CONFIG = {
    isProduction: true,
    appName: "Lili",
    bundleId: "cool.lili.app",
    packageName: "cool.lili.app",
    theme: {
        primaryColor: "#123456",
        secondaryColor: "#404040"
    }
}

export default config;