import * as Constants from "../../../shared/utils/Constants";

export const screenWebPath = {
    [Constants.ScreenAbout]:Constants.ScreenAbout,
    [Constants.ScreenActivitiesBySection]:Constants.ScreenActivitiesBySection+"/:sectionKey",
    [Constants.ScreenActivitiesTags]:Constants.ScreenActivitiesTags,
    [Constants.ScreenActivitySheet]:Constants.ScreenActivitySheet+"/:context/:activityKey/:categoryKey/:sectionKey",
    [Constants.ScreenActivityPager]:Constants.ScreenActivityPager+"/:context/:activityKey/:categoryKey/:sectionKey",
    [Constants.ScreenActivityMediaList]:Constants.ScreenActivityMediaList,
    [Constants.ScreenAdminGifts]:Constants.ScreenAdminGifts,
    [Constants.ScreenAdminAmplitude]:Constants.ScreenAdminAmplitude,
    [Constants.ScreenAdminQuoteManagement]:Constants.ScreenAdminQuoteManagement,
    [Constants.ScreenActivityForm]:Constants.ScreenActivityForm,
    [Constants.ScreenAppMenu]:Constants.ScreenAppMenu,
    [Constants.ScreenAuthentication]:Constants.ScreenAuthentication+"/:context/:screen",
    [Constants.ScreenAuthLanding]:Constants.ScreenAuthLanding,
    [Constants.ScreenCategoryHome]:Constants.ScreenCategoryHome+"/:categoryKey/:section/:origin",
    [Constants.ScreenCardActivation]:Constants.ScreenCardActivation+"/:code",
    [Constants.ScreenContact]:Constants.ScreenContact,
    [Constants.ScreenDeeplink]:Constants.ScreenDeeplink+"/:link",
    [Constants.ScreenFullScreenCategory]:Constants.ScreenFullScreenCategory+"/:context/:category",
    [Constants.ScreenLegalMentions]:Constants.ScreenLegalMentions+"/:reference",
    [Constants.ScreenLogout]:Constants.ScreenLogout,
    [Constants.ScreenSplashscreen]:Constants.ScreenSplashscreen,
    [Constants.ScreenSelectedSubscription]:Constants.ScreenSelectedSubscription+"/:status/:type/:duration",
    [Constants.ScreenStripeCallback]:Constants.ScreenStripeCallback+"/:status/:subId",
    [Constants.ScreenSubscription]:Constants.ScreenSubscription+"/:status",
    [Constants.ScreenSuperPowersPlaylist]:Constants.ScreenSuperPowersPlaylist+"/:context/:superPowersKey",
    [Constants.ScreenSuperAdmin]:Constants.ScreenSuperAdmin,
    [Constants.ScreenTeacherForm]:Constants.ScreenTeacherForm,
    [Constants.ScreenUserProfile]:Constants.ScreenUserProfile,
    [Constants.ScreenUserRewards]:Constants.ScreenUserRewards,
    [Constants.ScreenSuperRoom]:Constants.ScreenSuperRoom+"/:path",
    [Constants.ScreenTicketForm]:Constants.ScreenTicketForm
}