module.exports = {
    "about": {
        "title": "À propos",
        "legalMentions": "Mentions légales",
        "personalData": "Données personnelles",
        "contact": "Nous contacter",
        "description":[
            "Lili est un assistant pédagogique conçu par des chercheurs et des enseignants pour enseigner les compétences psychosociales aux enfants de 3 à 12 ans. Apprendre à apprendre, savoir s’adapter, communiquer, coopérer et faire preuve d’esprit critique : des compétences essentielles à la réussite éducative, quand les compétences techniques deviennent de plus en plus vite caduques (OCDE, rapport Éducation 2030).",
            "Lili est proposé en 3 versions : à l’école, en périscolaire et à la maison. Accessible sur smartphone, tablette, ordinateur et tableau numérique interactif, Lili outille les enseignants animateurs et parents, pour apprendre les compétences psychosociales, développer l’empathie en prévention du harcèlement, et favoriser l’inclusion des enfants à besoins éducatifs particuliers.",
            "Utilisé dans 6000 classes, Lili s’est vu récompensé des Trophées de l’Éducation par un jury composé d’une centaine d’enseignants et de responsables académiques en 2021, a été choisi par le  Ministère de l’Education nationale et de la Jeunesse pour enseigner les compétences psychosociales dès la maternelle dans le cadre des Territoires Numériques Éducatifs en 2022, et a remporté la 2e place des start-ups de l’Éducation au concours international Global Edtech Start-up Awards en 2023."
        ]
    },
    "accessibility": {
        "openTheMenu": "Ouvrir le menu",
        "openUserProfile": "Ouvrir le profil utilisateur"
    },
    "activities": {
        "grow": {
            "media": {
                "intro": "Pour aller plus loin, tu peux découvrir des histoires sur ce thème"
            }
        },
        "workshop": {
            "media": {
                "intro": "Liste des supports pédagogiques"
            }
        },
        "media": {
            "intro": "Liste des supports"
        },
        "bio": {
            "media": {
                "intro": "Voici des activités à faire en lien avec ce rôle modèle."
            }
        },
        "tuto": {
            "goldenRules": "Les règles d'or",
            "rulesActionButtonTitle": "Suivant",
            "actionButtonLast": "Précédent",
            "actionButtonNext": "Suivant",
            "step": "Étape",
            "mediaChecking": "Vérification des médias....",
            "mediaDownloading": "Téléchargement des médias...",
            "cancel": " Annuler"
        },
        "summary": {
            "title": "Résumé du podcast"
        },
        "favorites": {
            "addAsFavTitle": "Super !",
            "addAsFavBody": "Tu as beaucoup aimé cette activité,\nretrouve-la dans ton profil.",
            "removeAsFavTitle": "C'est noté !",
            "removeAsFavBody": "Nous avons enlevé cette activité de tes favoris."
        },
        "downloads": {
            "addTitle": "Super !",
            "addBody": "Cette activité est maintenant disponible hors-ligne.\nRetrouve-la dans ton profil.",
            "removeTitle": "C'est noté !",
            "removeBody": "Cette activité n'est maintenant plus disponible hors-ligne."
        }
    },
    "app": {
        "title": "%{app_name} - %{version} - %{build}"
    },
    "appMenu": {
        "parents": {
            "activitiesTitle": "Choisis tes activités",
            "button": {
                "title": "Le coin\ndes super-parents",
                "body": "Conseils d'experts et programme\nbien-être pour les parents."
            }
        },
        "teachers": {
            "activitiesTitle": "Choisir les activités pédagogiques",
            "button": {
                "title": "La salle\ndes super-profs",
                "body": "Accompagnement, formation et bien-être pour votre développement professionnel !"
            }
        },
        "animators": {
            "activitiesTitle": "Choisir les activités pédagogiques",
            "button": {
                "title": "Le coin\ndes super-animateurs",
                "body": "Accompagnement, formation et bien-être pour votre développement professionnel !"
            }
        },
        "extra": {
            "teachers": {
                "title": "Imprimer le matériel pour la classe",
                "first": {
                    "title": "Le parcours",
                    "body": "Bien dans mes baskets"
                },
                "second": {
                    "title": "Le poster",
                    "body": "des super-pouvoirs"
                }
            },
            "animators": {
                "title": "Imprimer le matériel pour le centre",
                "first": {
                    "title": "Le parcours",
                    "body": "Bien dans mes baskets"
                },
                "second": {
                    "title": "Le poster",
                    "body": "des super-pouvoirs"
                }
            },
            "family": {
                "title": "Installe ta routine de super-héros",
                "first": {
                    "title": "Bien dans mes baskets",
                    "body": "chaque jour"
                },
                "second": {
                    "title": "Bien m'endormir",
                    "body": "chaque soir"
                }
            }
        },
        "profileTitle": "Mon\nprofil",
        "switchMenu": {
            "coachManual": "Pour les parents",
            "about": "À propos",
            "profile": "Mon profil"
        }
    },
    "authentication": {
        "landing": {
            "signin": "J'ai déjà un compte",
            "signup": "Je m'inscris"
        },
        "animator": {
            "introduction": {
                "title": "Faire grandir la confiance sur le temps périscolaire, c’est facile et ludique avec Lili !",
                "body": "Lili offre aux super-animateurs des temps périscolaires et extrascolaires, de courts ateliers collectifs sur les compétences psychosociales pour développer l’intelligence émotionnelle, l’aisance à l’oral et le vivre ensemble des 3-12 ans. Peu d’équipement et de formation étant nécessaires, chaque éducateur peut les mettre en place très facilement à l’école ou en accueil de loisirs.",
                "or": "ou",
                "buttons": {
                    "main": "Contactez-nous pour un devis",
                    "second": "Créez un compte"
                }
            }
        },
        "userTypeChoice": {
            "liliAtHome": {
                "pre_intro": "Libérez les",
                "post_intro": "de vos enfants",
                "button": "Je suis\nsuper-parent"
            },
            "liliAtSchool": {
                "pre_intro": "Améliorez le",
                "post_intro": "en classe",
                "button": "Je suis\nsuper-prof"
            }
        },
        "forgotPassword": {
            "title": "Tu as perdu ton mot de passe ?",
            "body": "Saisis l'e-mail avec lequel tu t'es inscrit(e) pour choisir un nouveau mot de passe",
            "buttons": {
                "send": "Envoyer",
                "cancel": "Annuler"
            },
            "form": {
                "emailPlaceholder": "Ton e-mail"
            }
        },
        "signIn": {
            "title": "Nous sommes contents de te revoir !",
            "form": {
                "emailPlaceholder": "Ton e-mail",
                "passwordPlaceholder": "Ton mot de passe",
                "passwordLegend": "6 caractères minimum"
            },
            "buttons": {
                "signIn": "C'est parti !",
                "signUp": "Pas encore de compte ?",
                "forgotPassword": "Mot de passe oublié ?"
            }
        },
        "signUp": {
            "title": "Bienvenue chez LILI !",
            "errors": {
                "empty_firstname": "Votre prénom ne peut pas être vide",
                "empty_lastname": "Votre nom ne peut pas être vide",
                "empty_email": "Votre e-mail ne peut pas être vide",
                "empty_password": "Votre mot de passe ne peut pas être vide",
                "wrong_email": "La saisie de votre e-mail est incorrect",
                "wrong_password": "Votre mot de passe doit contenir 6 caractères minimum",
                "empty_organisation_name": "Le nom de votre organisation ne peut pas être vide",
                "empty_organisation_address": "L'adresse de votre organisation ne peut pas être vide",
                "empty_organisation_zip": "Le code postal de votre organisation ne peut pas être vide",
                "empty_organisation_city": "La ville de votre organisation ne peut pas être vide",
                "empty_organisation_country": "Le pays de votre organisation ne peut pas être vide"
            },
            "form": {
                "welcome": "Bienvenue\naux super-profs",
                "toStart": {
                    "title": "Pour bien démarrer, rendez-vous dans les ressources de l'enseignant",
                    "body": {
                        "tuto": "• Visionnez le tutoriel",
                        "advice": "• Lisez nos conseils",
                        "webinar": "• Participez au webinar"
                    }
                },
                "firstnamePlaceholder": "Votre prénom",
                "lastnamePlaceholder": "Votre nom",
                "emailPlaceholder": "Votre e-mail",
                "passwordPlaceholder": "Choisissez votre mot de passe",
                "passwordLegend": "6 caractères minimum",
                "schoolSearchEngineTitle": "Votre organisation est basée en France ?",
                "schoolByZipCodePlaceholder": "Code postal de votre organisation...",
                "schoolInOtherTerritoryTitle": "Votre organisation est basée dans un autre pays ?",
                "schoolFormTitle": "Mon organisation",
                "schoolSearch": "Cherchez votre organisation",
                "schoolInputsTitle": "ou\nsaisissez manuellement les coordonnées de votre organisation",
                "schoolNamePlaceholder": "Nom",
                "schoolAddressPlaceholder": "Adresse",
                "schoolZipCodePlaceholder": "Code postal",
                "schoolCityPlaceholder": "Ville",
                "schoolCountryPlaceholder": "Pays",
                "schoolinstitutionTypePrivate": "Privé",
                "schoolinstitutionTypePublic": "Public",
                "institutionTypePlaceholder": "Type d'organisation",
                "institutionFormTitle": "Mon organisation",
                "institutionNamePlaceholder": "Nom",
                "institutionAddressPlaceholder": "Adresse",
                "institutionZipCodePlaceholder": "Code postal",
                "institutionCityPlaceholder": "Ville",
                "institutionCountryPlaceholder": "Pays",
                "classesFormTitle": "De quel niveau scolaire\nvous occupez-vous ?",
                "animatorFunctionTitle": "Quel est votre rôle au sein de l'organisation ?",
                "animators": {
                    "args": {
                        "arg_1": {
                            "title": "400 activités",
                            "body": "clé-en-main à diffuser sur mobile, tablette ou ordinateur"
                        },
                        "arg_2": {
                            "title": "De courtes activités collectives",
                            "body": "Histoires audio, temps calmes, art & jeux d'impro, podcasts philo."
                        },
                        "arg_3": {
                            "title": "7 projets pédagogiques",
                            "body": "pour apprendre la confiance et le vivre ensemble dès 3 ans"
                        },
                        "arg_4": {
                            "title": "1 espace dédié",
                            "body": "à l’accompagnement des super-animateurs (formation, supports pédagogiques)"
                        },
                        "arg_5": {
                            "title": "Ressource soutenue par l’Education nationale",
                            "body": "Les compétences psychosociales au service du climat scolaire"
                        }
                    },
                    "organisationType": {
                        "title": "Précisez votre type d'organisation, s'il vous plait.",
                        "collectivity": "Collectivité",
                        "extrascollar": "Accueil périscolaire",
                        "social_center": "Centre social",
                        "edu_city": "Cité éducative",
                        "media_library": "Médiathèque",
                        "association": "Association",
                        "other": "Autre"
                    }
                },
                "family": {
                    "args": {
                        "arg_1": {
                            "title": "400 activités sans écran",
                            "body": "à diffuser sur mobile, tablette, et ordinateur"
                        },
                        "arg_2": {
                            "title": "Des activités audio et papier",
                            "body": "Histoires audio, temps calmes, art & jeux d'impro, podcasts philo"
                        },
                        "arg_3": {
                            "title": "7 super-programmes",
                            "body": "adaptés aux besoins particuliers de votre enfant"
                        },
                        "arg_4": {
                            "title": "1 espace dédié",
                            "body": "pour les super-parents avec des conseils d'experts en pédagogie"
                        },
                        "arg_5": {
                            "title": "Ressource éducation nationale",
                            "body": "Les compétences psychosociales au service du climat scolaire"
                        }
                    }
                },
                "teachers": {
                    "args": {
                        "arg_1": {
                            "title": "6000 Super-profs",
                            "body": "qui font grandir la confiance et le vivre ensemble avec Lili"
                        },
                        "arg_2": {
                            "title": "400 activités clé-en-main",
                            "body": "à diffuser sur mobile, tablette, ordinateur ou TNI"
                        },
                        "arg_3": {
                            "title": "De courtes activités collectives",
                            "body": "Récits de rôles modèles, temps calmes, jeux d'art et impro, ateliers philo."
                        },
                        "arg_4": {
                            "title": "7 séquences pédagogiques",
                            "body": "pour développer une compétence psychosociale particulière"
                        },
                        "arg_5": {
                            "title": "1 espace dédié",
                            "body": "à l’accompagnement des enseignants (formation & supports pédagogiques)"
                        },
                        "arg_6": {
                            "title": "Soutenue par l’Education nationale",
                            "body": "ressource Edu-Up et TNE"
                        }
                    },
                    "functions": {
                        "teacher": "Enseignant/e",
                        "documentalist": "Professeur/e documentaliste",
                        "aesh": "AESH",
                        "atsem": "ATSEM",
                        "establishment_head": "Chef/fe d’établissement",
                        "medico_social": "Personnel médico-social",
                        "inspector": "Inspecteur/rice, conseiller/e ou référent/e",
                        "director_executive": "Autre directeur/rice ou cadre de l’Education nationale",
                        "other": "Autre"
                    }
                },
                "radioButtons": {
                    "coachManual": {
                        "firstPart": "Je souhaite recevoir gratuitement les conseils d'experts pour les super-parents, ",
                        "secondPart": "7 e-mails pour libérer les super-pouvoirs de votre enfant."
                    },
                    "family_newsletter": "Je souhaite recevoir gratuitement les 7 conseils d'experts pour les super-parents et de rares messages de la part de Lili.",
                    "newsletter": "Je souhaite recevoir la newsletter et quelques messages de Lili pour m’accompagner à la prise en main de l’application.",
                    "classes": {
                        "kindergarten": "Maternelle",
                        "elementary": "Élementaire",
                        "middleschool": "Collège",
                        "highschool": "Lycée",
                        "other": "Autre (ex. SESSAD)",
                        "none": "Je ne m’occupe pas d’un niveau scolaire en particulier"
                    },
                    "animator": {
                        "animator": "Je suis animateur(rice)",
                        "specializedEducator": "Je suis éducateur(rice) spécialisé(e)",
                        "centerDirection": "Je suis directeur(rice) de centre",
                        "libraryDirector": "Je suis  directeur(rice) de médiathèque",
                        "collectivityDirection": "Je suis directeur(rice) ou personnel d'une collectivité",
                        "elected": "Je suis élu(e) ou adjoint(e)",
                        "other": "Autre"
                    }
                }
            },
            "buttons": {
                "search": "Rechercher",
                "steps": "OK",
                "thisWay": "C'est par ici",
                "signUp": "C'est parti !",
                "signIn": "Déjà inscrit(e) ?",
                "ok": "OK",
                "cgu": {
                    "firstPart": "L'inscription vaut pour acceptation des",
                    "secondPart": "conditions générales de l'application."
                }
            }
        }
    },
    "category": {
        "see_more": "Voir plus"
    },
    "choices": {
        "relax_title": "Tu peux écouter cette relaxation",
        "relax_without_music": "sans musique",
        "relax_with_music": "avec musique",
        "sleep_music_title": "Écoute une musique pour t'endormir",
        "sleep_music_with_intro": "avec une voix en introduction",
        "sleep_music": "sans aucune voix",
        "video": "Vidéo",
        "audio": "Audio"
    },
    "downloads": {
        "title": "Mes téléchargements",
        "empty": "Vous n'avez pas encore de téléchargement.\n\nPour en ajouter, appuyez sur le bouton téléchargement de vos activités."
    },
    "favorites": {
        "empty": "Tu n'as pas encore de favoris.\n\nPour en ajouter, appuie sur le bouton ♡ de tes activités préférées.",
        "title": "Mes favoris"
    },
    "filters": {
        "cycle1": "3-6 ans",
        "cycle2": "6-9 ans",
        "cycle3": "9-12 ans"
    },
    "giftcards": {
        "title": "Mes cartes Premium"
    },
    "keys": {
        "superAdultUsers": {
            "family": "Super-parents",
            "teacher": "Super-profs",
            "doctor": "Super-médecins",
            "animator": "Super-animateurs",
            "unknown": "Super-adultes"
        }
    },
    "media": {
        "toDownload": "À télécharger",
        "toPrint": "À imprimer",
        "philoNotebook": "Carnet",
        "podcast": "Podcast",
        "toListen": "À écouter",
        "preview": "Aperçu",
        "toWatch": "À voir"
    },
    "mood": {
        "title": "Ma météo intérieure",
        "subtitle": "Quel temps fait-il à l’intérieur de toi ? Soleil car tu te sens joyeux, pluie car tu te sens triste ? Choisis la météo de ton profil !",
        "buttonTitle": "OK",
        "happy": "joyeux(se)",
        "peaceful": "calme",
        "sad": "triste",
        "annoyed": "mécontent(e)",
        "stressed": "stressé(e)",
        "angry": "en colère"
    },
    "onboarding": {
        "buttons": {
            "signUp": "Je m'inscris",
            "signIn": "Déjà inscrit(e) ?"
        },
        "teacher": {
            "pageOne": {
                "title": "Conçue par des enseignants\npour les enseignants",
                "subtitle": "Inspirée des pédagogies Montessori et Freinet",
                "legend": "Maternelle - Elémentaire - Début du collège"
            },
            "pageTwo": {
                "title": "200 Ateliers pour la classe"
            },
            "pageThree": {
                "title": "Des ressources pour l'enseignant",
                "classMaterial": "Matériel pour la classe",
                "schoolMaterial": "Supports pédagogiques",
                "linkSheets": "Fiches de liaison",
                "formationModules": "Modules de formations",
                "teacherFacebook": {
                    "title": "Groupe facebook",
                    "subtitle": "La salle des profs Lili à l'école"
                }
            },
            "pageFour": {
                "title": "Paroles de super-profs",
                "sylvieDEsclaibes": {
                    "title": "\"Lili répond à un vrai besoin pour les enseignants\"",
                    "subtitle": "Sylvie d'Esclaibes, pionnière de l'éducation Montessori en France"
                },
                "nadine": {
                    "title": "\"Super application pédagogique, facile d'appropriation. Les élèves sont demandeurs\"",
                    "subtitle": "Nadine, directrice d'école primaire"
                },
                "severine": {
                    "title": "\"Mes élèves adorent les relaxations. Comme elles sont courtes, ça me permet de garder tout le monde calme et attentif.\"",
                    "subtitle": "Séverine, enseignante de maternelle"
                },
                "armelle": {
                    "title": "\"Des référents pédagogiques de renom\"",
                    "subtitle": "Armelle, présidente de Public Montessori"
                },
                "claire": {
                    "title": "\"L'éducation au bien-être\"",
                    "subtitle": "Claire, enseignante du CM2"
                }
            }
        },
        "parent": {
            "pageOne": {
                "title": "Bien plus qu'une appli audio\nune pédagogie active et ludique",
                "subtitle": "inspirée de Montessori et Freinet",
                "legend": "Relaxation & Yoga - Arts & Impro - Philo pour enfants"
            },
            "pageTwo": {
                "title": "200 Ateliers pour la classe"
            },
            "pageThree": {
                "title": "Pour le super-héros",
                "routines": {
                    "title": "Routine de super-héros",
                    "day": {
                        "title": "Le jour",
                        "subtitle": "Parcours d'entraînement\nde super-héros"
                    },
                    "night": {
                        "title": "Le soir",
                        "subtitle": "Relaxation\npour s'endormir"
                    }
                },
                "ressources": {
                    "title": "7 super-programmes",
                    "activities": "Avec ateliers",
                    "talk": "Arts et\nimprovisation",
                    "grow": "Podcasts et\ncarnets philo",
                    "still": "Relaxation\net yoga",
                    "tools": "Et matériels créatifs"
                }
            },
            "pageFour": {
                "title": "Pour les super-parents",
                "coachManual": {
                    "part_one": "Recevez-le par e-mail et retrouvez-le",
                    "part_two": "dans le menu",
                    "part_three": "de l'appli"
                },
                "tools": {
                    "school": {
                        "title": "Matériel pédagogique",
                        "body": "pour accompagner\nvotre super-héros"
                    },
                    "experts": {
                        "title": "Conseils d'experts",
                        "body": "en pédagogie et en\nSciences de l'éducation"
                    }
                },
                "citation": {
                    "text": "\"Avec Lili, nous donnons des clés de confiance à chaque enfant. Parce que la confiance, ça se transmet !\"",
                    "legend": "Arnaud, CEO de Lili et coach de 3 super-héros"
                }
            }
        }
    },
    "pdf": {
        "errorTitle": "Oups !",
        "errorBody": "Il semble qu'une erreur se soit produite au chargement de ton fichier.",
        "errorRetryButton": "Réessayer"
    },
    "preview": {
        "author": "Auteur",
        "editor": "Éditeur",
        "age": "Âge"
    },
    "profile": {
        "admin": "Super Admin",
        "giftCards": "Mes cartes Premium",
        "title": "Mon profil",
        "myAccount": "Mon compte",
        "myWeather": "Ma météo",
        "filtersByLevel": "Mon niveau de classe",
        "subAvailability": "Date de fin de validité : %{date}",
        "subYearAvailability": "Valide pour l'année scolaire : %{year}",
        "subCards": "Cartes Premium",
        "subCardsBody": "Activer une carte Premium",
        "myVictories": "Mes récompenses",
        "myDownloads": "Mes téléchargements",
        "myFavorites": "Mes favoris",
        "myReview": "Mon avis",
        "subscription": "Passer\nPremium",
        "code": "J'ai un code",
        "logout": "Me déconnecter",
        "deleteMyAccount": "Supprimer mon compte",
        "unsubscribe": "Se désabonner",
        "unsubscribeTitle": "Vous souhaitez mettre fin à votre abonnement ?",
        "unsubscribeAndroidBody": "Tu peux annuler ton abonnement en appuyant sur le bouton \"Gérer mon abonnement\" ci-dessous.",
        "unsubscribeiOSBody": "Tu peux annuler ton abonnement en appuyant sur le bouton \"Gérer mon abonnement\" ci-dessous.",
        "unsubscribeFromWeb": "En confirmant votre demande de résiliation à Lili à la maison Premium, votre abonnement s’arrêtera à sa date de fin. Vous n’aurez alors plus accès à l’intégralité des super-activités pour faire grandir pleinement la confiance de votre super-héros 🙁",
        "unsubscribeAndroidFromiOSBody": "Il semble que tu te sois abonné sur Android.\nTu peux annuler ton abonnement dans la rubrique Abonnements du Google Play Store.",
        "unsubscribeFromLili": "Ton abonnement t'es offert par LILI ! Il serait dommage de l'abandonner !",
        "unsubscribeCancel": "Annuler",
        "unsubscribeConfirmation": "Confirmer",
        "alreadySubscriber": "Je suis déjà abonné. Retrouver mon abonnement !",
        "findMySubscriptionTitle": "Retrouver mon abonnement",
        "findMySubscriptioniOSFromAndroidBody": "Il semble que ton dernier abonnement ait été payé depuis un iPhone ou un iPad. Il faut te connecter sur celui-ci pour voir si ton abonnement est toujours valide.",
        "findMySubscriptionAndroidFromiOSBody": "Il semble que ton dernier abonnement ait été payé depuis un Android. Il faut te connecter sur un Android pour voir si ton abonnement est toujours valide.",
        "findMySubscriptioniOSFromiOSBody": "Nous n'avons pas trouvé d'abonnement en cours de validité. Si tu penses que tu devrais toujours être abonné, contacte-nous : support@lili.cool.",
        "findMySubscriptionAndroidFromAndroidBody": "Nous n'avons pas trouvé d'abonnement en cours de validité. Si tu penses que tu devrais toujours être abonné, contacte-nous : support@lili.cool.",
        "findMySubscriptionLILI": "Il semble que ton dernier abonnement ait été offert par LILI. Si tu penses que tu devrais toujours être abonné, contacte-nous : support@lili.cool.",
        "findMySubscriptionWeb": "Il semble que ton dernier abonnement ait été payé sur le site de LILI. Si tu penses que tu devrais toujours être abonné, contacte-nous : support@lili.cool.",
        "showSubscriptionManagement": "Gérer mon abonnement",
        "unsubscribeSuccessTitle": "C'est fait !",
        "unsubscribeSuccessBody": "Le renouvellement automatique de ton abonnement est annulé, et celui-ci prendra fin le %{date}.",
        "unsubscribeErrorTitle": "Aïe !",
        "unsubscribeErrorBody": "Une erreur est survenue lors de l'annulation de ton abonnement. Pour que nous puissions t'aider, contacte-nous : support@lili.cool",
        "freeVersion": "Version Découverte",
        "demoVersion": "Version de démonstration",
        "premiumVersion": "Version Premium"
    },
    "startingSteps": {
        "title": "Nouveau sur Lili ?",
        "subtitle": "Suivez les %{number_of_steps} étapes pour vous lancer",
        "button": "Lancez-vous !",
        "macaroon": "C'est\npar ici",
        "modal": {
            "title": "%{number_of_steps} étapes pour vous lancer",
            "button": "Fermer"
        }
    },
    "subCards": {
        "title": "Cartes Premium",
        "no_cards": "Vous n’avez pas de carte Premium\nà activer ou à transférer.",
        "card": {
            "used": "Carte utilisée"
        },
        "modal": {
            "form": {
                "redeemed": "Votre carte est activée.\nVous bénéficiez dès à présent de votre abonnement.",
                "title": "Activer une carte PREMIUM",
                "body": "Vous avez reçu un code pour activer un abonnement LILI PREMIUM ?\n\nVous pouvez le saisir dans le champs ci-dessous pour associer l'abonnement à votre compte.",
                "active": "Activer",
                "nothingToDo": "Ne rien faire pour l'instant",
                "close": "Fermer"
            },
            "already":" Tu as déjà un compte Premium. Tu ne peux donc pas utiliser cette carte pour toi.",
            "valid": {
                "title": "Que souhaites-tu faire avec ta carte Premium ? ",
                "body": "Tu peux l'utiliser pour accéder à l'intégralité de LILI avec ton compte ou la transférer à un autre utilisateur de LILI.",
                "redeemed": "Activer la carte Premium sur mon compte",
                "nothingToDo": "Ne rien faire pour l'instant",
                "transfer": "Transférer la carte Premium à un autre utilisateur"
            },
            "redeemed": {
                "title": "Ta carte Premium a déjà été utilisée.",
                "body": "Cette carte Premium a été affectée à un autre utilisateur et ne peut donc plus être utilisée.",
                "close": "Fermer"
            },
            "transfer": {
                "title": "Tu souhaites transférer cette carte Premium",
                "body": "Tu peux offrir cette carte Premium à un autre utilisateur en saisissant son adresse e-mail ci-dessous.",
                "cancel": "Annuler",
                "send": "Envoyer",
                "email": "Adresse e-mail du destinataire"
            }
        },
        "post_action": {
            "validation": {
                "title": "Carte Premium activé !",
                "body": "Ton compte est maintenant Premium! Bonne utilisation de LILI !"
            },
            "transfert": {
                "title": "Carte Premium transféré !",
                "body":  "Ta carte Premium a bien été tranférée à l'adresse : %{email}\nLe destinataire a reçu un e-mail pour lui expliquer comment en bénéficier."
            }
        }
    },
    "subscription": {
        "animator": {
            "title": "Choisissez la meilleure formule Premium pour votre centre",
            "offers": {
                "title": "Lili en périscolaire Premium",
                "body": "Profitez en illimité des  400 activités pour votre centre et de l’intégralité de l’accompagnement dont le module de formation aux ateliers philo."
            },
            "subscribed": {
                "title": "Votre formule",
                "duration": "Lili en périscolaire Premium",
                "body": "Vous profitez en illimité des 400 activités pour votre centre et de l’intégralité de l’accompagnement dont le module de formation aux ateliers philo."
            },
            "legalMentions": "La formule Premium s’arrête automatiquement à la fin de la période.",
            "subscribe": "Je souscris",
            "restore": "J’ai déjà une licence !"
        },
        "teacher": {
            "title": "Choisissez la meilleure formule Premium pour votre classe",
            "offers": {
                "title": "Lili à l'école premium",
                "body": "Profitez en illimité des 400 activités pour la classe et de l’intégralité des supports pédagogiques"
            },
            "subscribed": {
                "title": "Votre formule",
                "duration": "Lili à l'école Premium",
                "body": "Vous profitez en illimité des 400 activités pour la classe et de l’intégralité des supports pédagogiques"
            },
            "legalMentions": "La formule Premium s’arrête automatiquement à la fin de la période.",
            "subscribe": "Je souscris",
            "restore": "J’ai déjà une licence !"
        },
        "parent": {
            "title": "Abonnez votre enfant à Lili à la maison pour profiter des super-programmes en illimité !",
            "offers": {
                "title": "Lili à la maison PREMIUM",
                "body": "Libérez pleinement les super-pouvoirs de votre enfant en lui offrant l'intégralité des 400 activités."
            },
            "subscribed": {
                "title": "Votre abonnement",
                "duration": "Lili à la maison Premium",
                "body": "Vous accédez en illimité aux 400 activités audio et papier, dont les relaxations pour s’endormir, et aux conseils d’experts pour faire grandir la confiance de votre super-héros.",
                "legalMentions": {
                    "renewable": "Votre abonnement sera reconduit automatiquement à cette date.",
                    "notRenewable": "Votre abonnement ne sera pas reconduit automatiquement à cette date."
                }
            },
            "legalMentions": "Sans engagement. Résiliable à tout moment.",
            "testPeriod": "7 jours gratuits puis",
            "subscribe": "J’abonne mon super-héros",
            "restore": "Je suis déjà abonné !"
        },
        "buttons": {
            "more": "En savoir plus",
            "hide": "Fermer",
            "unsubscribe": "Me désabonner",
            "startWithPremium": "Profitez de LILI en Premium",
            "goBackHome": "Retourner à la page d'accueil"
        }
    },
    "superAdmin": {
        "sub": "Abonnement",
        "subOff": "Désactiver mon abonnement",
        "subOn": "Réactiver mon abonnement",
        "title": "SUPER ADMIN HQ",
        "users": "Gestion des utilisateurs",
        "gifts": "Offrir LILI",
        "bugs": "Remonter un bug",
        "editionMode": "Mode ADMIN",
        "teacherProfileMode": "Profil enseignant",
        "animatorProfileMode": "Profil animateur",
        "familyProfileMode": "Profil parent",
        "tneStat": "Statistique TNE",
        "quotes": "Citations",
        "offlineMode": "Mode offline"
    },
    "superPowersPlaylist": {
        "loading": "Super-programme en cours de lancement…",
        "titles": {
            "equipment": "Pour t'équiper",
            "warmUp": "Pour t'échauffer",
            "training": "Pour t'entraîner"
        }
    },
    "superPowers": {
        "emotionsTaming": {
            "body": {
                "fr": [
                    "En 2011, des chercheurs de Harvard ont montré que la pratique d'un temps calme densifie la matière grise du cerveau dans l’hippocampe (connu pour son rôle dans l’apprentissage et la mémoire) et la fait diminuer dans l’amygdale (impliquée dans la production du stress). Depuis, Grégory Michel, professeur de psychologie clinique, a mené une étude à l'école : l'intensité de l’anxiété des enfants diminue, améliorant ainsi le bien-être collectif et les relations en classe.",
                    "Les résultats du programme d’éducation à l’empathie mené par Omar Zanna en 2014, docteur en sociologie et psychologie, rappellent également que les émotions se vivent et s’intègrent corporellement ; il est essentiel de mobiliser le corps pour se les approprier. Des études ont enfin montré que les ateliers de philosophie pour enfants améliorent le contrôle émotionnel et induisent une plus grande cohésion sociale."
                ]
            }
        },
        "focus": {
            "body": {
                "fr": [
                    "Le trouble déficit de l’attention touche 3 à 5 % des enfants, dû à un dysfonctionnement des mémoires à court terme. Si la difficulté à se concentrer concerne un nombre grandissant d'enfants, heureusement “l'attention s'apprend”, affirme le neurobiologiste Jean-Philippe Lachaux, \"malgré et avec l’écran\". Ce que permet Lili, avec la pratique d’activités qui aiguisent l’attention sur un temps court, au format audio.",
                    "Selon Lachaux, si on se déconcentre du fait de pensées parasites (un souci, une chose à faire, une envie, un souvenir) ou de perceptions extérieures (un bruit, une conversation), on peut apprendre à se concentrer en découpant chaque objectif en une suite de tâches à confier à des \"mini-moi\" successifs polarisés sur une seule consigne. À la fin de la séquence, son \"maxi-moi\" reprend la main et planifie la séquence suivante, avec à ce moment-là le droit de se laisser divertir par ses pensées ou le monde extérieur."
                ]
            }
        },
        "errorTaming": {
            "body": {
                "fr": [
                    "\"Apprendre ce n’est pas faire grossir son cerveau, c'est un processus dynamique non linéaire\", explique le chercheur en psychologie et neurosciences Grégoire Borst. \"Les erreurs sont aussi importantes que les réussites : c’est de leur logique que se déduisent les facteurs de progrès\", insiste Olivier Houdé, enseignant-chercheur et psychologue. Pour accompagner les enfants dans cette démarche d’acceptation de l’erreur, il faut valoriser leur état d'esprit de développement (le \"growth mindset\") théorisé par la psychologue Carol Dweck.",
                    "Ne pas survaloriser la performance, mais plutôt louer l'effort, l'approche, les progrès, afin que l’enfant prenne conscience d'être sur un chemin d'apprentissage. Remplacez la formule \"je n'y arrive pas\" par \"je n'y arrive pas… encore !\", sous-entendu \"j'y arriverai un jour\", et l'enfant s'implique, il corrige l'erreur et apprend d'elle."
                ]
            }
        },
        "speak": {
            "body": {
                "fr": [
                    "Dans son rapport \"Faire du grand oral un levier de l'égalité des chances\", Cyril Delhay, professeur d'art oratoire, affirme que l'enseignement de l’oral peut être vecteur d'égalité sociale. La maîtrise de l'oral fait effectivement la différence dans les études et la vie professionnelle, or c'est un art très ouvert socialement qui demande peu de pré-requis culturels. Investir sur l'oral, c'est ouvrir la voie à une meilleure qualité d'écoute et de vie en collectivité, et œuvrer pour la réussite éducative de tous.",
                    "\"Savoir parler est partageable à tous les publics, et particulièrement bénéfique à l’école pour les enfants socialement défavorisés qui ne peuvent apprendre par mimétisme à la maison\", conclut Cyril Delhay."
                ]
            }
        },
        "collective": {
            "body": {
                "fr": [
                    "La pratique théâtrale a une véritable fonction sociale. Une étude de 2013 l’expose comme un outil efficace pour apprendre à se connaître, à faire avec les autres et à vivre-ensemble en dépassant les conflits. Philippe Osmalin, metteur en scène formateur et enseignant, compare les séances théâtrales à des mailles de la construction de soi par le tissage collectif.",
                    "Dans le prolongement des jeux d’improvisation qui aident à la socialisation, le questionnement lors d’un atelier de philosophie permet de déconstruire les idées reçues et s'ouvrir aux autres avec empathie. On apprend à être en désaccord sans se disputer, on comprend mieux son comportement et celui des autres. Selon les économistes Yann Algan et Pierre Cahuc, \"l’apprentissage de la coopération avec des travaux en groupe et la participation à des débats civiques\" est un facteur déterminant du bien-être et de la réussite éducative. Ce que corroborent les enquêtes PISA."
                ]
            }
        },
        "criticalThinking": {
            "body": {
                "fr": [
                    "\"Il n'y a pas d'âge pour se poser des questions philosophiques : le premier humain avait-il une maman (sur les origines), pourquoi tout le monde ne parle pas comme nous (sur le langage), doit-on être gentil avec les méchants (sur le bien le mal) ?\", explique Edwige Chirouter, professeure des universités et chercheure en philosophie pour enfants. Selon Grégoire Borst et Olivier Houdé, chercheurs et psychologues, la curiosité ou le doute, propices au questionnement, sont des émotions subtiles et positives qui permettent au cerveau de bloquer nos pensées automatiques pour activer une pensée rationnelle et logique.",
                    "Lors des ateliers de philosophie, il s’agit de créer un cadre dans lequel on doit justifier ses opinions. Il a été prouvé que ce dialogue stimule l’engagement avec le sujet traité et a des effets cognitifs positifs sur la scolarité, impactant les résultats dans les matières traditionnelles, en mathématiques notamment."
                ]
            }
        },
        "selfEsteem": {
            "body": {
                "fr": [
                    "Le théoricien Stanley Coopersmith a montré la non-corrélation entre l'estime de soi de l'enfant et la fortune, l'éducation ou la profession des parents : ce qui prime c’est la qualité de la relation avec ses parents. La psychologue Susan Harter a ajouté l'importance de l’approbation de ses parents, puis peu à peu de ses pairs. Encourager, valoriser, aider à bien grandir : c'est le rôle de chaque éducateur de donner ces clés de confiance et de réussite, et la mission que s’est donnée Lili à leur côté, en permettant un enseignement des compétences psychosociales basé sur l'expérimentation, ludique et valorisant.",
                    "Plusieurs études ont par ailleurs mis en évidence les effets positifs des ateliers de philosophie sur le comportement à l’école. Expérimenter l'écoute active et se sentir écouté renforce l'estime de soi, la confiance en soi et la motivation. Deux études ont démontré une efficacité élevée et constante en termes de gain cognitif, de développement socio-émotionnel et de réussite scolaire."
                ]
            }
        }
    },
    "tags": {
        "emotionsTaming": "Apprivoiser\nmes émotions",
        "focus": "Muscler\nma concentration",
        "errorTaming": "Dompter\nl'erreur",
        "speak": "Oser\nà l'oral",
        "collective": "La jouer\ncollectif",
        "criticalThinking": "Réfléchir\nsur la vie",
        "selfEsteem": "Croire\nen moi"
    },
    "teacherForm": {
        "title": "Bénéficiez de Lili à l'école pour votre classe avec les Petits champions de la lecture",
        "body": "Inscrivez-vous aujourd'hui et bénéficiez de 1 mois d'abonnement à Lili à l’école : bien + qu'une appli audio, c'est un programme complet d’ateliers de relaxation, d’improvisation et de philo pour enfants, accompagnés de supports pédagogiques, spécifiquement conçus par une équipe d’experts et d’enseignants, pour les enseignants de maternelle, de l’élémentaire et du début du collège. Vous trouverez également des ateliers pour travailler la lecture à l'oral en classe, co-construits avec les Petits champions de la lecture.",
        "error": {
            "allFieldsAreMandatory": "Tous les champs du formulaire sont obligatoires.",
            "default": "Une erreur est survenue.",
            "impossibleSubscription": "Inscription impossible",
            "tooShortPassword": "Votre mot de passe ne contient pas assez de caractères !",
            "addressAlreadyInUse": {
                "body": "Votre e-mail appartient déjà à notre base. Si vous avez déjà un compte, cliquez sur le bouton \"Attention : si vous avez déjà un compte...\" au dessus du formulaire, pour bénéficier d'un mois d'abonnement gratuit sur votre compte LILI.",
                "title": "E-mail déjà existant."
            }
        },
        "success": {
            "signUp": {
                "title": "Bienvenue chez LILI !",
                "body": "Dès maintenant, vous pouvez installer LILI sur votre téléphone, et commencer à bénéficier de votre mois d'abonnement gratuit."
            },
            "signIn": {
                "title": "Découvrez votre nouvel abonnement !",
                "body": "Dès maintenant, vous pouvez bénéficier de votre mois d'abonnement gratuit."
            }
        },
        "form": {
            "title": "Profitez d’1 mois offert",
            "signUp": {
                "createYourAccount": "Créez votre compte Lili"
            },
            "signIn": {
                "connect": "Connectez-vous à votre compte Lili"
            },
            "emailPlaceholder": "Votre e-mail",
            "signUpPasswordPlaceholder": "Choisissez votre mot de passe",
            "signInPasswordPlaceholder": "Votre mot de passe",
            "schoolNamePlaceholder": "Nom de votre établissement",
            "zipCodePlaceholder": "Code postal de votre établissement",
            "button": {
                "signUp": "C'est parti !",
                "signIn": "C'est parti !",
                "alreadyRegistered": "Attention : si vous avez déjà un compte, cliquez ici pour préciser vos identifiants Lili et bénéficier d'un abonnement Premium",
                "noAccount": "Vous n'avez pas encore de compte ? Inscrivez-vous",
                "newsletter": "Je souhaite recevoir la newsletter et quelques messages de Lili pour m’accompagner à la prise en main de l’application."
            }
        }
    },
    "userRewards": {
        "myRainbows": "Mes arcs-en-ciel gagnés",
        "myDays": "Mes jours d'affilée",
        "myBadges": "Mes badges gagnés",
        "seeAllDiscoveryBadges": "Voir tous les badges découverte",
        "hideAllDiscoveryBadges": "Masquer les badges découverte",
        "seeAllFidelityBadges": "Voir tous les badges fidélité",
        "hideAllFidelityBadges": "Masquer les badges fidélité",
        "seeAllEventsBadges": "Voir tous les badges évènements",
        "hideAllEventsBadges": "Masquer les badges évènements",
        "Ihelp": "j'aide Lili",
        "Ishare": "je partage",
        "Igive": "je donne",
        "Isponsor": "je parraine",
        "noBadgesBody": "Retrouve ici\nta collection de badges\nà imprimer en version coloriage !",
        "howToGainBadges": "Comment gagner des badges ?",
        "howToGainBadgesBody": "Activité après activité, gagne des points et\najoute des couleurs à ton arc-en-ciel.\n\nCela te permettra aussi de gagner\ndes badges à colorier.\n\nCi-dessous tu as la description de ce que tu dois\nfaire pour remporter chacun des badges.",
        "letsGo": "C'est parti !",
        "badge_melomane": "Mélomane",
        "badge_expressif": "Expressif",
        "badge_audacieux": "Intrépide",
        "badge_aventurier": "Aventurier(e)",
        "badge_joueur": "Joueur",
        "badge_courageux": "Téméraire",
        "badge_agile": "Agile",
        "badge_grand_lecteur": "Littéraire",
        "badge_pose": "Calme",
        "badge_repose": "Détendu(e)",
        "badge_intellectuel": "Intellectuel(le)",
        "badge_curieux": "Curieux",
        "badge_collectionneur": "Loyal(e)",
        "badge_reveur": "Rêveur",
        "badge_tranquille": "Tranquille",
        "badge_serein": "Serein(e)",
        "badge_maitre_zen": "Zen",
        "badge_comedien": "Comédien(ne)",
        "badge_star": "Star",
        "badge_roi_de_la_scene": "Roi de la scène",
        "badge_enqueteur": "Détective",
        "badge_explorateur": "Explorateur",
        "badge_grand_sage": "Philosophe",
        "body": {
            "0124": "Accès au programme \"Lili à l'école\" lors de la journée de l'éducation",
            "0308": "Podcast philo \"Fille, garçon : c'est différent ?\" lors de la journée internationale des droits de la femme",
            "0321": "Podcast philo \"Nous sommes tous pareils ?\" lors de la journée pour l'élimination de la discrimination raciale",
            "0406": "Podcast philo \"L'important, c'est de participer ?\" lors de la journée du sport au service du développement et de la paix",
            "0421": "Improvisation ou mime lors de la journée de la créativité et de l’innovation",
            "0621": "Atelier de yoga lors de la journée internationale du yoga",
            "0730": "Podcast philo \"Ça veut dire quoi, un ami ?\" lors de la journée de l'amitié",
            "0921": "Relaxation lors de la journée de la paix",
            "0922": "Pratique de Lili le jour de la date anniversaire de l'application (Lili a été lancée le 22 septembre 2020)",
            "1031": "Podcast philo \"J'ai peur !\" le jour de Halloween",
            "1105": "Podcast philo \"Ça va, on rigole !\" lors de la journée de lutte contre le harcèlement",
            "1203": "Podcast philo \"Ça veut dire quoi, normal ?\" lors de la journée des personnes handicapées",
            "badge_melomane": "Une bulle sonore et un atelier de yoga en musique",
            "badge_expressif": "Mime \"Alphabet visuel\" et jeu d’émotion \"La valse des émotions\"",
            "badge_audacieux": "Improvisation \"Super, j’ai raté !\" et podcast philo \"Mince, j’ai fait une erreur !\"",
            "badge_aventurier": "Relaxation \"L’île intérieure\" et respiration \"La montgolfière\"",
            "badge_joueur": "Dessin à dédicacer \"Victoire !\" et podcast philo \"L’important, c’est de participer ?\"",
            "badge_courageux": "Improvisation \"Attention à la chute !\" et dessin à dédicacer \"Même pas peur !\"",
            "badge_agile": "Réaliser au moins une activités de chacune des 4 sections \"Mes programmes\", \"Je me pose\", \"Je m’exprime\" et \"Je grandis\"",
            "badge_grand_lecteur": "Lecture de plus de 10 conseils de lecture depuis \"Je grandis\"",
            "badge_pose": "Un origami, un dessin à dédicacer et un carnet philo",
            "badge_repose": "Pratique d’une activité \"Je me pose\" un samedi et un dimanche",
            "badge_intellectuel": "Jeu d'attention \"Jeu de mémoire\" et podcast philo \"Ça veut dire quoi, être intelligent ?\"",
            "badge_curieux": "Pratique d’une activité de chaque catégorie",
            "badge_collectionneur": "Pratique de 4 ateliers au sein d’une catégorie",
            "badge_reveur": "Bulle sonore \"La plage\" et dessin à dédicacer \"Je me souviens\"",
            "badge_tranquille": "Pratique de 3 ateliers \"Je me pose\"",
            "badge_serein": "Pratique de 10 ateliers \"Je me pose\"",
            "badge_maitre_zen": "Pratique de 20 ateliers \"Je me pose\"",
            "badge_comedien": "Pratique de 3 ateliers \"Je m'exprime\"",
            "badge_star": "Pratique de 10 ateliers \"Je m'exprime\"",
            "badge_roi_de_la_scene": "Pratique de 20 ateliers \"Je m'exprime\"",
            "badge_enqueteur": "Pratique de 3 ateliers \"Je grandis\"",
            "badge_explorateur": "Pratique de 10 ateliers \"Je grandis\"",
            "badge_grand_sage": "Pratique de 20 ateliers \"Je grandis\""
        },
        "owned": {
            "0124": "Tu as consulté le programme \"Lili à l'école\" lors de la journée de l'éducation.",
            "0308": "Tu as réfléchi sur l'équité en écoutant \"Fille, garçon : c'est différent ?\" lors de la journée des droits de la femme.",
            "0321": "Tu t'es posé la question \"On est tous pareils ?\" lors de la journée pour l'élimination de la discrimination raciale.",
            "0406": "Tu as écouté \"L'important, c'est de participer ?\" lors de la journée du sport au service du développement et de la paix.",
            "0421": "Tu as développé ta créativité avec une improvisation ou un mime lors de la journée de la créativité et de l’innovation.",
            "0621": "Tu as célébré la journée internationale du yoga en faisant une posture ou une séance en musique. Namasté !",
            "0730": "Tu as pu échanger sur la question \"Ça veut dire quoi, un ami ?\" lors de la journée de l'amitié.",
            "0921": "Tu as médité pour la journée internationale de la paix des Nations Unies.",
            "0922": "Tu as réalisé un atelier de Lili le jour de son anniversaire (Lili a été lancée le 22 septembre 2020).",
            "1031": "Tu as osé écouter le podcast \"J'ai peur !\" le jour de Halloween... Brrrr !",
            "1105": "Tu as réfléchi sur le très sérieux sujet \"Ça va, on rigole !\" lors de la journée de lutte contre le harcèlement.",
            "1203": "Tu as pu réfléchir à la différence avec le podcast \"Ça veut dire quoi, normal ?\" lors de la journée des personnes handicapées.",
            "badge_melomane": "Tu as fait vibrer ta corde musicale en écoutant une bulle sonore et un atelier de yoga en musique.",
            "badge_expressif": "Tu as montré tes talents d'acteur avec le mime \"Alphabet visuel\" et le jeu d’émotion \"La valse des émotions\".",
            "badge_audacieux": "Tu as osé risquer de te tromper avec l'impro \"Super, j’ai raté !\" et le podcast \"Mince, j’ai fait une erreur !\".",
            "badge_aventurier": "Tu es parti à l'aventure avec la relaxation \"L’île intérieure\" et l'exercice de respiration \"La montgolfière\".",
            "badge_joueur": "Tu as été joueur en dessinant \"Victoire !\" et en te posant la question \"L’important, c’est de participer ?\".",
            "badge_courageux": "Tu as été téméraire en jouant à l'improvisation \"Attention à la chute !\" et en dessinant \"Même pas peur !\".",
            "badge_agile": "Tu as su pratiquer un atelier de chacune des sections \"Mes programmes\", \"Je me pose\", \"Je m’exprime\", \"Je grandis\".",
            "badge_grand_lecteur": "Tu as pris plaisir à découvrir au moins 10 conseils de lectures philosophiques dans \"Je grandis\".",
            "badge_pose": "Tu as pris un temps pour toi en construisant un origami, en faisant un dessin, et en remplissant un carnet de philosophie.",
            "badge_repose": "Tu t'es offert du repos en pratiquant un atelier \"Je me pose\" un samedi et un autre un dimanche.",
            "badge_intellectuel": "Tu as fait marcher tes neurones avec le \"Jeu de mémoire\" et le podcast \"Ça veut dire quoi, être intelligent ?\".",
            "badge_curieux": "Tu as éveillé ta curiosité en pratiquant au moins un atelier de chacune des catégories de l'application.",
            "badge_collectionneur": "Tu as peut-être trouvé ta catégorie préférée en réalisant au moins 4 ateliers au sein de celle-ci.",
            "badge_reveur": "Tu as fais un beau voyage grâce à la bulle sonore \"La plage\" et au dessin à dédicacer \"Je me souviens\".",
            "badge_tranquille": "Tu as apaisé ton corps et ton esprit avec la pratique de 3 ateliers \"Je me pose\".",
            "badge_serein": "La sérénité est tienne après la pratique de 10 ateliers \"Je me pose\".",
            "badge_maitre_zen": "La pratique de 20 ateliers \"Je me pose\" t'a permis de rejoindre le club des grands maîtres du zen !",
            "badge_comedien": "Tu as réveillé ton goût pour la comédie avec la pratique de 3 ateliers \"Je m'exprime\".",
            "badge_star": "Les flashs crépitent à ton passage suite à la pratique de 10 ateliers \"Je m'exprime\".",
            "badge_roi_de_la_scene": "La foule t'acclame après avoir joué lors de 20 ateliers \"Je m'exprime\".",
            "badge_enqueteur": "Tu t'es posé de nombreuses questions en pratiquant 3 ateliers \"Je grandis\".",
            "badge_explorateur": "Ton esprit est en effervescence après l'exploration de 10 ateliers \"Je grandis\".",
            "badge_grand_sage": "La sagesse est tienne, acquise au cours de 20 ateliers \"Je grandis\"."
        }
    },
    "websub": {
        "title": "Abonnement à",
        "lah": {
            "subTitle": "LILI à la maison premium %{duration}",
            "details": "7 jours d'essai gratuits puis %{price}€ par %{duration}",
            "legalMention": "Abonnement %{duration} résiliable à tout moment"
        },
        "las": {
            "subTitle": "LILI à l'école premium",
            "details": "%{price}€ TTC pour %{duration}"
        },
        "signInButtonTitle": "J'ai déjà un compte",
        "signUpButtonTitle": "Je n'ai pas de compte",
        "signIn": "Je me connecte",
        "signUp": "Je m'inscris",
        "forgotPassword": "Mot de passe oublié"
    }
};