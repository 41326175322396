import React, { useEffect, useRef, useState } from "react";

import {
    View,
    Text,
    Platform,
    Image,
    Animated,
    TouchableOpacity,
    ViewStyle,
    ImageStyle
} from "react-native";
import User from "../../data/user/User";
import Images from "../../../specific/utils/Images";
import i18n from "../../utils/Localization/Localization";
import AppTheme from "../../utils/Theme";
import Divider from "../../designSystem/Divider/Divider";
import MenuStyle from "../../styles/menu/MenuStyle";
import { trackEvent } from "../../services/Tracking/Analytics";
import MenuSegment from "../../services/MenuSegment/MenuSegment";

interface MenuHeaderProps {
    onLayout: () => void,
    onSelectMenuItem: (itemId: number) => void,
    menuType: number
}

const MenuHeader = (props: MenuHeaderProps) => {

    const { onLayout, onSelectMenuItem, menuType } = props;

    const user: User = User.getInstance();
    const menuSegment: MenuSegment = MenuSegment.getInstance();
    const images: Images = new Images();
    const appTheme: AppTheme = new AppTheme();
    const menuStyle: MenuStyle = new MenuStyle();

    const [refresh, setRefresh] = useState<number>(0);

    /** CONFIOGURATION BY PROFILE **/
    const configurationByProfile = {
        teacher: {
            icon: images.icLiliAtSchoolCircle,
            title: i18n.t("appMenu.teachers.activitiesTitle"),
            color: appTheme.schoolColor,
            activitiesByTypeIndex: 0
        },
        animator: {
            icon: images.icLiliAtExtracurricularCircle,
            title: i18n.t("appMenu.animators.activitiesTitle"),
            color: appTheme.schoolColor,
            activitiesByTypeIndex: 0
        },
        family: {
            icon: images.icLiliAtHomeCircle,
            title: i18n.t("appMenu.parents.activitiesTitle"),
            color: appTheme.homeColor,
            activitiesByTypeIndex: 1
        }
    }

    const currentConfiguration = configurationByProfile[user.profile] ?? configurationByProfile["family"];
    const { icon, title, color, activitiesByTypeIndex } = currentConfiguration;
    const firstMenuItemTitle = activitiesByTypeIndex === 0 ? "par type" : "par super-pouvoir";
    const secondMenuItemTitle = activitiesByTypeIndex === 0 ? "par compétence" : "par type";

    /** UI **/
    const iconSize = Platform.OS === "web" ? appTheme.pixelPerfect(80) : appTheme.pixelPerfect(50);
    const typeMenuWidth = Platform.OS === "web" ? appTheme.pixelPerfect(280) : appTheme.pixelPerfect(220);
    const typeMenuBackgroundWidth = typeMenuWidth / 2;
    const menuBackgroundPosition = useRef(new Animated.Value(0)).current;

    const containerStyle: ViewStyle = { alignItems: "center", justifyContent: "flex-start", width: appTheme.getFullAppWidth() };
    const mainIconStyle: ImageStyle = { width: iconSize, height: iconSize };
    const menuSegmentStyle: ViewStyle = { flexDirection: "row", width: typeMenuWidth, height: appTheme.pixelPerfect(36), borderRadius: appTheme.pixelPerfect(20), backgroundColor: appTheme.white }
    const backgroundStyle: ViewStyle = { position: "absolute", top: 0, left: menuBackgroundPosition, backgroundColor: color, width: "50%", height: "100%", borderRadius: appTheme.pixelPerfect(20) };

    useEffect(() => {
        if ((menuType === 1) && (menuBackgroundPosition._value === 0)) {
            // Si on a une incohérence du au retour du 2nd menu, on relance l'animation
            Animated.timing(menuBackgroundPosition, {
                toValue:  (typeMenuBackgroundWidth + appTheme.pixelPerfect(2)),
                duration: 500,
                useNativeDriver: false
            }).start();
        }
    }, [props]);

    const getFontForMenuItemIndex = (menuItemIndex: number) => {
        if (user.profile === "family") {
            return appTheme.darkBlue;
        }
        const outputRangeByIndex: string[] = menuItemIndex === 0 ? [appTheme.white, appTheme.schoolColor] : [appTheme.schoolColor, appTheme.white];
        return menuBackgroundPosition.interpolate({
            inputRange: [0, typeMenuBackgroundWidth],
            outputRange: outputRangeByIndex
        })
    }

    const onSelectItem = (menuItemIndex: number) => {
        const activitiesByTypeIndex = user.profile === "family" ? 1 : 0;
        if (activitiesByTypeIndex === menuItemIndex) {
            menuSegment.origin = "section";
            trackEvent("hp_sections_clicked");
        } else {
            menuSegment.origin = "superpowers";
            trackEvent("hp_superpowers_clicked");
        }
        Animated.timing(menuBackgroundPosition, {
            toValue: menuItemIndex * (typeMenuBackgroundWidth + appTheme.pixelPerfect(2)),
            duration: 500,
            useNativeDriver: false
        }).start();
        setTimeout(() => {
            onSelectMenuItem(menuItemIndex);
        }, 500);
    }

    return <View onLayout={onLayout} style={containerStyle}>
        <Divider />
        <Image source={icon} style={mainIconStyle} resizeMode="contain" />
        <Divider />
        <Text style={menuStyle.titleStyle}>{title.toLocaleUpperCase()}</Text>
        <Divider />
        <View style={menuSegmentStyle}>
            <Animated.View style={backgroundStyle} />
            <TouchableOpacity onPress={() => onSelectItem(0)} style={{ width: "50%", height: "100%", justifyContent: "center", alignItems: "center" }}>
                <Animated.Text style={{ fontFamily: appTheme.primaryBoldFont, color: getFontForMenuItemIndex(0), fontSize: appTheme.pixelPerfectForFont(8) }}>{firstMenuItemTitle}</Animated.Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onSelectItem(1)} style={{ width: "50%", height: "100%", justifyContent: "center", alignItems: "center" }}>
                <Animated.Text style={{ fontFamily: appTheme.primaryBoldFont, color: getFontForMenuItemIndex(1), fontSize: appTheme.pixelPerfectForFont(8) }}>{secondMenuItemTitle}</Animated.Text>
            </TouchableOpacity>
        </View>
        <Divider />
    </View>;
}

export default MenuHeader;