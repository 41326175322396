import { CONFIG } from "./model";

const config: CONFIG = {
    isProduction: false,
    appName: "Lili bêta",
    bundleId: "cool.lili.app.beta",
    packageName: "cool.lili.app.beta",
    theme: {
        primaryColor: "#123456",
        secondaryColor: "#404040"
    }
}

export default config;