import { getBundleId } from "react-native-device-info";
import PROD from "./prod";
import STAGING from "./staging";
import { CONFIG } from "./model";

const appsConfgurations: CONFIG[] = [ PROD, STAGING ];

const appsbundleId: string = getBundleId();

let defaultConfiguration: CONFIG = PROD;

for (const anAppsConfigIndex in appsConfgurations) {
    if (Object.prototype.hasOwnProperty.call(appsConfgurations, anAppsConfigIndex)) {
        const anAppsConfig: CONFIG = appsConfgurations[anAppsConfigIndex];
        if ((anAppsConfig.bundleId === appsbundleId) || (anAppsConfig.packageName === appsbundleId)) {
            defaultConfiguration = anAppsConfig;
            break;
        }
    }
}

export default defaultConfiguration;