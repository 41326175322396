import React, { Component, useState } from "react";

import {
    Alert,
    FlatList,
    StyleSheet,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import AppTheme from "../../../utils/Theme";
import i18n from "../../../utils/Localization/Localization";
import TextInput from "../../../designSystem/TextInput/RoundCornerTextInput";
import SVGView from "../../../utils/SvgView";
import { default as IcSearch } from "../../../assets/svg/lili/ic_search.svg";
import Divider from "../../../designSystem/Divider/Divider";
import ActivityIndicator from "../../../designSystem/ActivityIndicator/ActivityIndicator";
import { default as IcAngleRight } from "../../../assets/svg/icons/solid/angle-right.svg";

interface ActionSheetSchoolSearchProps {
    onChange: (item: any) => void
}

const ActionSheetSchoolSearch = (props: ActionSheetSchoolSearchProps) => {

    const appTheme: AppTheme = new AppTheme();

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [schoolsList, setSchoolsList] = useState(undefined);

    const { onChange } = props;

    const titleStyle = {
        textAlign: "center",
        fontSize: appTheme.pixelPerfectForFont(9)
    };

    const onSchoolSearchRequest = async () => {
        setIsLoading(true);
        const url = "https://data.education.gouv.fr/api/explore/v2.1/catalog/datasets/fr-en-annuaire-education/records?where=code_postal%3D" + searchTerm + "&limit=50"
        try {
            const listResult = await fetch(url, {
                method: "GET",
                headers: {
                    "Accept": "application/json"
                }
            });
            let jsonResults = await listResult.json();
            if (jsonResults.results !== undefined) {
                jsonResults = jsonResults.results;
            } else {
                jsonResults = [];
            }
            setSchoolsList(jsonResults);
            setIsLoading(false);
        } catch (error) {
            //throw error;
            Alert.alert("Une erreur est survenue lors de la recherche de votre école.")
        }
    }

    const selectSchool = (school) => {
        onChange(school);
    }

    const getButtonContent = () => {
        if (isLoading === true) {
            return <ActivityIndicator loading={isLoading} color={appTheme.white} />
        }
        return <SVGView Component={IcSearch} width={appTheme.pixelPerfect(16)} height={appTheme.pixelPerfect(16)} color={appTheme.white} />;
    }

    const getSchoolListTitle = () => {
        if (schoolsList === undefined) {
            return <View />;
        }
        let numberOfResult = "Aucun établissement trouvé pour ce code postal."
        if (schoolsList.length === 1) {
            numberOfResult = "1 établissement trouvé."
        } else if (schoolsList.length > 1) {
            numberOfResult = schoolsList.length + " établissements trouvés."
        }
        return <Text style={[titleStyle, { textAlign: "left", color: appTheme.schoolColor, fontFamily: appTheme.primaryBoldFont, paddingTop: appTheme.pixelPerfect(10) }]}>
            {numberOfResult}
        </Text>
    }

    const schoolCell = ({ item, index }) => {
        let schoolAddress: string = item.adresse_1;
        if (item.adresse_2 !== null) {
            schoolAddress = schoolAddress+"\n"+item.adresse_2;
        }
        return (
            <TouchableOpacity onPress={() => selectSchool(item)} style={[styles.cell, { paddingVertical: appTheme.pixelPerfect(10), borderBottomColor: appTheme.schoolColor }]}>
                <View style={{ flex: 1 }}>
                    <Text style={{ color: appTheme.secondaryColor, fontFamily: appTheme.primaryFont, fontWeight: "700", fontSize: appTheme.pixelPerfectForFont(12) }}>{item.nom_etablissement}</Text>
                    <Text style={{ color: appTheme.secondaryColor }}>{item.type_etablissement + " " + item.statut_public_prive}</Text>
                    <Text style={{ color: appTheme.secondaryColor }}>{item.adresse_1}</Text>
                    <Text style={{ color: appTheme.secondaryColor }}>{item.code_postal + " " + item.nom_commune}</Text>
                </View>
                <View style={{ marginLeft: appTheme.pixelPerfect(10) }}>
                    <SVGView Component={IcAngleRight} width={appTheme.pixelPerfect(16)} height={appTheme.pixelPerfect(16)} color={appTheme.schoolColor} />
                </View>
            </TouchableOpacity>
        )
    }

    return (
        <View style={{ marginBottom: appTheme.pixelPerfect(10), paddingTop: appTheme.pixelPerfect(10), maxHeight: appTheme.getFullAppHeight() - appTheme.pixelPerfect(100), alignItems: "center" }}>
            <Text style={[titleStyle, { textAlign: "left", color: appTheme.schoolColor, fontFamily: appTheme.primaryBoldFont, paddingTop: appTheme.pixelPerfect(10), width: appTheme.getBlockWidth() * 0.9 }]}>
                {i18n.t("authentication.signUp.form.schoolSearchEngineTitle").toLocaleUpperCase()}
            </Text>
            <Divider />
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", height: appTheme.pixelPerfect(50) }}>
                <View>
                    <TextInput
                        key="schoolNameSearch"
                        placeholder={i18n.t("authentication.signUp.form.schoolByZipCodePlaceholder")}
                        onChangeText={(text) => setSearchTerm(text)}
                        value={searchTerm}
                        keyboardType="default"
                        autoCapitalize="words" />
                </View>
                <TouchableOpacity disabled={isLoading} onPress={onSchoolSearchRequest} style={{ marginBottom: appTheme.pixelPerfect(10), marginLeft: appTheme.pixelPerfect(10), width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), justifyContent: "center", alignItems: "center", backgroundColor: appTheme.schoolColor, borderRadius: appTheme.pixelPerfect(20) }}>
                    {getButtonContent()}
                </TouchableOpacity>
            </View>
            {getSchoolListTitle()}
            <FlatList
                style={{ width: "100%", backgroundColor: appTheme.white }}
                data={schoolsList}
                keyExtractor={(item, index) => index.toString()}
                renderItem={schoolCell} />
        </View>
    );

}

export default ActionSheetSchoolSearch;

const styles = StyleSheet.create({
    cell: {
        width: "100%",
        borderBottomWidth: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    }
});