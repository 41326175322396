import React, { useEffect, useRef } from "react";
import { useState, useContext } from "react";
import i18n from "../../utils/Localization/Localization";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";

import {
    View,
    Text,
    ActivityIndicator,
    TouchableOpacity,
    Image,
    ScrollView,
    Animated,
    Platform
} from 'react-native';

import AppTheme from "../../utils/Theme";
import * as Constants from "../../utils/Constants";

// Assets
import Images from "../../../specific/utils/Images";

import { useNavigate } from "react-router-dom";

import { analyticsEventHpLoungeClicked, analyticsEventHpSleepMeditationsClicked, analyticsEventHpSuperpowersPosterClicked, analyticsEventHpTrainingPathClicked, analyticsEventHpViewed, propertiesEventHpLoungeStartingPoint, propertiesEventSuperPower, propertiesValueHpLoungeStartingPointSection, propertiesValueHpLoungeStartingPointSuperpower, trackEvent } from "../../services/Tracking/Analytics";
import User from "../../data/user/User";
import Divider from "../../designSystem/Divider/Divider";
import MenuStyle from "../../styles/menu/MenuStyle";
import InViewModal from "../../../specific/components/InViewModal/InViewModal";
import DeepLink from "../../services/DeepLink/DeepLink";
import MainOptions from "../../models/MainOptions";
import Main from "../../../specific/components/Main/Main";
import { handleUrl } from "../../../specific/utils/Deeplink/Deeplink";
import MenuSegment from "../../services/MenuSegment/MenuSegment";
import ComponentProps from "../../../specific/utils/Interfaces/ComponentProps";
import MenuHeader from "./MenuHeader";
import MenuSuperPower from "./MenuSuperPowers";
import MenuBySections from "./MenuBySections";

interface AppMenuProps extends ComponentProps {

}

/*
 const onViewDidAppear = () => {
        if (viewDidAppear === false) {
            checkTodaysMood();
        }
    }

    const checkTodaysMood = () => {
        if (todaysMoodDone === false) {
            // Launch mood
            todaysMoodDone = true;
            Navigation.showModalInView({
                componentId,
                name: Constants.ScreenMood,
                options: {
                    modalPresentationStyle: 'overCurrentContext',
                    layout: {
                        componentBackgroundColor: 'transparent'
                    }
                }
            });
        }
        setViewDidAppear(true);
    }
        */

const AppMenu = (props: AppMenuProps) => {
    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [segmentMenuDidAppear, setSegmentMenuDidAppear] = useState<boolean>(false);

    const [menuType, setMenuType] = useState<number>(0);

    const menuSegment: MenuSegment = MenuSegment.getInstance();

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const menuStyle: MenuStyle = new MenuStyle();

    const mainViewOpacity: Animated.Value = useRef(new Animated.Value(1)).current;
    const menuTypeValue: Animated.Value = useRef(new Animated.Value(0)).current;

    const inViewModal = useRef<InViewModal>(null);

    const { componentId = "" } = props;
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const mainOptions: MainOptions = new MainOptions({
        key: "app_menu",
        componentId,
        showNavBar: false,
        showMenu: false,
        safeArea: false,
        canGoBack: false,
        showAccountAccess: false
    });

    const user: User = User.getInstance();

    const onViewDidAppear = () => {
        trackEvent(analyticsEventHpViewed);
        const deepLink: DeepLink = DeepLink.getInstance();
        if (deepLink.path.length > 0) {
            handleDeepLink(deepLink.path);
            deepLink.path = "";
        } else {
            setViewDidAppear(true);
        }
    }

    useEffect(() => {
        if (segmentMenuDidAppear === true) {
            testMenuSegmentOrigin();
        }
    }, [segmentMenuDidAppear]);

    const testMenuSegmentOrigin = () => {
        if (menuSegment.origin.length > 0) {
            const activitiesBySectionIndex = user.profile === "family" ? 1 : 0;
            const activitiesBySuperPowersIndex = user.profile === "family" ? 0 : 1;
            if (menuSegment.origin === "section") {
                if (menuType !== activitiesBySectionIndex) {
                    onSelectMenuItem(activitiesBySectionIndex);
                }
            } else if (menuSegment.origin === "superpowers") {
                if (menuType !== activitiesBySuperPowersIndex) {
                    onSelectMenuItem(activitiesBySuperPowersIndex);
                }
            }
        }
    }

    const handleDeepLink = (path: string) => {
        if (path.includes("/appMenu") === true) {
            if (path.includes("/appMenu/skills") === true) {
                if ((user.profile === "teacher") || (user.profile === "animator")) {
                    onSelectMenuItem(1);
                } else {
                    onSelectMenuItem(0);
                }
            }
        } else {
            if (Platform.OS === "web") {
                const directCorrespondanceForSuperRoom = {
                    "/selectSubscription": "sub",
                    "/philo": "philo",
                    "/categoryHome/phi_questions": "philo",
                    "/wellBeing": "well_being",
                    "/categoryHome/wellBeing": "well_being",
                    "/advices": "advices",
                    "/activityTypes": "activity_types",
                    "/superRoom": ""
                }
                let findDirectCorrespondanceLink: boolean = false;
                for (const aDirectCorrespondaceKey in directCorrespondanceForSuperRoom) {
                    if (Object.prototype.hasOwnProperty.call(directCorrespondanceForSuperRoom, aDirectCorrespondaceKey)) {
                        const aTarget: string = directCorrespondanceForSuperRoom[aDirectCorrespondaceKey];
                        if (path.includes(aDirectCorrespondaceKey) === true) {
                            goToSuperRoom(aTarget);
                            findDirectCorrespondanceLink = true;
                            break;
                        }
                    }
                }
                if (path.includes("/cardActivation") === true) {
                    goToSuperRoom(path);
                } else if (path.includes("/stripeCallBack") === true) {
                    let urlArguments: string[] = path.split("/");
                    const subId: string = urlArguments[urlArguments.length - 1];
                    const status: string = urlArguments[urlArguments.length - 2];
                    goToSuperRoom("sub", { status, subId });
                } else if (path.includes("/activitiesBySection") === true) {
                    let section = { key: "" };
                    let sectionsKeys: string[] = ["still", "talk", "grow", "liliAtSchool"];
                    for (const aSectionKeyIndex in sectionsKeys) {
                        if (Object.prototype.hasOwnProperty.call(sectionsKeys, aSectionKeyIndex)) {
                            const aSectionKey: string = sectionsKeys[aSectionKeyIndex];
                            if (path.includes(aSectionKey) === true) {
                                section.key = aSectionKey;
                                launchTheApp({ section });
                                break;
                            }
                        }
                    }
                } else if (path.includes("/activitySheet") === true) {
                    // Quelque soit l'origine, on aura toujours la section en dernier, la catégorie en avant-dernier et enfin le code de l'activité en avant-avant-dernier
                    let urlArguments: string[] = path.split("/");
                    const deepLink: DeepLink = DeepLink.getInstance();
                    deepLink.category_key = urlArguments[urlArguments.length - 2];
                    deepLink.activity_key = urlArguments[urlArguments.length - 3];
                    launchTheApp({ section: { key: urlArguments[urlArguments.length - 1] } });
                }
            } else {
                handleUrl({ url: path, isLaunchedUrl: false, componentId, navigate });
            }
        }

        setViewDidAppear(true);
    }

    const goToSuperRoom = (path?: string, stripe?: { status: string, subId: string }) => {
        /*** STAT ***/
        const loungeRoomByProfile = {
            "family": "parent_lounge",
            "animator": "animator_lounge",
            "teacher": "teacher_lounge"
        }
        const activitiesByTypeIndex = user.profile === "family" ? 1 : 0;
        if (loungeRoomByProfile[user.profile] !== undefined) {
            trackEvent(analyticsEventHpLoungeClicked, {
                "room": loungeRoomByProfile[user.profile],
                [propertiesEventHpLoungeStartingPoint]: menuType === activitiesByTypeIndex ? propertiesValueHpLoungeStartingPointSection : propertiesValueHpLoungeStartingPointSuperpower
            });
        }
        /*** STAT ***/

        let superRoomPath: string = path !== undefined ? path : "";
        Navigation.showModalInView({
            componentId,
            name: Constants.ScreenSuperRoom,
            passProps: {
                path: superRoomPath,
                stripe
            }
        });
    }

    const launchTheApp = (data: { section: any }) => {
        const { section } = data;
        const { key } = section;
        if ((key === "still") || (key === "talk") || (key === "grow") || (key === "liliAtSchool") || (key === "inspire")) {
            if (key === "still") {
                trackEvent("section_calm_clicked");
            } else if (key === "talk") {
                trackEvent("section_expression_clicked");
            } else if (key === "grow") {
                trackEvent("section_philo_clicked");
            } else if (key === "inspire") {
                trackEvent("section_inspire_clicked");
            }
            Navigation.push({
                componentId,
                navigate,
                name: Constants.ScreenActivitiesBySection,
                passProps: {
                    sectionKey: key
                }
            });
        }
    }

    const launchCategory = (data: { categoryKey: string }) => {
        const { categoryKey } = data;
        let passProps = {
            context: user.profile,
            isModal: true,
            category: "sleepMeditation"
        };
        let name = Constants.ScreenFullScreenCategory;
        if (categoryKey === "firstExtra") {
            trackEvent(analyticsEventHpTrainingPathClicked);
            passProps = {
                activityKey: user.profile === "family" ? "ROU_02" : "PAC_16",
                categoryKey: "routineSetUp",
                sectionKey: user.profile === "family" ? "liliAtHome" : "liliAtSchool",
                context: user.profile,
                menu: "superpowers",
                isModal: true
            };
            name = Constants.ScreenActivitySheet;
        } else if (categoryKey === "secondExtra") {
            if ((user.profile === "teacher") || (user.profile === "animator")) {
                name = Constants.ScreenActivitySheet;
                passProps = {
                    activityKey: "PAC_18",
                    categoryKey: "poster_instructions",
                    sectionKey: "liliAtSchool",
                    context: user.profile,
                    menu: "superpowers",
                    isModal: true
                };
                trackEvent(analyticsEventHpSuperpowersPosterClicked);
            } else {
                trackEvent(analyticsEventHpSleepMeditationsClicked);
            }
        }
        if ((Platform.OS === "web") && (name === Constants.ScreenActivitySheet)) {
            inViewModal.current?.showAlert({
                name,
                passProps
            });
        } else {
            Navigation.showModal({
                componentId,
                navigate,
                passProps,
                name
            });
        }
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ flex: 1, width: appTheme.getFullAppWidth(), justifyContent: "center", alignItems: "center", paddingBottom: appTheme.pixelPerfect(20) }}>
                <ActivityIndicator color={appTheme.darkBlue} />
            </View>
        }
        const activitiesByTypeIndex = user.profile === "family" ? 1 : 0;
        if (menuType === activitiesByTypeIndex) {
            return <MenuBySections componentId={componentId} launchTheApp={launchTheApp} />
        } else {
            return <MenuSuperPower componentId={componentId} />
        }
    }

    const onSelectMenuItem = (menuItemIndex: number) => {
        Animated.parallel([
            Animated.sequence([
                Animated.timing(mainViewOpacity, {
                    toValue: 0,
                    duration: 500,
                    useNativeDriver: true
                }),
                Animated.timing(mainViewOpacity, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: true
                })
            ]),
            Animated.timing(menuTypeValue, {
                toValue: 1 - menuType,
                duration: 500,
                useNativeDriver: false
            })
        ]).start(() => {

        });
        setTimeout(() => {
            setMenuType(menuItemIndex);
        }, 500);
    }

    const getBackgroundColor = () => {
        const colorRange = [appTheme.daySkyColor, appTheme.nightSkyColor];
        const outputRange = user.profile === "family" ? colorRange.reverse() : colorRange;
        return menuTypeValue.interpolate({
            inputRange: [0, 1],
            outputRange
        })
    }

    const getGroundColor = () => {
        const colorRange = ["#00BC98", "#515EA0"];
        const outputRange = user.profile === "family" ? colorRange.reverse() : colorRange;
        return menuTypeValue.interpolate({
            inputRange: [0, 1],
            outputRange
        })
    }

    const getWaveColor = () => {
        const colorRange = ["#57537F", "#354F84"];
        const outputRange = user.profile === "family" ? colorRange.reverse() : colorRange;
        return menuTypeValue.interpolate({
            inputRange: [0, 1],
            outputRange
        })
    }

    const getBackgroundImageOpacity = (menuItemIndex: number) => {
        const outputRange: number[] = menuItemIndex === 0 ? [1, 0] : [0, 1];
        return menuTypeValue.interpolate({
            inputRange: [0, 1],
            outputRange: outputRange
        })
    }

    const getExtraContent = () => {
        let finalBlocsSize = (appTheme.getBlockWidth() / 2) - appTheme.pixelPerfect(5);
        if (finalBlocsSize > 256) {
            finalBlocsSize = 256;
        }
        const finalBlocsImageSize = Platform.OS === "web" ? finalBlocsSize / 2 : finalBlocsSize / 3;
        const positionForClouds = Platform.OS === "web" ? finalBlocsSize / 2 : finalBlocsSize * 2 / 3;
        const fontSizeForBottomBlocks = Platform.OS === "web" ? appTheme.pixelPerfectForFont(10) : appTheme.pixelPerfectForFont(6);
        const extraTitle = user.profile === "teacher" ? i18n.t("appMenu.extra.teachers.title") : user.profile === "animator" ? i18n.t("appMenu.extra.animators.title") : i18n.t("appMenu.extra.family.title");
        const firstSectionTitle = user.profile === "teacher" ? i18n.t("appMenu.extra.teachers.first.title") : user.profile === "animator" ? i18n.t("appMenu.extra.animators.first.title") : i18n.t("appMenu.extra.family.first.title");
        const firstSectionBody = user.profile === "teacher" ? i18n.t("appMenu.extra.teachers.first.body") : user.profile === "animator" ? i18n.t("appMenu.extra.animators.first.body") : i18n.t("appMenu.extra.family.first.body");
        const secondSectionTitle = user.profile === "teacher" ? i18n.t("appMenu.extra.teachers.second.title") : user.profile === "animator" ? i18n.t("appMenu.extra.animators.second.title") : i18n.t("appMenu.extra.family.second.title");
        const secondSectionBody = user.profile === "teacher" ? i18n.t("appMenu.extra.teachers.second.body") : user.profile === "animator" ? i18n.t("appMenu.extra.animators.second.body") : i18n.t("appMenu.extra.family.second.body");
        const secondSectionImage = user.profile === "teacher" ? images.icSuperpowerPoster : user.profile === "animator" ? images.icSuperpowerPoster : images.icMeditationsSleep;
        const firstCloudImage = user.profile === "teacher" ? <View /> : <Image resizeMode="contain" source={images.icDay} style={{ width: finalBlocsImageSize * 2 / 3, height: appTheme.pixelPerfect(23), position: "absolute", top: appTheme.pixelPerfect(2), right: positionForClouds }} />;
        const secondCloudImage = user.profile === "teacher" ? <View /> : <Image resizeMode="contain" source={images.icNight} style={{ width: finalBlocsImageSize * 2 / 3, height: appTheme.pixelPerfect(23), position: "absolute", top: appTheme.pixelPerfect(2), left: positionForClouds }} />;
        const extraHeightForWeb = Platform.OS === "web" ? appTheme.pixelPerfect(20) : 0;
        return <View style={{ width: appTheme.getFullAppWidth(), alignItems: "center" }}>
            <Text style={menuStyle.titleStyle}>{extraTitle.toLocaleUpperCase()}</Text>
            <Animated.View style={{ opacity: 1, justifyContent: "space-evenly", alignItems: "center", paddingTop: appTheme.pixelPerfect(10), flexDirection: "row", width: appTheme.getBlockWidth(), paddingBottom: appTheme.pixelPerfect(30) }}>
                <TouchableOpacity onPress={() => launchCategory({ categoryKey: "firstExtra" })} style={{ paddingHorizontal: appTheme.pixelPerfect(5), width: finalBlocsSize, height: finalBlocsImageSize + appTheme.pixelPerfect(40) + extraHeightForWeb, paddingVertical: appTheme.pixelPerfect(5), borderRadius: appTheme.pixelPerfect(10), alignItems: "center" }}>
                    <View style={{ width: "100%", height: finalBlocsImageSize + appTheme.pixelPerfect(20) + extraHeightForWeb, borderRadius: appTheme.pixelPerfect(10), backgroundColor: appTheme.white, position: "absolute", bottom: 0 }} />
                    <Image resizeMode="contain" source={images.icPath} style={{ width: finalBlocsImageSize, height: finalBlocsImageSize }} />
                    <View style={{ width: finalBlocsSize, justifyContent: "center", alignItems: "center" }}>
                        <Text style={[{ fontFamily: appTheme.primaryBoldFont, color: appTheme.nightSkyColor, textAlign: "center", fontSize: fontSizeForBottomBlocks }]}>
                            {firstSectionTitle}
                        </Text>
                        <Text style={[{ fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor, textAlign: "center", fontSize: fontSizeForBottomBlocks }]}>
                            {firstSectionBody}
                        </Text>
                    </View>
                    {firstCloudImage}
                </TouchableOpacity>
                <TouchableOpacity onPress={() => launchCategory({ categoryKey: "secondExtra" })} style={{ paddingHorizontal: appTheme.pixelPerfect(5), width: finalBlocsSize, height: finalBlocsImageSize + appTheme.pixelPerfect(40) + extraHeightForWeb, paddingVertical: appTheme.pixelPerfect(5), borderRadius: appTheme.pixelPerfect(10), alignItems: "center" }}>
                    <View style={{ width: "100%", height: finalBlocsImageSize + appTheme.pixelPerfect(20) + extraHeightForWeb, borderRadius: appTheme.pixelPerfect(10), backgroundColor: appTheme.white, position: "absolute", bottom: 0 }} />
                    <Image resizeMode="contain" source={secondSectionImage} style={{ width: finalBlocsImageSize, height: finalBlocsImageSize }} />
                    <View style={{ width: finalBlocsSize, justifyContent: "center", alignItems: "center" }}>
                        <Text style={[{ fontFamily: appTheme.primaryBoldFont, color: appTheme.nightSkyColor, textAlign: "center", fontSize: fontSizeForBottomBlocks }]}>
                            {secondSectionTitle}
                        </Text>
                        <Text style={[{ fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor, textAlign: "center", fontSize: fontSizeForBottomBlocks }]}>
                            {secondSectionBody}
                        </Text>
                    </View>
                    {secondCloudImage}
                </TouchableOpacity>
            </Animated.View>
        </View>;
    }

    const renderHeaderMenu = () => {
        return <MenuHeader
            menuType={menuType}
            onLayout={() => setSegmentMenuDidAppear(true)}
            onSelectMenuItem={(onSelectMenuItem)} />
    }

    const illustrationPosition = Platform.OS === "web" ? "fixed" : "absolute";
    const illustrationHeight = Platform.OS === "web" ? appTheme.getFullAppWidth() / 9 : appTheme.getFullAppWidth() * 2 / 9;
    const pictoIllustrationHeight = Platform.OS === "web" ? appTheme.pixelPerfect(160) : appTheme.pixelPerfect(80) + appTheme.topInsets;
    const illustationPaddingBottom = Platform.OS === "web" ? appTheme.pixelPerfect(160) : appTheme.pixelPerfect(80) + appTheme.topInsets;
    const bottomButtonWidth = appTheme.getFullAppWidth();
    const bottomButtonHeight = (bottomButtonWidth * 2 / 45);
    const fullBottomViewHeight = appTheme.pixelPerfect(60) + appTheme.bottomInsets;
    const activitiesByTypeIndex = user.profile === "family" ? 1 : 0;
    const activitiesBySkillsIndex = user.profile === "family" ? 0 : 1;
    const backgroundPosition: string = Platform.OS === "web" ? "fixed" : "absolute";

    const getFooter = () => {
        const imageSize = appTheme.pixelPerfect(60);
        const buttonTitle = user.profile === "teacher" ? i18n.t("appMenu.teachers.button.title") : user.profile === "animator" ? i18n.t("appMenu.animators.button.title") : i18n.t("appMenu.parents.button.title");
        const buttonBody = user.profile === "teacher" ? i18n.t("appMenu.teachers.button.body") : user.profile === "animator" ? i18n.t("appMenu.animators.button.body") : i18n.t("appMenu.parents.button.body");
        const buttonImage = user.profile === "teacher" ? images.icSuperTeachersRoom : images.icSuperParentsRoom;
        const paddingBottom = appTheme.bottomInsets > 0 ? appTheme.bottomInsets : appTheme.pixelPerfect(20);
        const waveHeight = Platform.OS === "web" ? bottomButtonWidth * 12 / 384 : bottomButtonWidth * 16 / 360;
        return <View style={{ position: illustrationPosition, bottom: 0, right: 0, left: 0 }}>
            <View style={{ width: appTheme.getFullAppWidth(), height: waveHeight }}>
                <Animated.Image source={images.illTeacherWave} style={{ position: "absolute", width: appTheme.getFullAppWidth(), height: waveHeight, opacity: getBackgroundImageOpacity(activitiesByTypeIndex) }} resizeMode="cover" />
                <Animated.Image source={images.illFamilyWave} style={{ position: "absolute", width: appTheme.getFullAppWidth(), height: waveHeight, opacity: getBackgroundImageOpacity(activitiesBySkillsIndex) }} resizeMode="cover" />
            </View>
            <Animated.View style={{ width: appTheme.getFullAppWidth(), justifyContent: "center", alignItems: "center", backgroundColor: getWaveColor() }}>
                <TouchableOpacity onPress={goToSuperRoom} style={{ flexDirection: "row", width: appTheme.getBlockWidth(), justifyContent: "center", alignItems: "center", paddingBottom }}>
                    <View style={{ flex: 1, paddingHorizontal: appTheme.pixelPerfect(10), alignItems: "center" }}>
                        <Text style={{ fontFamily: appTheme.primaryBoldFont, textTransform: "uppercase", color: appTheme.white, fontSize: appTheme.pixelPerfectForFont(7), textAlign: "center" }}>{buttonTitle}</Text>
                        <Text style={{ fontFamily: appTheme.primaryMediumFont, color: appTheme.white, fontSize: appTheme.pixelPerfectForFont(6), textAlign: "center" }}>{buttonBody}</Text>
                    </View>
                    <Image source={buttonImage} style={{ width: imageSize, height: imageSize, marginEnd: appTheme.pixelPerfect(10) }} resizeMode="contain" />
                </TouchableOpacity>
            </Animated.View >
        </View>
    }

    const renderAppMenuBackground = () => {
        // En fonction du type de menu afficher, on afficher l'une ou l'autre des images de fond
        return <Animated.View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), position: backgroundPosition, backgroundColor: getBackgroundColor() }}>
            <Animated.Image source={images.bgDayClouds} style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), position: "absolute", opacity: getBackgroundImageOpacity(activitiesByTypeIndex) }} resizeMode="contain" />
            <Animated.Image source={images.bgNightStars} style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), position: "absolute", opacity: getBackgroundImageOpacity(activitiesBySkillsIndex) }} resizeMode="cover" />
        </Animated.View>
    }

    const renderAppMenuSet = () => {
        // On affiche les décors du fond, en fonction du type de menu
        return <View style={{ position: illustrationPosition, bottom: 0, right: 0, left: 0, paddingBottom: illustationPaddingBottom }}>
            <Animated.View style={{ width: appTheme.getFullAppWidth(), height: pictoIllustrationHeight + appTheme.pixelPerfect(10), backgroundColor: getGroundColor(), position: "absolute", bottom: 0 }} />
            <View style={{ width: appTheme.getFullAppWidth(), height: illustrationHeight }}>
                <Animated.Image source={images.illDayGround} style={{ position: "absolute", width: appTheme.getFullAppWidth(), height: illustrationHeight, opacity: getBackgroundImageOpacity(activitiesByTypeIndex) }} resizeMode="cover" />
                <Animated.Image source={images.illNightGround} style={{ position: "absolute", width: appTheme.getFullAppWidth(), height: illustrationHeight, opacity: getBackgroundImageOpacity(activitiesBySkillsIndex) }} resizeMode="cover" />
            </View>
        </View>
    }

    return (
        <Main mainOptions={mainOptions}>
            <View style={{ flex: 1, width: appTheme.getFullAppWidth() }}>
                {renderAppMenuBackground()}
                {renderAppMenuSet()}
                <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1, width: appTheme.getFullAppWidth() }}>
                    <View style={{ flex: 1, justifyContent: "center" }}>
                        {renderHeaderMenu()}
                        <Animated.View style={{ flex: 1, opacity: mainViewOpacity }}>
                            {getContent()}
                        </Animated.View>
                        {getExtraContent()}
                        <Divider size={fullBottomViewHeight + bottomButtonHeight} />
                    </View>
                </ScrollView>
                {getFooter()}
                <InViewModal ref={inViewModal} />
            </View>
        </Main>
    )
}

export default AppMenu;

