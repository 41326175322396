import React, { useRef } from "react";

import {
    View,
    Text,
    Platform,
    Image,
    Animated,
    TouchableOpacity,
    ViewStyle,
    ImageStyle
} from "react-native";
import User from "../../data/user/User";
import Images from "../../../specific/utils/Images";
import i18n from "../../utils/Localization/Localization";
import AppTheme from "../../utils/Theme";
import Divider from "../../designSystem/Divider/Divider";
import MenuStyle from "../../styles/menu/MenuStyle";
import { analyticsEventHpSuperPowerClicked, propertiesEventSuperPower, trackEvent } from "../../services/Tracking/Analytics";
import MenuSegment from "../../services/MenuSegment/MenuSegment";
import OtbImage from "../../../specific/utils/OtbImage/OtbImage";
import * as Constants from "../../utils/Constants";
import { useNavigate } from "react-router-dom";

import * as Navigation from "../../../specific/utils/Navigation/Navigation";

interface MenuSuperPowerProps {
    componentId: string
}

const MenuSuperPower = (props: MenuSuperPowerProps) => {

    const { componentId } = props;
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const user: User = User.getInstance();
    const menuSegment: MenuSegment = MenuSegment.getInstance();
    const images: Images = new Images();
    const appTheme: AppTheme = new AppTheme();
    const menuStyle: MenuStyle = new MenuStyle();

    let menuWidth = appTheme.getBlockWidth() - appTheme.pixelPerfect(20);
    let menuHeight = menuWidth * 380 / 285;
    const ratioForMenuHeightInScreen = 3 / 5;
    const ratioForMenuHeight = menuHeight / appTheme.getFullScreenHeight();
    if (ratioForMenuHeight > ratioForMenuHeightInScreen) {
        menuHeight = ratioForMenuHeightInScreen * appTheme.getFullScreenHeight();
        menuWidth = menuHeight * 285 / 380;
    }

    const launchSuperPowerPlaylist = (data: { key: string }) => {
        const { key } = data;
        // ON va vérifier si l'utilisateur est un pro
        trackEvent(analyticsEventHpSuperPowerClicked, { [propertiesEventSuperPower]: key });
        Navigation.push({
            componentId,
            navigate,
            passProps: {
                superPowersKey: key,
                context: Constants.CONTEXT.family
            },
            name: Constants.ScreenSuperPowersPlaylist
        });
    }

    const getSuperPowersContent = () => {
        let finalBlocsSize = (appTheme.getBlockWidth() / 2) - appTheme.pixelPerfect(10);
        if (finalBlocsSize > 256) {
            finalBlocsSize = 256;
        }

        let superPowerMenuWidth = menuWidth;
        let superPowerMenuHeight = menuHeight;
        let beamAndCatView = [];
        let titleViews = [];
        if (Platform.OS === "web") {
            superPowerMenuHeight = appTheme.getBlockWidth() * 288 / 384;
            superPowerMenuWidth = appTheme.getBlockWidth();
            const beamAndCatImages = [
                {
                    img: images.illHomeBeamApprivoiserMesEmotions,
                    height: superPowerMenuHeight / 2,
                    width: 556 * (superPowerMenuHeight / 2) / 883,
                    top: 0,
                    left: superPowerMenuWidth / 2
                },
                {
                    img: images.illHomeBeamMusclerMaConcentration,
                    width: (superPowerMenuWidth / 2) * 0.9,
                    height: 383 * ((superPowerMenuWidth / 2) * 0.9) / 1002,
                    bottom: superPowerMenuHeight / 2,
                    left: superPowerMenuWidth / 2
                },
                {
                    img: images.illHomeBeamDompterErreur,
                    width: (superPowerMenuWidth / 2) * 0.7,
                    height: 569 * ((superPowerMenuWidth / 2) * 0.7) / 747,
                    top: superPowerMenuHeight / 2,
                    left: superPowerMenuWidth / 2
                },
                {
                    img: images.illHomeBeamOserAOral,
                    height: superPowerMenuHeight / 2,
                    width: 381 * (superPowerMenuHeight / 2) / 892,
                    top: superPowerMenuHeight / 2,
                    left: (superPowerMenuWidth / 2) - (381 * (superPowerMenuHeight / 2) / 892) / 2
                },
                {
                    img: images.illHomeBeamLaJouerCollectif,
                    width: (superPowerMenuWidth / 2) * 0.7,
                    height: 569 * ((superPowerMenuWidth / 2) * 0.7) / 747,
                    top: superPowerMenuHeight / 2,
                    right: superPowerMenuWidth / 2
                },
                {
                    img: images.illHomeBeamReflechirSurLaVie,
                    width: (superPowerMenuWidth / 2) * 0.9,
                    height: 383 * ((superPowerMenuWidth / 2) * 0.9) / 1002,
                    bottom: superPowerMenuHeight / 2,
                    right: superPowerMenuWidth / 2
                },
                {
                    img: images.illHomeBeamCroireEnMoi,
                    height: superPowerMenuHeight / 2,
                    width: 532 * (superPowerMenuHeight / 2) / 878,
                    top: 0,
                    right: superPowerMenuWidth / 2
                },
                {
                    img: images.illHomeSupercat,
                    height: superPowerMenuHeight - appTheme.pixelPerfect(80),
                    width: superPowerMenuWidth - appTheme.pixelPerfect(80),
                    top: appTheme.pixelPerfect(40),
                    left: appTheme.pixelPerfect(40)
                }
            ];

            for (const aBeamAndCatIndex in beamAndCatImages) {
                if (Object.prototype.hasOwnProperty.call(beamAndCatImages, aBeamAndCatIndex)) {
                    const aBeamAndCaTImage = beamAndCatImages[aBeamAndCatIndex];
                    let imageStyle = {
                        position: "absolute",
                        width: aBeamAndCaTImage.width,
                        height: aBeamAndCaTImage.height
                    }
                    if (aBeamAndCaTImage.left !== undefined) {
                        imageStyle.left = aBeamAndCaTImage.left;
                    }
                    if (aBeamAndCaTImage.right !== undefined) {
                        imageStyle.right = aBeamAndCaTImage.right;
                    }
                    if (aBeamAndCaTImage.bottom !== undefined) {
                        imageStyle.bottom = aBeamAndCaTImage.bottom;
                    }
                    if (aBeamAndCaTImage.top !== undefined) {
                        imageStyle.top = aBeamAndCaTImage.top;
                    }
                    beamAndCatView.push(<View key={aBeamAndCatIndex} style={{ width: superPowerMenuWidth, height: superPowerMenuHeight, position: "absolute" }}>
                        <Image source={aBeamAndCaTImage.img} style={imageStyle} />
                    </View>);
                }
            }
            const heightRatio = superPowerMenuHeight / 2880;
            const widthRatio = superPowerMenuWidth / 2160;
            const titleImages = [
                {
                    key: Constants.superPowersKeys.EMOTIONS_TAMING,
                    top: 103,
                    right: 556,
                    width: 430,
                    height: 364,
                    image: images.icHomeApprivoiserMesEmotions
                },
                {
                    key: Constants.superPowersKeys.FOCUS,
                    top: 850,
                    right: 50,
                    width: 570,
                    height: 470,
                    image: images.icHomeMusclerMaConcentration
                },
                {
                    key: Constants.superPowersKeys.ERROR_TAMING,
                    top: 1850,
                    right: 260,
                    width: 515,
                    height: 515,
                    image: images.icHomeDompterErreur
                },
                {
                    key: Constants.superPowersKeys.SPEAK,
                    top: 2300,
                    right: 830,
                    width: 500,
                    height: 500,
                    image: images.icHomeOserAOral
                },
                {
                    key: Constants.superPowersKeys.CRITICAL_THINKING,
                    top: 850,
                    left: 10,
                    width: 640,
                    height: 540,
                    image: images.icHomeReflechirSurLaVie
                },
                {
                    key: Constants.superPowersKeys.COLLECTIVE,
                    top: 1850,
                    left: 260,
                    width: 515,
                    height: 515,
                    image: images.icHomeLaJouerCollectif
                },
                {
                    key: Constants.superPowersKeys.SELF_ESTEEM,
                    top: 90,
                    left: 540,
                    width: 430,
                    height: 464,
                    image: images.icHomeCroireEnMoi
                }
            ];
            for (const aTitleIndex in titleImages) {
                if (Object.prototype.hasOwnProperty.call(titleImages, aTitleIndex)) {
                    const aTitleImage = titleImages[aTitleIndex];
                    const { key, top, right = -1, left = -1, width, height, image } = aTitleImage;
                    let titleViewStyle = { top: (top * heightRatio) + appTheme.pixelPerfect(5), position: "absolute", justifyContent: 'flex-start', alignItems: "flex-start" };
                    if (right !== -1) {
                        titleViewStyle.right = (right * widthRatio) + appTheme.pixelPerfect(5);
                    } else if (left !== -1) {
                        titleViewStyle.left = (left * widthRatio) + appTheme.pixelPerfect(5);
                    }
                    titleViews.push(<View key={key} style={titleViewStyle}>
                        <TouchableOpacity onPress={() => launchSuperPowerPlaylist({ key })}>
                            <OtbImage resizeMode="contain" source={image} style={{ width: (width * widthRatio) - appTheme.pixelPerfect(10), height: (height * heightRatio) - appTheme.pixelPerfect(10) }} />
                        </TouchableOpacity>
                    </View >);
                }
            }
        } else {
            const beamAndCatImages = [
                images.illHomeBeamApprivoiserMesEmotions,
                images.illHomeBeamMusclerMaConcentration,
                images.illHomeBeamDompterErreur,
                images.illHomeBeamOserAOral,
                images.illHomeBeamReflechirSurLaVie,
                images.illHomeBeamLaJouerCollectif,
                images.illHomeBeamCroireEnMoi,
                images.illHomeSupercat
            ];

            for (const aBeamAndCatIndex in beamAndCatImages) {
                if (Object.prototype.hasOwnProperty.call(beamAndCatImages, aBeamAndCatIndex)) {
                    const aBeamAndCaTImage = beamAndCatImages[aBeamAndCatIndex];
                    beamAndCatView.push(<View key={aBeamAndCatIndex} style={{ width: menuWidth, height: menuHeight, position: "absolute", transform: [{ scale: 1 }] }}>
                        <OtbImage source={aBeamAndCaTImage} style={{ width: menuWidth, height: menuHeight }} />
                    </View>);
                }
            }

            const heightRatio = superPowerMenuHeight / 2880;
            const widthRatio = superPowerMenuWidth / 2160;
            const titleImages = [
                {
                    key: Constants.superPowersKeys.EMOTIONS_TAMING,
                    top: 103,
                    right: 300,
                    width: 608,
                    height: 515,
                    image: images.icHomeApprivoiserMesEmotions
                },
                {
                    key: Constants.superPowersKeys.FOCUS,
                    top: 1000,
                    right: 66,
                    width: 630,
                    height: 515,
                    image: images.icHomeMusclerMaConcentration
                },
                {
                    key: Constants.superPowersKeys.ERROR_TAMING,
                    top: 1816,
                    right: 110,
                    width: 515,
                    height: 515,
                    image: images.icHomeDompterErreur
                },
                {
                    key: Constants.superPowersKeys.SPEAK,
                    top: 2256,
                    right: 820,
                    width: 518,
                    height: 518,
                    image: images.icHomeOserAOral
                },
                {
                    key: Constants.superPowersKeys.CRITICAL_THINKING,
                    top: 1030,
                    left: 99,
                    width: 570,
                    height: 455,
                    image: images.icHomeReflechirSurLaVie
                },
                {
                    key: Constants.superPowersKeys.COLLECTIVE,
                    top: 1824,
                    left: 110,
                    width: 515,
                    height: 515,
                    image: images.icHomeLaJouerCollectif
                },
                {
                    key: Constants.superPowersKeys.SELF_ESTEEM,
                    top: 102,
                    left: 325,
                    width: 475,
                    height: 475,
                    image: images.icHomeCroireEnMoi
                }
            ];
            for (const aTitleIndex in titleImages) {
                if (Object.prototype.hasOwnProperty.call(titleImages, aTitleIndex)) {
                    const aTitleImage = titleImages[aTitleIndex];
                    const { key, top, right = -1, left = -1, width, height, image } = aTitleImage;
                    let titleViewStyle = { top: (top * heightRatio) + appTheme.pixelPerfect(5), position: "absolute", justifyContent: 'flex-start', alignItems: "flex-start" };
                    if (right !== -1) {
                        titleViewStyle.right = (right * widthRatio) + appTheme.pixelPerfect(5);
                    } else if (left !== -1) {
                        titleViewStyle.left = (left * widthRatio) + appTheme.pixelPerfect(5);
                    }
                    titleViews.push(<View key={key} style={titleViewStyle}>
                        <TouchableOpacity onPress={() => launchSuperPowerPlaylist({ key })}>
                            <OtbImage resizeMode="contain" source={image} style={{ width: (width * widthRatio) - appTheme.pixelPerfect(10), height: (height * heightRatio) - appTheme.pixelPerfect(10) }} />
                        </TouchableOpacity>
                    </View >);
                }
            }
        }

        return (<View style={{ justifyContent: 'flex-start', alignItems: "center", marginVertical: appTheme.pixelPerfect(20) }}>
            <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: "center" }}>
                <View style={{ width: superPowerMenuWidth, height: superPowerMenuHeight }}>
                    {beamAndCatView}
                    {titleViews}
                </View>
            </View>
        </View >)
    }

    return getSuperPowersContent();
}

export default MenuSuperPower;