import React, { useContext, useRef } from "react";

import {
    View,
    Text,
    Platform,
    Image,
    Animated,
    TouchableOpacity,
    ViewStyle,
    ImageStyle
} from "react-native";
import User from "../../data/user/User";
import Images from "../../../specific/utils/Images";
import i18n from "../../utils/Localization/Localization";
import AppTheme from "../../utils/Theme";
import Divider from "../../designSystem/Divider/Divider";
import MenuStyle from "../../styles/menu/MenuStyle";
import { trackEvent } from "../../services/Tracking/Analytics";
import MenuSegment from "../../services/MenuSegment/MenuSegment";
// Data
import { LocalDBContext } from "../../data/LocalDB";
import OtbImage from "../../../specific/utils/OtbImage/OtbImage";

interface MenuBySectionsProps {
    componentId: string,
    launchTheApp:(data: {section: any}) => void
}

const MenuBySections = (props: MenuBySectionsProps) => {

    const { componentId, launchTheApp } = props;

    const localDBData = useContext(LocalDBContext);

    const user: User = User.getInstance();
    const menuSegment: MenuSegment = MenuSegment.getInstance();
    const images: Images = new Images();
    const appTheme: AppTheme = new AppTheme();
    const menuStyle: MenuStyle = new MenuStyle();

    const bodyTextSize = appTheme.pixelPerfectForFont(8);
    const sections = localDBData.sections.data;
    let mainMenuButtons = [
        <View />,
        <View />,
        <View />,
        <View />
    ];

    // Liste des sections à afficher
    //const sectionKeyArray: string[] = ["still", "talk", "grow"]; 
    const sectionKeyArray: string[] = ["inspire", "still", "talk", "grow"];
    for (const aLocalSectionKey in sections) {
        if (Object.hasOwnProperty.call(sections, aLocalSectionKey)) {
            const aLocalSection = sections[aLocalSectionKey];
            const aLocalSectionTitle = ((aLocalSection.title !== undefined) && (aLocalSection.title.fr !== undefined)) ? aLocalSection.title.fr : "";
            let aLocalSectionBody = ((aLocalSection.appMenuBody !== undefined) && (aLocalSection.appMenuBody.fr !== undefined)) ? aLocalSection.appMenuBody.fr : "";
            if (user.profile !== undefined) {
                if ((aLocalSection[user.profile] !== undefined) && (aLocalSection[user.profile].appMenuBody !== undefined) && (aLocalSection[user.profile].appMenuBody.fr !== undefined)) {
                    aLocalSectionBody = aLocalSection[user.profile].appMenuBody.fr;
                }
            }
            if (sectionKeyArray.indexOf(aLocalSection.key) !== -1) {
                const colors = aLocalSection.clr !== undefined ? aLocalSection.clr : { "action": "#ff855f", "main": "#ff855f", "txt": "#25364f" };
                const textColor: string = colors.menu_txt !== undefined ? colors.menu_text : "#25364f";
                let buttonPosition = 0;
                let buttonImage = images.illStill;
                if (aLocalSection.key === "still") {
                    buttonPosition = 1;
                    buttonImage = images.illStill;
                } else if (aLocalSection.key === "talk") {
                    buttonPosition = 2;
                    buttonImage = images.illTalk;
                } else if (aLocalSection.key === "grow") {
                    buttonPosition = 3;
                    buttonImage = images.illGrow;
                } else if (aLocalSection.key === "inspire") {
                    buttonPosition = 0;
                    buttonImage = images.icInspiration;
                } else if (aLocalSection.key === "actForThePlanet") {
                    buttonPosition = 4;
                    buttonImage = images.icPlanet;
                }
                const imageSize = appTheme.getFlexDirectionForSplitScreen() === "row" ? appTheme.pixelPerfect(130) : appTheme.pixelPerfect(66);
                const menuButton = <TouchableOpacity key={aLocalSection.key} onPress={() => launchTheApp({ section: aLocalSection })} style={[menuStyle.headingButtonStyle, { backgroundColor: colors.main }]}>
                    <OtbImage source={buttonImage} style={{ width: imageSize, height: imageSize }} resizeMode="contain" />
                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                        <Text style={[{ color: textColor, fontFamily: appTheme.superHerosFontBold, textAlign: "center", fontSize: bodyTextSize }]}>{aLocalSectionTitle.toLocaleUpperCase()}</Text>
                        <Text style={[{ fontFamily: appTheme.primarySemiBoldFont, color: textColor, textAlign: "center", fontSize: bodyTextSize }]}>{aLocalSectionBody}</Text>
                    </View>
                </TouchableOpacity>;
                mainMenuButtons[buttonPosition] = menuButton;
            }
        }
    }
    const flexDirection = appTheme.getFlexDirectionForSplitScreen();
    const widthForMenu = flexDirection === "row" ? (appTheme.pixelPerfect(140) * mainMenuButtons.length + appTheme.pixelPerfect(20) * mainMenuButtons.length - 1) : appTheme.getFullAppWidth();
    return (
        <View style={{ flex: 1, width: appTheme.getFullAppWidth(), justifyContent: "center", alignItems: "center", paddingBottom: appTheme.pixelPerfect(20) }}>
            <View style={{ flexDirection, flex: 1, width: widthForMenu, justifyContent: "space-evenly", alignItems: "center", paddingBottom: appTheme.pixelPerfect(20) }}>
                {mainMenuButtons}
            </View>
        </View>
    )
}

export default MenuBySections;