import React from "react";
import {
    Text,
    View
} from "react-native";

import Splashscreen from "../../../shared/components/Splashcreen/Splashscreen";
import CasSplashscreen from "../../../shared/components/Splashcreen/CasSplashscreen";

import { ScreenAbout, ScreenActivityMediaList, ScreenAdminAmplitude, ScreenAdminQuoteManagement, ScreenAuthLanding, ScreenAuthentication, ScreenCardActivation, ScreenCategoryHome, ScreenDeeplink, ScreenOnboarding, ScreenSplashscreen, ScreenStripeCallback, ScreenSuperAdmin, ScreenSuperPowersPlaylist, ScreenSuperRoom, ScreenTicketForm, ScreenUserProfile, ScreenUserRewards } from "../../../shared/utils/Constants";
import { Location, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { screenWebPath } from "./ScreenPath";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import { AppTarget, getAppTarget } from "./Host";
import User from "../../../shared/data/user/User";
import About from "../../components/React/About";
import UserGiftCards from "../../../shared/components/Profile/UserGiftCards";
import { ScreenLegalMentions } from "../../../shared/utils/Constants";
import LegalMentions from "../../../shared/components/Legal/LegalMentions";
import { ScreenTeacherForm } from "../../../shared/utils/Constants";
import TeacherForm from "../../../shared/components/SchoolCommunication/TeacherForm";
import { ScreenActivitiesBySection } from "../../../shared/utils/Constants";
import ActivityMediaList from "../../../shared/components/Activities/ActivityMediaList/ActivityMediaList";
import { ScreenActivityForm } from "../../../shared/utils/Constants";
import ActivityForm from "../../../shared/components/Admin/ActivityForm";
import { ScreenActivitiesTags } from "../../../shared/utils/Constants";
import ActivitiesTags from "../../../shared/components/Activities/ActivitiesTags";
import { ScreenActivityPager } from "../../../shared/utils/Constants";
import ActivityPager from "../../../shared/components/Activities/ActivityPager";
import { ScreenActivitySheet } from "../../../shared/utils/Constants";
import ActivitySheet from "../../../shared/components/Activities/ActivitySheet";
import { ScreenAdminGifts } from "../../../shared/utils/Constants";
import AdminGifts from "../../components/Admin/AdminGifts";
import { ScreenAppMenu } from "../../../shared/utils/Constants";
import { ScreenContact } from "../../../shared/utils/Constants";
import Contact from "../../../shared/components/About/Contact";
import { ScreenFullScreenCategory } from "../../../shared/utils/Constants";
import FullScreenCategory from "../../../shared/components/Activities/FullScreenCategory";
import { ScreenMood } from "../../../shared/utils/Constants";
import Mood from "../../../shared/components/Mood/Mood";
import { ScreenSubscription } from "../../../shared/utils/Constants";
import Subscription from "../../../shared/components/Subscription/Subscription";
import { ScreenSelectedSubscription } from "../../../shared/utils/Constants";
import SuperAdmin from "../../../shared/components/Admin/SuperAdmin";
import SuperPowersPlaylist from "../../../shared/components/Superpowers/SuperPowersPlaylist";
import UserProfile from "../../../shared/components/Profile/UserProfile";
import UserRewards from "../../../shared/components/UserRewards/UserRewards";
import { ProtectedRoute } from "./ProtectedRoute";
import CategoryHome from "../../../shared/components/Categories/CategoryHome";
import Landing from "../../../shared/components/Authentication/Landing/Landing";
import AdminAmplitude from "../../components/Admin/AdminAmplitude";
import QuotesManagement from "../../../shared/components/Admin/QuotesManagement";
import SectionCategories from "../../../shared/components/Section/SectionCategories";
import LHQHome from "../../../shared/components/LiliHQ/LHQHome";
import SuperRoom from "../../../shared/components/Rooms/SuperRoom";
import TicketForm from "../../../shared/components/Support/TicketForm";
import AppMenu from "../../../shared/components/Menu/Menu";

function AnimatedRoutes() {

    const appTarget: AppTarget = getAppTarget();
    const SplashscreenComponent: (props) => JSX.Element = appTarget === "public" ? Splashscreen : appTarget === "lhq" ? LHQHome : CasSplashscreen;
    const location: Location = useLocation();
    return (
        <AnimatePresence exitBeforeEnter initial={false}>
            <Routes location={location} key={location.pathname}>
                <Route path={screenWebPath[ScreenTicketForm]} element={<TicketForm />} />
                <Route path={screenWebPath[ScreenAbout]} element={<About />} />
                <Route element={<ProtectedRoute location={location} />}>
                    <Route path={screenWebPath[ScreenActivitySheet]} element={<ActivitySheet />} />
                    <Route path={screenWebPath[ScreenAppMenu]} element={<AppMenu />} />
                    <Route path={screenWebPath[ScreenActivityForm]} element={<ActivityForm />} />
                    <Route path={screenWebPath[ScreenActivityMediaList]} element={<ActivityMediaList />} />
                    <Route path={screenWebPath[ScreenActivitiesTags]} element={<ActivitiesTags />} />
                    <Route path={screenWebPath[ScreenActivityPager]} element={<ActivityPager />} />
                    <Route path={screenWebPath[ScreenAdminGifts]} element={<AdminGifts />} />
                    <Route path={screenWebPath[ScreenAdminAmplitude]} element={<AdminAmplitude />} />
                    <Route path={screenWebPath[ScreenAdminQuoteManagement]} element={<QuotesManagement />} />
                    <Route path={screenWebPath[ScreenActivitiesBySection]} element={<SectionCategories />} />
                    <Route path={screenWebPath[ScreenCategoryHome]} element={<CategoryHome />} />
                    <Route path={screenWebPath[ScreenFullScreenCategory]} element={<FullScreenCategory />} />
                    <Route path={screenWebPath[ScreenMood]} element={<Mood />} />
                    <Route path={screenWebPath[ScreenSelectedSubscription]} element={<Subscription />} />
                    <Route path={screenWebPath[ScreenStripeCallback]} element={<Subscription />} />
                    <Route path={screenWebPath[ScreenSubscription]} element={<Subscription />} />
                    <Route path={screenWebPath[ScreenSuperAdmin]} element={<SuperAdmin />} />
                    <Route path={screenWebPath[ScreenSuperPowersPlaylist]} element={<SuperPowersPlaylist />} />
                    <Route path={screenWebPath[ScreenUserProfile]} element={<UserProfile />} />
                    <Route path={screenWebPath[ScreenUserRewards]} element={<UserRewards />} />
                    <Route path={screenWebPath[ScreenCardActivation]} element={<UserGiftCards />} />
                    <Route path={screenWebPath[ScreenSuperRoom]} element={<SuperRoom />} />
                    <Route path={ScreenCardActivation} element={<UserGiftCards />} />
                </Route>
                <Route path={screenWebPath[ScreenAuthLanding]} element={<Landing />} />
                <Route path={screenWebPath[ScreenContact]} element={<Contact />} />
                <Route path={screenWebPath[ScreenDeeplink]} element={<SplashscreenComponent />} />
                <Route path={screenWebPath[ScreenLegalMentions]} element={<LegalMentions />} />
                <Route path={screenWebPath[ScreenSplashscreen]} element={<SplashscreenComponent />} />
                <Route path={ScreenSplashscreen} element={<SplashscreenComponent />} />
                <Route path={screenWebPath[ScreenTeacherForm]} element={<TeacherForm />} />
                <Route path={"*"} element={<SplashscreenComponent />} />
            </Routes>
        </AnimatePresence >
    );
}

export default AnimatedRoutes;