import { useColorScheme } from "react-native";

interface bundleImage {
    uri: string;
}

class Images {

    isDarkMode: boolean = useColorScheme() === "dark";

    adv01JeMePose : bundleImage | number;
    adv02JeMExprime : bundleImage | number;
    adv03JeGrandis : bundleImage | number;
    aut01Cestquoiunami : bundleImage | number;
    aut02Cavaonrigole : bundleImage | number;
    aut03Mechantougentil : bundleImage | number;
    aut04Nesoispasjaloux : bundleImage | number;
    aut05Fillegarconcestdifferent : bundleImage | number;
    aut06Ondoittoujoursetredaccord : bundleImage | number;
    aut07Onpartage : bundleImage | number;
    aut08Cestpasjuste : bundleImage | number;
    aut09Faismoiconfiance : bundleImage | number;
    aut10Quicommande : bundleImage | number;
    badgeAgile : bundleImage | number;
    badgeAgileBw : bundleImage | number;
    badgeAudacieux : bundleImage | number;
    badgeAudacieuxBw : bundleImage | number;
    badgeAventurier : bundleImage | number;
    badgeAventurierBw : bundleImage | number;
    badgeCollectionneur : bundleImage | number;
    badgeCollectionneurBw : bundleImage | number;
    badgeComedien : bundleImage | number;
    badgeComedienBw : bundleImage | number;
    badgeCourageux : bundleImage | number;
    badgeCourageuxBw : bundleImage | number;
    badgeCurieux : bundleImage | number;
    badgeCurieuxBw : bundleImage | number;
    badgeEnqueteur : bundleImage | number;
    badgeEnqueteurBw : bundleImage | number;
    badgeExplorateur : bundleImage | number;
    badgeExplorateurBw : bundleImage | number;
    badgeExpressif : bundleImage | number;
    badgeExpressifBw : bundleImage | number;
    badgeGrandLecteur : bundleImage | number;
    badgeGrandLecteurBw : bundleImage | number;
    badgeGrandSage : bundleImage | number;
    badgeGrandSageBw : bundleImage | number;
    badgeIntellectuel : bundleImage | number;
    badgeIntellectuelBw : bundleImage | number;
    badgeJoueur : bundleImage | number;
    badgeJoueurBw : bundleImage | number;
    badgeMaitreZen : bundleImage | number;
    badgeMaitreZenBw : bundleImage | number;
    badgeMelomane : bundleImage | number;
    badgeMelomaneBw : bundleImage | number;
    badgePose : bundleImage | number;
    badgePoseBw : bundleImage | number;
    badgeRepose : bundleImage | number;
    badgeReposeBw : bundleImage | number;
    badgeReveur : bundleImage | number;
    badgeReveurBw : bundleImage | number;
    badgeRoiDeLaScene : bundleImage | number;
    badgeRoiDeLaSceneBw : bundleImage | number;
    badgeSerein : bundleImage | number;
    badgeSereinBw : bundleImage | number;
    badgeStar : bundleImage | number;
    badgeStarBw : bundleImage | number;
    badgeTranquille : bundleImage | number;
    badgeTranquilleBw : bundleImage | number;
    bgAnimatorPictos : bundleImage | number;
    bgBtnLiliAtExtracurricular : bundleImage | number;
    bgBtnLiliAtHome : bundleImage | number;
    bgBtnLiliAtSchool : bundleImage | number;
    bgDayClouds : bundleImage | number;
    bgDayGrass : bundleImage | number;
    bgNightStars : bundleImage | number;
    bgOnboarding : bundleImage | number;
    bgOnboardingAnimators : bundleImage | number;
    bgOnboardingParent : bundleImage | number;
    bgOnboardingTeacher : bundleImage | number;
    bgParentsPictos : bundleImage | number;
    bgTeacherformLeft : bundleImage | number;
    bgTeacherformRight : bundleImage | number;
    bgTeacherPictos : bundleImage | number;
    bio01HelenKeller : bundleImage | number;
    bio02CharlieChaplin : bundleImage | number;
    bio03GretaThunberg : bundleImage | number;
    bio04ValentinHauy : bundleImage | number;
    bio05KeithHaring : bundleImage | number;
    bio06ThomasPesquet : bundleImage | number;
    bio07PratimaSherpa : bundleImage | number;
    bioa02MerceCunningham : bundleImage | number;
    biobe01VictorHugo : bundleImage | number;
    biobe02CarolineCriadoPerez : bundleImage | number;
    biomm01JosephineBaker : bundleImage | number;
    biomm02EmmaWatson : bundleImage | number;
    bions01TempleGrandin : bundleImage | number;
    bions02CarlSagan : bundleImage | number;
    biop01NelsonMandela : bundleImage | number;
    biop02OlympeDeGouges : bundleImage | number;
    biosa01TheoCurin : bundleImage | number;
    biosa02SerenaWilliams : bundleImage | number;
    biosi01AdaLovelace : bundleImage | number;
    biosi02ShigeruMiyamoto : bundleImage | number;
    bul01Lunivers : bundleImage | number;
    bul02Laplage : bundleImage | number;
    bul03Laferme : bundleImage | number;
    bul04Lajungle : bundleImage | number;
    bul05Locean : bundleImage | number;
    catPhiloFormation : bundleImage | number;
    catPhiloFormationAnimator : bundleImage | number;
    catPhiloFormationAnimatorRoom : bundleImage | number;
    catPhiloFormationTeacherRoom : bundleImage | number;
    catWellBeing : bundleImage | number;
    contexte01ArcEnCielApparait : bundleImage | number;
    contexte02Brouhaha : bundleImage | number;
    contexte03CoeurBrise : bundleImage | number;
    contexte04Coeur : bundleImage | number;
    contexte05EtoileFilante : bundleImage | number;
    contexte06IlSeMetANeiger : bundleImage | number;
    contexte07IlSeMetPleuvoir : bundleImage | number;
    contexte08OnEntendDeLaMusique : bundleImage | number;
    contexte09Orage : bundleImage | number;
    contexte10PleineLune : bundleImage | number;
    contexte11ReveilSonne : bundleImage | number;
    contexte12SoleilSeCouche : bundleImage | number;
    contexte13SoleilSeLeve : bundleImage | number;
    contexte14TvAllumee : bundleImage | number;
    decor01Cascade : bundleImage | number;
    decor02ChateauFort : bundleImage | number;
    decor03Etoiles : bundleImage | number;
    decor04Ferme : bundleImage | number;
    decor05FeteForaine : bundleImage | number;
    decor06ForetDeLumiere : bundleImage | number;
    decor07IleDeserte : bundleImage | number;
    decor08IleInterieure : bundleImage | number;
    decor09Jungle : bundleImage | number;
    decor10LicornePrendSonBain : bundleImage | number;
    decor11MerDeNuages : bundleImage | number;
    decor12Montagne : bundleImage | number;
    decor13MurailleDeChine : bundleImage | number;
    decor14Phare : bundleImage | number;
    decor15Plage : bundleImage | number;
    decor16Pyramides : bundleImage | number;
    decor17Savane : bundleImage | number;
    ded01JeMeSouviens : bundleImage | number;
    ded02RecetteMartienne : bundleImage | number;
    ded03ALaventure : bundleImage | number;
    ded04Victoire : bundleImage | number;
    ded05LeGateauSurprise : bundleImage | number;
    ded06MemePasPeur : bundleImage | number;
    ded07EtSiOnChantait : bundleImage | number;
    ded08Chut : bundleImage | number;
    dscv00ActivitiesDiscovery : bundleImage | number;
    emo01Jemesensbien : bundleImage | number;
    emo02Jesuisamoureux : bundleImage | number;
    emo03Netinquietepas : bundleImage | number;
    emo04Netenervepascommeca : bundleImage | number;
    emo05Jaipeur : bundleImage | number;
    emo06Nesoispastimide : bundleImage | number;
    emo07Jesuistristecestgrave : bundleImage | number;
    headerSectionGrow : bundleImage | number;
    headerSectionInspire : bundleImage | number;
    headerSectionStill : bundleImage | number;
    headerSectionTalk : bundleImage | number;
    icAbout : bundleImage | number;
    icArrow : bundleImage | number;
    icCanope : bundleImage | number;
    icCatPhiloFormation : bundleImage | number;
    icCloud : bundleImage | number;
    icDay : bundleImage | number;
    icEquipement : bundleImage | number;
    icFacebook : bundleImage | number;
    icHeaderAnimatorsWindow : bundleImage | number;
    icHeaderParentsWindow : bundleImage | number;
    icHeaderTeacherBoard : bundleImage | number;
    icHomeApprivoiserMesEmotions : bundleImage | number;
    icHomeCroireEnMoi : bundleImage | number;
    icHomeDompterErreur : bundleImage | number;
    icHomeLaJouerCollectif : bundleImage | number;
    icHomeMusclerMaConcentration : bundleImage | number;
    icHomeOserAOral : bundleImage | number;
    icHomeReflechirSurLaVie : bundleImage | number;
    icInspiration : bundleImage | number;
    icLiliAtExtracurricularCircle : bundleImage | number;
    icLiliAtHomeCircle : bundleImage | number;
    icLiliAtSchoolCircle : bundleImage | number;
    icLiliCircle : bundleImage | number;
    icLiliLesPetitsChampionsLogo : bundleImage | number;
    icMeditationsMusic : bundleImage | number;
    icMeditationsSleep : bundleImage | number;
    icMinistere : bundleImage | number;
    icMinistereWhite : bundleImage | number;
    icMoodAngry : bundleImage | number;
    icMoodAnnoyed : bundleImage | number;
    icMoodCalme : bundleImage | number;
    icMoodHappy : bundleImage | number;
    icMoodSad : bundleImage | number;
    icMoodStressed : bundleImage | number;
    icNight : bundleImage | number;
    icOwlFlysuit : bundleImage | number;
    icOwlMaster : bundleImage | number;
    icOwlYoda : bundleImage | number;
    icParentMacaroon : bundleImage | number;
    icParentsPremium : bundleImage | number;
    icPath : bundleImage | number;
    icPeriscolaire : bundleImage | number;
    icPlanet : bundleImage | number;
    icPremium : bundleImage | number;
    icSuperParentsRoom : bundleImage | number;
    icSuperTeachersRoom : bundleImage | number;
    icSuperpowerPoster : bundleImage | number;
    icTalk : bundleImage | number;
    icTalkEyes : bundleImage | number;
    icTeacherMacaroon : bundleImage | number;
    icTeacherProfs : bundleImage | number;
    icTeacherSuperProgrammes : bundleImage | number;
    icTeacherTogether : bundleImage | number;
    icTeachersWorkshops : bundleImage | number;
    icTneMap : bundleImage | number;
    icTraining : bundleImage | number;
    icWarmUp : bundleImage | number;
    icWellBeing : bundleImage | number;
    illversiontwocoming : bundleImage | number;
    illAnimatorSignUpFinal : bundleImage | number;
    illAnimatorSignUpFinalBis : bundleImage | number;
    illDayGround : bundleImage | number;
    illFamilyWave : bundleImage | number;
    illGrow : bundleImage | number;
    illHeaderAnimatorsRoom : bundleImage | number;
    illHeaderApprivoiserMesEmotions : bundleImage | number;
    illHeaderCroireEnMoi : bundleImage | number;
    illHeaderDompterErreur : bundleImage | number;
    illHeaderLaJouerCollectif : bundleImage | number;
    illHeaderMeditationsPourSEndormir : bundleImage | number;
    illHeaderMusclerSaConcentration : bundleImage | number;
    illHeaderMusiquesPourSEndormir : bundleImage | number;
    illHeaderOserALOral : bundleImage | number;
    illHeaderParentsRoom : bundleImage | number;
    illHeaderReflechirSurLaVie : bundleImage | number;
    illHeaderTeacherRoom : bundleImage | number;
    illHomeBeamApprivoiserMesEmotions : bundleImage | number;
    illHomeBeamCroireEnMoi : bundleImage | number;
    illHomeBeamDompterErreur : bundleImage | number;
    illHomeBeamLaJouerCollectif : bundleImage | number;
    illHomeBeamMusclerMaConcentration : bundleImage | number;
    illHomeBeamOserAOral : bundleImage | number;
    illHomeBeamReflechirSurLaVie : bundleImage | number;
    illHomeSupercat : bundleImage | number;
    illNefle : bundleImage | number;
    illNightGround : bundleImage | number;
    illParentSignUpFinal : bundleImage | number;
    illParentSignUpFinalBis : bundleImage | number;
    illPosterCollective : bundleImage | number;
    illPosterCritical : bundleImage | number;
    illPosterError : bundleImage | number;
    illPosterFocus : bundleImage | number;
    illPosterSelfEstemm : bundleImage | number;
    illPosterSpeak : bundleImage | number;
    illPremiumCard : bundleImage | number;
    illSevenSteps : bundleImage | number;
    illSheetCollective : bundleImage | number;
    illSheetCritic : bundleImage | number;
    illSheetEmotions : bundleImage | number;
    illSheetError : bundleImage | number;
    illSheetFocus : bundleImage | number;
    illSheetSelfEsteem : bundleImage | number;
    illSheetSpeak : bundleImage | number;
    illSleepingMeditations : bundleImage | number;
    illStepAdvicesByActivityType : bundleImage | number;
    illStepAdvicesByLevel : bundleImage | number;
    illStepFilterByAge : bundleImage | number;
    illStepFilterForAge46 : bundleImage | number;
    illStepFilterForAge69 : bundleImage | number;
    illStepFilterForAge912 : bundleImage | number;
    illStepNotice : bundleImage | number;
    illStepPath : bundleImage | number;
    illStepPoster : bundleImage | number;
    illStepTutorial : bundleImage | number;
    illStepWebinar : bundleImage | number;
    illStill : bundleImage | number;
    illSupercat : bundleImage | number;
    illSupercatStanding : bundleImage | number;
    illTalk : bundleImage | number;
    illTeacherCat : bundleImage | number;
    illTeacherDocs : bundleImage | number;
    illTeacherSignUpFinal : bundleImage | number;
    illTeacherSignUpFinalBis : bundleImage | number;
    illTeacherSignUpFinalTne : bundleImage | number;
    illTeacherWave : bundleImage | number;
    illWhiteWave : bundleImage | number;
    jac01Letremsdesemotions : bundleImage | number;
    jac02Yogamemory : bundleImage | number;
    jac03Lilipourlavie : bundleImage | number;
    jac04Memorydesemotions : bundleImage | number;
    jat01Leverredeau : bundleImage | number;
    jat02Leraisinsec : bundleImage | number;
    jat03Jeudememoire : bundleImage | number;
    jat04Lechocolat : bundleImage | number;
    jat05Laboissonchaude : bundleImage | number;
    jco01Alphabetvisuel : bundleImage | number;
    jco03Lesstatues : bundleImage | number;
    jec01Lechmurtsse : bundleImage | number;
    jec02Cadavreexquis : bundleImage | number;
    jec07Les3fontlapaire : bundleImage | number;
    jem01Lavalsedesemotions : bundleImage | number;
    jem02Blablamotion : bundleImage | number;
    jem03Emotionverite : bundleImage | number;
    jim01Devinequoi : bundleImage | number;
    jim02Quiquoiou : bundleImage | number;
    jim03Despetitstrous : bundleImage | number;
    jim04Attentionalachute : bundleImage | number;
    jim05MetsToiamaplace : bundleImage | number;
    jim06Ouiet : bundleImage | number;
    jim07Superjairate : bundleImage | number;
    jim08ALAttaque : bundleImage | number;
    jim09LePetitBac : bundleImage | number;
    jim11IlEtaitMilleEtUneFois : bundleImage | number;
    jmi01Mimemotion : bundleImage | number;
    jmi02Ledoublage : bundleImage | number;
    jmi04Photomaton : bundleImage | number;
    jmi06AlphabetVisuelPasserelles : bundleImage | number;
    jmo01Bimcarime : bundleImage | number;
    jmo02Doricacastra : bundleImage | number;
    keyTable : bundleImage | number;
    liliBadgeAsian : bundleImage | number;
    liliBadgeAsianLevel1 : bundleImage | number;
    liliBadgeAsianLevel2 : bundleImage | number;
    liliBadgeAsianLevel3 : bundleImage | number;
    liliBadgeAsianLevel4 : bundleImage | number;
    liliBadgeAsianLevel5 : bundleImage | number;
    liliBadgeBase : bundleImage | number;
    liliBadgeBaseLevel1 : bundleImage | number;
    liliBadgeBaseLevel2 : bundleImage | number;
    liliBadgeBaseLevel3 : bundleImage | number;
    liliBadgeBaseLevel4 : bundleImage | number;
    liliBadgeBaseLevel5 : bundleImage | number;
    liliBadgeCatwoman : bundleImage | number;
    liliBadgeCatwomanLevel1 : bundleImage | number;
    liliBadgeCatwomanLevel2 : bundleImage | number;
    liliBadgeCatwomanLevel3 : bundleImage | number;
    liliBadgeCatwomanLevel4 : bundleImage | number;
    liliBadgeCatwomanLevel5 : bundleImage | number;
    liliBadgeChevalier : bundleImage | number;
    liliBadgeChevalierLevel1 : bundleImage | number;
    liliBadgeChevalierLevel2 : bundleImage | number;
    liliBadgeChevalierLevel3 : bundleImage | number;
    liliBadgeChevalierLevel4 : bundleImage | number;
    liliBadgeChevalierLevel5 : bundleImage | number;
    liliBadgeCowboy : bundleImage | number;
    liliBadgeCowboyLevel1 : bundleImage | number;
    liliBadgeCowboyLevel2 : bundleImage | number;
    liliBadgeCowboyLevel3 : bundleImage | number;
    liliBadgeCowboyLevel4 : bundleImage | number;
    liliBadgeCowboyLevel5 : bundleImage | number;
    liliBadgeFleur : bundleImage | number;
    liliBadgeFleurLevel1 : bundleImage | number;
    liliBadgeFleurLevel2 : bundleImage | number;
    liliBadgeFleurLevel3 : bundleImage | number;
    liliBadgeFleurLevel4 : bundleImage | number;
    liliBadgeFleurLevel5 : bundleImage | number;
    liliBadgeGaulois : bundleImage | number;
    liliBadgeGauloisLevel1 : bundleImage | number;
    liliBadgeGauloisLevel2 : bundleImage | number;
    liliBadgeGauloisLevel3 : bundleImage | number;
    liliBadgeGauloisLevel4 : bundleImage | number;
    liliBadgeGauloisLevel5 : bundleImage | number;
    liliBadgeIndien : bundleImage | number;
    liliBadgeIndienLevel1 : bundleImage | number;
    liliBadgeIndienLevel2 : bundleImage | number;
    liliBadgeIndienLevel3 : bundleImage | number;
    liliBadgeIndienLevel4 : bundleImage | number;
    liliBadgeIndienLevel5 : bundleImage | number;
    liliBadgeLicorne : bundleImage | number;
    liliBadgeLicorneLevel1 : bundleImage | number;
    liliBadgeLicorneLevel2 : bundleImage | number;
    liliBadgeLicorneLevel3 : bundleImage | number;
    liliBadgeLicorneLevel4 : bundleImage | number;
    liliBadgeLicorneLevel5 : bundleImage | number;
    liliBadgePirate : bundleImage | number;
    liliBadgePirateLevel1 : bundleImage | number;
    liliBadgePirateLevel2 : bundleImage | number;
    liliBadgePirateLevel3 : bundleImage | number;
    liliBadgePirateLevel4 : bundleImage | number;
    liliBadgePirateLevel5 : bundleImage | number;
    liliBadgePlongeur : bundleImage | number;
    liliBadgePlongeurLevel1 : bundleImage | number;
    liliBadgePlongeurLevel2 : bundleImage | number;
    liliBadgePlongeurLevel3 : bundleImage | number;
    liliBadgePlongeurLevel4 : bundleImage | number;
    liliBadgePlongeurLevel5 : bundleImage | number;
    liliBadgePrincesse : bundleImage | number;
    liliBadgePrincesseLevel1 : bundleImage | number;
    liliBadgePrincesseLevel2 : bundleImage | number;
    liliBadgePrincesseLevel3 : bundleImage | number;
    liliBadgePrincesseLevel4 : bundleImage | number;
    liliBadgePrincesseLevel5 : bundleImage | number;
    liliBadgeRasta : bundleImage | number;
    liliBadgeRastaLevel1 : bundleImage | number;
    liliBadgeRastaLevel2 : bundleImage | number;
    liliBadgeRastaLevel3 : bundleImage | number;
    liliBadgeRastaLevel4 : bundleImage | number;
    liliBadgeRastaLevel5 : bundleImage | number;
    liliBadgeTeacher : bundleImage | number;
    liliBadgeTeacherLevel1 : bundleImage | number;
    liliBadgeTeacherLevel2 : bundleImage | number;
    liliBadgeTeacherLevel3 : bundleImage | number;
    liliBadgeTeacherLevel4 : bundleImage | number;
    liliBadgeTeacherLevel5 : bundleImage | number;
    liliBadgeVampire : bundleImage | number;
    liliBadgeVampireLevel1 : bundleImage | number;
    liliBadgeVampireLevel2 : bundleImage | number;
    liliBadgeVampireLevel3 : bundleImage | number;
    liliBadgeVampireLevel4 : bundleImage | number;
    liliBadgeVampireLevel5 : bundleImage | number;
    liliPoster : bundleImage | number;
    liliPosterEcole : bundleImage | number;
    lpc01LectureAVoixHaute : bundleImage | number;
    lpc02ParolesDePro : bundleImage | number;
    lpc03LiliEtLesPetitsChampions : bundleImage | number;
    mas01AutoMassagedesepaules : bundleImage | number;
    mas02AutoMassagedestempes : bundleImage | number;
    mas03AutoMassagedesmains : bundleImage | number;
    mas04AutoMassagedelamachoire : bundleImage | number;
    med01Lamontagne : bundleImage | number;
    med02Lameteointerieure : bundleImage | number;
    med03Lessons : bundleImage | number;
    med04Merci : bundleImage | number;
    med05Lesourire : bundleImage | number;
    med06Lerayondesoleil : bundleImage | number;
    med07Lelac : bundleImage | number;
    med08Lerefuge : bundleImage | number;
    med09Lexplorateur : bundleImage | number;
    med10Labienveillance : bundleImage | number;
    med11Lileinterieure : bundleImage | number;
    med12Meditationlibre : bundleImage | number;
    med13MeditationPourSendormirLesEtoiles : bundleImage | number;
    med14MeditationPourSendormirLeWagonMagique : bundleImage | number;
    med15MeditationPourSendormirLaForetDeLumiere : bundleImage | number;
    med16MeditationPourSendormirLoasis : bundleImage | number;
    med17MeditationPourSendormirLOiseau : bundleImage | number;
    med18MeditationPourSendormirLesTroisPortes : bundleImage | number;
    med19MeditationPourSendormirLAlbumPhoto : bundleImage | number;
    med20MeditationPourSEndormirAuCoinDuFeu : bundleImage | number;
    med21MeditationPourSEndormirAuPaysDesNeiges : bundleImage | number;
    med22MeditationPourSEndormirLaLicorneVolante : bundleImage | number;
    med23MeditationPourSEndormirLaLampeDesReves : bundleImage | number;
    med24MeditationPourSEndormirLesBullesDeSavon : bundleImage | number;
    med25MeditationPourSEndormirLeSacADosInvisible : bundleImage | number;
    med26MeditationPourSEndormirLaStarDeCinema : bundleImage | number;
    med27MeditationPourSEndormirLePianoEnchante : bundleImage | number;
    med28MeditationPourSEndormirLesMilleCouleurs : bundleImage | number;
    med29MeditationPourSEndormirLaPlongeeFeerique : bundleImage | number;
    med30MeditationPourSEndormirLePhareDesReves : bundleImage | number;
    med31MeditationPourSEndormirLescalierDeFleurs : bundleImage | number;
    med32MeditationPourSEndormirLaFluteAerienne : bundleImage | number;
    mon01Onesttouspareils : bundleImage | number;
    mon02Caveutdirequoinormal : bundleImage | number;
    mon03Aidercesttoujoursbien : bundleImage | number;
    mon04Questcequiestbeau : bundleImage | number;
    mon05Fautiltoujoursdirelaverite : bundleImage | number;
    mon06Mourircachangequoi : bundleImage | number;
    mon07Cestquoilebonheur : bundleImage | number;
    noMultipleKeys : bundleImage | number;
    objet013Portes : bundleImage | number;
    objet02BaguetteMagique : bundleImage | number;
    objet03BallonCoeur : bundleImage | number;
    objet04Bougie : bundleImage | number;
    objet05BouleAFacette : bundleImage | number;
    objet06BrosseADent : bundleImage | number;
    objet07Cadeau : bundleImage | number;
    objet08Cafetiere : bundleImage | number;
    objet09Cartable : bundleImage | number;
    objet10CartePostale : bundleImage | number;
    objet11Clef : bundleImage | number;
    objet12Coquelicot : bundleImage | number;
    objet13CoupeChampion : bundleImage | number;
    objet14DeA6Faces : bundleImage | number;
    objet15DrapeauCourse : bundleImage | number;
    objet16DrapeauPirate : bundleImage | number;
    objet17Echelle : bundleImage | number;
    objet18Fauteuil : bundleImage | number;
    objet19FeuDeCamp : bundleImage | number;
    objet20Fleurs : bundleImage | number;
    objet21Fusee : bundleImage | number;
    objet22GalereViking : bundleImage | number;
    objet23Gateau : bundleImage | number;
    objet24JeuDeCarte : bundleImage | number;
    objet25Journal : bundleImage | number;
    objet26LampeMagique : bundleImage | number;
    objet27LitDefait : bundleImage | number;
    objet28MaletteDocteur : bundleImage | number;
    objet29Micro : bundleImage | number;
    objet30Piano : bundleImage | number;
    objet31Plume : bundleImage | number;
    objet32PotionMagique : bundleImage | number;
    objet33Pyjama : bundleImage | number;
    objet34Regle : bundleImage | number;
    objet35Sablier : bundleImage | number;
    objet36SacADos : bundleImage | number;
    objet37Scooter : bundleImage | number;
    objet38Sucette : bundleImage | number;
    objet39Tresor : bundleImage | number;
    objet40Trousse : bundleImage | number;
    objet41Valise : bundleImage | number;
    objet42Voiture : bundleImage | number;
    objet43WagonMagique : bundleImage | number;
    onboardingPhotos : bundleImage | number;
    pac01PosterLiliEcole : bundleImage | number;
    pac02ConseilsPourBienCommencer : bundleImage | number;
    pac03ConseilsPourLaMaternelle : bundleImage | number;
    pac04ConseilsPourLElementaire : bundleImage | number;
    pac05ConseilsPourLeCollege : bundleImage | number;
    pac06PosterEmotions : bundleImage | number;
    pac11LiliSurOrdinateur : bundleImage | number;
    pac12PaletteMeteo : bundleImage | number;
    pac13Webinar : bundleImage | number;
    pac15NoticeSuperProf : bundleImage | number;
    pac18SuperPouvoirsPoster : bundleImage | number;
    personnage012PersonnagesCourent : bundleImage | number;
    personnage022PersonnagesSEnlacent : bundleImage | number;
    personnage032PersonnagesSeDisputent : bundleImage | number;
    personnage04Cameleon : bundleImage | number;
    personnage05ChatEnColere : bundleImage | number;
    personnage06ChatSouriant : bundleImage | number;
    personnage07ChatTriste : bundleImage | number;
    personnage08Chevalier : bundleImage | number;
    personnage09Coccinelle : bundleImage | number;
    personnage10Concert : bundleImage | number;
    personnage11Cowboy : bundleImage | number;
    personnage12Crabe : bundleImage | number;
    personnage13Crocodiles : bundleImage | number;
    personnage14Dindon : bundleImage | number;
    personnage15Docteur : bundleImage | number;
    personnage16Dragon : bundleImage | number;
    personnage17Elephant : bundleImage | number;
    personnage18Extraterrestre : bundleImage | number;
    personnage19KimonoChapeauAsiatique : bundleImage | number;
    personnage20LeChatEnParachute : bundleImage | number;
    personnage21Licorne : bundleImage | number;
    personnage22LiliEnVelo : bundleImage | number;
    personnage23Meduse : bundleImage | number;
    personnage24Oie : bundleImage | number;
    personnage25Pie : bundleImage | number;
    personnage26Pirate : bundleImage | number;
    personnage27Plongeur : bundleImage | number;
    personnage28PoissonRouge : bundleImage | number;
    personnage29Princesse : bundleImage | number;
    personnage30RaieTachetee : bundleImage | number;
    personnage31Requin : bundleImage | number;
    personnage32Sorciere : bundleImage | number;
    personnage33Squelette : bundleImage | number;
    personnage34Tortue : bundleImage | number;
    personnage35Vampire : bundleImage | number;
    photoKo : bundleImage | number;
    photoOk : bundleImage | number;
    pictoApprivoiserMesEmotions : bundleImage | number;
    pictoCollectives : bundleImage | number;
    pictoCriticalThinking : bundleImage | number;
    pictoCroireEnMoi : bundleImage | number;
    pictoDompterErreur : bundleImage | number;
    pictoEmotionsTaming : bundleImage | number;
    pictoErrorTaming : bundleImage | number;
    pictoExercerMonSensCritique : bundleImage | number;
    pictoFocus : bundleImage | number;
    pictoLaJouerCollectif : bundleImage | number;
    pictoOserAOral : bundleImage | number;
    pictoPoserMonAttention : bundleImage | number;
    pictoSelfEsteem : bundleImage | number;
    pictoSpeak : bundleImage | number;
    pro01Nefaispasdebetises : bundleImage | number;
    pro02Jefaiscequejeveux : bundleImage | number;
    pro03Donnelemoi : bundleImage | number;
    pro04Depechetoi : bundleImage | number;
    pro05Faisattentionacequetudis : bundleImage | number;
    pro06Arretezdevousdisputer : bundleImage | number;
    pro07Frereetsoeurcestfacile : bundleImage | number;
    reg01Grandircaveutdirequoi : bundleImage | number;
    reg02Caveutdirequoietrefort : bundleImage | number;
    reg03Caveutdirequoietreintelligent : bundleImage | number;
    reg04Mincejaifaituneerreur : bundleImage | number;
    reg05Limportantcestdeparticiper : bundleImage | number;
    resp01Respirationdelamoustache : bundleImage | number;
    resp02Leballon : bundleImage | number;
    resp03Respirationdelamontgolfiere : bundleImage | number;
    resp04Respirationdusoleil : bundleImage | number;
    rou01NoticeSuperParent : bundleImage | number;
    rou04ConseilExpert : bundleImage | number;
    sch01Lesemotions : bundleImage | number;
    sch02Laconfianceensoi : bundleImage | number;
    sch03Labienveillance : bundleImage | number;
    sch04Lamoquerie : bundleImage | number;
    sch05Apprendredeseserreurs : bundleImage | number;
    she00ParcoursEntrainement : bundleImage | number;
    she01ApprivoiserMesEmotions : bundleImage | number;
    she02MusclerMaConcentration : bundleImage | number;
    she03DompterLErreur : bundleImage | number;
    she04OserALOral : bundleImage | number;
    she05ReflechirSurLaVie : bundleImage | number;
    she06LaJouerCollectif : bundleImage | number;
    she07CroireEnSoi : bundleImage | number;
    slm01DormirLesEtoiles : bundleImage | number;
    slm02LeWagonMagique : bundleImage | number;
    slm03LaForetDeLumiere : bundleImage | number;
    slm04Loasis : bundleImage | number;
    slm05Loiseau : bundleImage | number;
    slm06LalbumPhotos : bundleImage | number;
    slm07LesTroisPortes : bundleImage | number;
    slm08AuCoinDuFeu : bundleImage | number;
    slm09AuPaysDesNeiges : bundleImage | number;
    slm10LaLicorneVolante : bundleImage | number;
    slm11LaLampeDesReves : bundleImage | number;
    slm12LesBullesDeSavon : bundleImage | number;
    slm13LeSacADosInvisible : bundleImage | number;
    slm14LaStarDeCinema : bundleImage | number;
    slm15LePianoEnchante : bundleImage | number;
    slm16LesMilleCouleurs : bundleImage | number;
    slm17LaPlongeeFeerique : bundleImage | number;
    slm18LePhareDesReves : bundleImage | number;
    slm19LescalierDeFleurs : bundleImage | number;
    slm20LaFluteAerienne : bundleImage | number;
    spc19LaPhiloDesEnfantsIntroductionGenerale : bundleImage | number;
    spc20LaPhiloDesEnfantsConcevoir : bundleImage | number;
    spc21LaPhiloDesEnfantsDerivesPointsAttention : bundleImage | number;
    spc22LaPhiloDesEnfantsAnimerAtelier : bundleImage | number;
    spc23LaPhiloDesEnfantsProcessusPensee : bundleImage | number;
    spc24LaPhiloDesEnfantsQuestionnement : bundleImage | number;
    twk01LesEmotions : bundleImage | number;
    twk02ApprendreDeSesErreurs : bundleImage | number;
    twk03LaBienveillance : bundleImage | number;
    twk04LaConfianceEnSoi : bundleImage | number;
    vir01ChansonSansSon : bundleImage | number;
    vir02TroisTortues : bundleImage | number;
    vir03DodusDindons : bundleImage | number;
    vir04SixSaucissons : bundleImage | number;
    vir05LaPieNicheHaut : bundleImage | number;
    vir06ExquisesExcuses : bundleImage | number;
    vir07ChatEchappent : bundleImage | number;
    vir08Totor : bundleImage | number;
    vir09TonThe : bundleImage | number;
    vir10SachezSoigner : bundleImage | number;
    vir11UnGenereuxDejeuner : bundleImage | number;
    vir12DarioRadieux : bundleImage | number;
    vir13UnMarinRomain : bundleImage | number;
    vir14Passerelles : bundleImage | number;
    vir15Matemamoto : bundleImage | number;
    vir16LiliAuLit : bundleImage | number;
    vqp01OserSeLancer : bundleImage | number;
    vqp01OserSeLancerAnimator : bundleImage | number;
    vqp02GererUnEnfant : bundleImage | number;
    vqp02GererUnEnfantAnimator : bundleImage | number;
    vqp03GererLaClasse : bundleImage | number;
    vqp03GererLeGroupeAnimator : bundleImage | number;
    vqp04GererLeDebat : bundleImage | number;
    vqp04GererLeDebatAnimator : bundleImage | number;
    vqp05AllerPlusLoin : bundleImage | number;
    vqp06Intro : bundleImage | number;
    vqp06IntroAnimator : bundleImage | number;
    vqp07Decouverte : bundleImage | number;
    vqp07DecouverteAnimator : bundleImage | number;
    vqp08FocusMaternelle : bundleImage | number;
    vqp08FocusMaternelleAnimator : bundleImage | number;
    vqp09FocusAdolescents : bundleImage | number;
    vqp09FocusAdolescentsAnimator : bundleImage | number;
    vqp10SensibilisationPpe : bundleImage | number;
    wlb01IntroductionAuProgrammeBienEtre : bundleImage | number;
    wlb02ProgrammeDecouverte : bundleImage | number;
    wlb03RespirerEtSeDetendre : bundleImage | number;
    wlb04SAncrerDansLePresent : bundleImage | number;
    wlb05VisualiserPourSeSentirMieux : bundleImage | number;
    wlb06CroireEnSoi : bundleImage | number;
    wlb07ReduireSonStress : bundleImage | number;
    wlb08TrouverLeSommeil : bundleImage | number;
    wlb09SeRelaxerEnMusique : bundleImage | number;
    ydd01Lachouette : bundleImage | number;
    ydd02Lecobra : bundleImage | number;
    ydd03Lechat : bundleImage | number;
    ydd04Arbre : bundleImage | number;
    ydd05Lamontgolfiere : bundleImage | number;
    ydd06Lelion : bundleImage | number;
    ydd07Arcenciel : bundleImage | number;
    ydd08Oeuf : bundleImage | number;
    ydd09Flamandrose : bundleImage | number;
    ydd10LaMaison : bundleImage | number;
    ydd11ACoeurOuvert : bundleImage | number;
    yog01Lachouette : bundleImage | number;
    yog02Lechienteteenbas : bundleImage | number;
    yog03Lechat : bundleImage | number;
    yog04Lecobra : bundleImage | number;
    yog05Larbre : bundleImage | number;
    yog06Bebe : bundleImage | number;
    yog07Lechameau : bundleImage | number;
    yog08Arcenciel : bundleImage | number;
    yog09Lachaiseimaginaire : bundleImage | number;
    yog10Lependule : bundleImage | number;
    yog11Lespetitsvolets : bundleImage | number;
    yog12Latablerenversee : bundleImage | number;
    yog13Bebeheureux : bundleImage | number;
    yog14Lespaghetti : bundleImage | number;
    yog15Loeuf : bundleImage | number;
    yog16Lechelle : bundleImage | number;
    yog17Lesoleil : bundleImage | number;
    yog18Lamarmotte : bundleImage | number;
    yog19Leyogadeschampions : bundleImage | number;
    yog20Leyogadescreatifs : bundleImage | number;
    yog21Leyogadescurieux : bundleImage | number;
    yog22LeYogaDesEmotions : bundleImage | number;
    yog23LeYogaDeLAmitie : bundleImage | number;
    yog24LeYogaDeLaNature : bundleImage | number;
    yog25LeYogaDesCourageux : bundleImage | number;
    yog26LeYogaDuCorps : bundleImage | number;

    constructor() {
        this.adv01JeMePose = require("../images/adv_01_je_me_pose.png");
        this.adv02JeMExprime = require("../images/adv_02_je_m_exprime.png");
        this.adv03JeGrandis = require("../images/adv_03_je_grandis.png");
        this.aut01Cestquoiunami = require("../images/aut_01_cestquoiunami.png");
        this.aut02Cavaonrigole = require("../images/aut_02_cavaonrigole.png");
        this.aut03Mechantougentil = require("../images/aut_03_mechantougentil.png");
        this.aut04Nesoispasjaloux = require("../images/aut_04_nesoispasjaloux.png");
        this.aut05Fillegarconcestdifferent = require("../images/aut_05_fillegarconcestdifferent.png");
        this.aut06Ondoittoujoursetredaccord = require("../images/aut_06_ondoittoujoursetredaccord.png");
        this.aut07Onpartage = require("../images/aut_07_onpartage.png");
        this.aut08Cestpasjuste = require("../images/aut_08_cestpasjuste.png");
        this.aut09Faismoiconfiance = require("../images/aut_09_faismoiconfiance.png");
        this.aut10Quicommande = require("../images/aut_10_quicommande.png");
        this.badgeAgile = require("../images/badge_agile.png");
        this.badgeAgileBw = require("../images/badge_agile_bw.png");
        this.badgeAudacieux = require("../images/badge_audacieux.png");
        this.badgeAudacieuxBw = require("../images/badge_audacieux_bw.png");
        this.badgeAventurier = require("../images/badge_aventurier.png");
        this.badgeAventurierBw = require("../images/badge_aventurier_bw.png");
        this.badgeCollectionneur = require("../images/badge_collectionneur.png");
        this.badgeCollectionneurBw = require("../images/badge_collectionneur_bw.png");
        this.badgeComedien = require("../images/badge_comedien.png");
        this.badgeComedienBw = require("../images/badge_comedien_bw.png");
        this.badgeCourageux = require("../images/badge_courageux.png");
        this.badgeCourageuxBw = require("../images/badge_courageux_bw.png");
        this.badgeCurieux = require("../images/badge_curieux.png");
        this.badgeCurieuxBw = require("../images/badge_curieux_bw.png");
        this.badgeEnqueteur = require("../images/badge_enqueteur.png");
        this.badgeEnqueteurBw = require("../images/badge_enqueteur_bw.png");
        this.badgeExplorateur = require("../images/badge_explorateur.png");
        this.badgeExplorateurBw = require("../images/badge_explorateur_bw.png");
        this.badgeExpressif = require("../images/badge_expressif.png");
        this.badgeExpressifBw = require("../images/badge_expressif_bw.png");
        this.badgeGrandLecteur = require("../images/badge_grand_lecteur.png");
        this.badgeGrandLecteurBw = require("../images/badge_grand_lecteur_bw.png");
        this.badgeGrandSage = require("../images/badge_grand_sage.png");
        this.badgeGrandSageBw = require("../images/badge_grand_sage_bw.png");
        this.badgeIntellectuel = require("../images/badge_intellectuel.png");
        this.badgeIntellectuelBw = require("../images/badge_intellectuel_bw.png");
        this.badgeJoueur = require("../images/badge_joueur.png");
        this.badgeJoueurBw = require("../images/badge_joueur_bw.png");
        this.badgeMaitreZen = require("../images/badge_maitre_zen.png");
        this.badgeMaitreZenBw = require("../images/badge_maitre_zen_bw.png");
        this.badgeMelomane = require("../images/badge_melomane.png");
        this.badgeMelomaneBw = require("../images/badge_melomane_bw.png");
        this.badgePose = require("../images/badge_pose.png");
        this.badgePoseBw = require("../images/badge_pose_bw.png");
        this.badgeRepose = require("../images/badge_repose.png");
        this.badgeReposeBw = require("../images/badge_repose_bw.png");
        this.badgeReveur = require("../images/badge_reveur.png");
        this.badgeReveurBw = require("../images/badge_reveur_bw.png");
        this.badgeRoiDeLaScene = require("../images/badge_roi_de_la_scene.png");
        this.badgeRoiDeLaSceneBw = require("../images/badge_roi_de_la_scene_bw.png");
        this.badgeSerein = require("../images/badge_serein.png");
        this.badgeSereinBw = require("../images/badge_serein_bw.png");
        this.badgeStar = require("../images/badge_star.png");
        this.badgeStarBw = require("../images/badge_star_bw.png");
        this.badgeTranquille = require("../images/badge_tranquille.png");
        this.badgeTranquilleBw = require("../images/badge_tranquille_bw.png");
        this.bgAnimatorPictos = require("../images/bg_animator_pictos.png");
        this.bgBtnLiliAtExtracurricular = require("../images/bg_btn_lili_at_extracurricular.png");
        this.bgBtnLiliAtHome = require("../images/bg_btn_lili_at_home.png");
        this.bgBtnLiliAtSchool = require("../images/bg_btn_lili_at_school.png");
        this.bgDayClouds = require("../images/bg_day_clouds.png");
        this.bgDayGrass = require("../images/bg_day_grass.png");
        this.bgNightStars = require("../images/bg_night_stars.png");
        this.bgOnboarding = require("../images/bg_onboarding.png");
        this.bgOnboardingAnimators = require("../images/bg_onboarding_animators.png");
        this.bgOnboardingParent = require("../images/bg_onboarding_parent.png");
        this.bgOnboardingTeacher = require("../images/bg_onboarding_teacher.png");
        this.bgParentsPictos = require("../images/bg_parents_pictos.png");
        this.bgTeacherformLeft = require("../images/bg_teacherForm_left.jpg");
        this.bgTeacherformRight = require("../images/bg_teacherForm_right.jpg");
        this.bgTeacherPictos = require("../images/bg_teacher_pictos.png");
        this.bio01HelenKeller = require("../images/bio_01_helen_keller.png");
        this.bio02CharlieChaplin = require("../images/bio_02_charlie_chaplin.png");
        this.bio03GretaThunberg = require("../images/bio_03_greta_thunberg.png");
        this.bio04ValentinHauy = require("../images/bio_04_valentin_hauy.png");
        this.bio05KeithHaring = require("../images/bio_05_keith_haring.png");
        this.bio06ThomasPesquet = require("../images/bio_06_thomas_pesquet.png");
        this.bio07PratimaSherpa = require("../images/bio_07_pratima_sherpa.png");
        this.bioa02MerceCunningham = require("../images/bioa_02_merce_cunningham.png");
        this.biobe01VictorHugo = require("../images/biobe_01_victor_hugo.png");
        this.biobe02CarolineCriadoPerez = require("../images/biobe_02_caroline_criado_perez.png");
        this.biomm01JosephineBaker = require("../images/biomm_01_josephine_baker.png");
        this.biomm02EmmaWatson = require("../images/biomm_02_emma_watson.png");
        this.bions01TempleGrandin = require("../images/bions_01_temple_grandin.png");
        this.bions02CarlSagan = require("../images/bions_02_carl_sagan.png");
        this.biop01NelsonMandela = require("../images/biop_01_nelson_mandela.png");
        this.biop02OlympeDeGouges = require("../images/biop_02_olympe_de_gouges.png");
        this.biosa01TheoCurin = require("../images/biosa_01_theo_curin.png");
        this.biosa02SerenaWilliams = require("../images/biosa_02_serena_williams.png");
        this.biosi01AdaLovelace = require("../images/biosi_01_ada_lovelace.png");
        this.biosi02ShigeruMiyamoto = require("../images/biosi_02_shigeru_miyamoto.png");
        this.bul01Lunivers = require("../images/bul_01_lunivers.png");
        this.bul02Laplage = require("../images/bul_02_laplage.png");
        this.bul03Laferme = require("../images/bul_03_laferme.png");
        this.bul04Lajungle = require("../images/bul_04_lajungle.png");
        this.bul05Locean = require("../images/bul_05_locean.png");
        this.catPhiloFormation = require("../images/cat_philo_formation.png");
        this.catPhiloFormationAnimator = require("../images/cat_philo_formation_animator.png");
        this.catPhiloFormationAnimatorRoom = require("../images/cat_philo_formation_animator_room.png");
        this.catPhiloFormationTeacherRoom = require("../images/cat_philo_formation_teacher_room.png");
        this.catWellBeing = require("../images/cat_well_being.png");
        this.contexte01ArcEnCielApparait = require("../images/contexte_01_arc_en_ciel_apparait.png");
        this.contexte02Brouhaha = require("../images/contexte_02_brouhaha.png");
        this.contexte03CoeurBrise = require("../images/contexte_03_coeur_brise.png");
        this.contexte04Coeur = require("../images/contexte_04_coeur.png");
        this.contexte05EtoileFilante = require("../images/contexte_05_etoile_filante.png");
        this.contexte06IlSeMetANeiger = require("../images/contexte_06_il_se_met_a_neiger.png");
        this.contexte07IlSeMetPleuvoir = require("../images/contexte_07_il_se_met_pleuvoir.png");
        this.contexte08OnEntendDeLaMusique = require("../images/contexte_08_on_entend_de_la_musique.png");
        this.contexte09Orage = require("../images/contexte_09_orage.png");
        this.contexte10PleineLune = require("../images/contexte_10_pleine_lune.png");
        this.contexte11ReveilSonne = require("../images/contexte_11_reveil_sonne.png");
        this.contexte12SoleilSeCouche = require("../images/contexte_12_soleil_se_couche.png");
        this.contexte13SoleilSeLeve = require("../images/contexte_13_soleil_se_leve.png");
        this.contexte14TvAllumee = require("../images/contexte_14_tv_allumee.png");
        this.decor01Cascade = require("../images/decor_01_cascade.png");
        this.decor02ChateauFort = require("../images/decor_02_chateau_fort.png");
        this.decor03Etoiles = require("../images/decor_03_etoiles.png");
        this.decor04Ferme = require("../images/decor_04_ferme.png");
        this.decor05FeteForaine = require("../images/decor_05_fete_foraine.png");
        this.decor06ForetDeLumiere = require("../images/decor_06_foret_de_lumiere.png");
        this.decor07IleDeserte = require("../images/decor_07_ile_deserte.png");
        this.decor08IleInterieure = require("../images/decor_08_ile_interieure.png");
        this.decor09Jungle = require("../images/decor_09_jungle.png");
        this.decor10LicornePrendSonBain = require("../images/decor_10_licorne_prend_son_bain.png");
        this.decor11MerDeNuages = require("../images/decor_11_mer_de_nuages.png");
        this.decor12Montagne = require("../images/decor_12_montagne.png");
        this.decor13MurailleDeChine = require("../images/decor_13_muraille_de_chine.png");
        this.decor14Phare = require("../images/decor_14_phare.png");
        this.decor15Plage = require("../images/decor_15_plage.png");
        this.decor16Pyramides = require("../images/decor_16_pyramides.png");
        this.decor17Savane = require("../images/decor_17_savane.png");
        this.ded01JeMeSouviens = require("../images/ded_01_je_me_souviens.png");
        this.ded02RecetteMartienne = require("../images/ded_02_recette_martienne.png");
        this.ded03ALaventure = require("../images/ded_03_a_laventure.png");
        this.ded04Victoire = require("../images/ded_04_victoire.png");
        this.ded05LeGateauSurprise = require("../images/ded_05_le_gateau_surprise.png");
        this.ded06MemePasPeur = require("../images/ded_06_meme_pas_peur.png");
        this.ded07EtSiOnChantait = require("../images/ded_07_et_si_on_chantait.png");
        this.ded08Chut = require("../images/ded_08_chut.png");
        this.dscv00ActivitiesDiscovery = require("../images/dscv_00_activities_discovery.png");
        this.emo01Jemesensbien = require("../images/emo_01_jemesensbien.png");
        this.emo02Jesuisamoureux = require("../images/emo_02_jesuisamoureux.png");
        this.emo03Netinquietepas = require("../images/emo_03_netinquietepas.png");
        this.emo04Netenervepascommeca = require("../images/emo_04_netenervepascommeca.png");
        this.emo05Jaipeur = require("../images/emo_05_jaipeur.png");
        this.emo06Nesoispastimide = require("../images/emo_06_nesoispastimide.png");
        this.emo07Jesuistristecestgrave = require("../images/emo_07_jesuistristecestgrave.png");
        this.headerSectionGrow = require("../images/header_section_grow.png");
        this.headerSectionInspire = require("../images/header_section_inspire.png");
        this.headerSectionStill = require("../images/header_section_still.png");
        this.headerSectionTalk = require("../images/header_section_talk.png");
        this.icAbout = require("../images/ic_about.png");
        this.icArrow = require("../images/ic_arrow.png");
        this.icCanope = require("../images/ic_canope.png");
        this.icCatPhiloFormation = require("../images/ic_cat_philo_formation.png");
        this.icCloud = require("../images/ic_cloud.png");
        this.icDay = require("../images/ic_day.png");
        this.icEquipement = require("../images/ic_equipement.png");
        this.icFacebook = require("../images/ic_facebook.png");
        this.icHeaderAnimatorsWindow = require("../images/ic_header_animators_window.png");
        this.icHeaderParentsWindow = require("../images/ic_header_parents_window.png");
        this.icHeaderTeacherBoard = require("../images/ic_header_teacher_board.png");
        this.icHomeApprivoiserMesEmotions = require("../images/ic_home_apprivoiser_mes_emotions.png");
        this.icHomeCroireEnMoi = require("../images/ic_home_croire_en_moi.png");
        this.icHomeDompterErreur = require("../images/ic_home_dompter_erreur.png");
        this.icHomeLaJouerCollectif = require("../images/ic_home_la_jouer_collectif.png");
        this.icHomeMusclerMaConcentration = require("../images/ic_home_muscler_ma_concentration.png");
        this.icHomeOserAOral = require("../images/ic_home_oser_a_oral.png");
        this.icHomeReflechirSurLaVie = require("../images/ic_home_reflechir_sur_la_vie.png");
        this.icInspiration = require("../images/ic_inspiration.png");
        this.icLiliAtExtracurricularCircle = require("../images/ic_lili_at_extracurricular_circle.png");
        this.icLiliAtHomeCircle = require("../images/ic_lili_at_home_circle.png");
        this.icLiliAtSchoolCircle = require("../images/ic_lili_at_school_circle.png");
        this.icLiliCircle = require("../images/ic_lili_circle.png");
        this.icLiliLesPetitsChampionsLogo = require("../images/ic_lili_les_petits_champions_logo.png");
        this.icMeditationsMusic = require("../images/ic_meditations_music.png");
        this.icMeditationsSleep = require("../images/ic_meditations_sleep.png");
        this.icMinistere = require("../images/ic_ministere.png");
        this.icMinistereWhite = require("../images/ic_ministere_white.png");
        this.icMoodAngry = require("../images/ic_mood_angry.png");
        this.icMoodAnnoyed = require("../images/ic_mood_annoyed.png");
        this.icMoodCalme = require("../images/ic_mood_calme.png");
        this.icMoodHappy = require("../images/ic_mood_happy.png");
        this.icMoodSad = require("../images/ic_mood_sad.png");
        this.icMoodStressed = require("../images/ic_mood_stressed.png");
        this.icNight = require("../images/ic_night.png");
        this.icOwlFlysuit = require("../images/ic_owl_flysuit.png");
        this.icOwlMaster = require("../images/ic_owl_master.png");
        this.icOwlYoda = require("../images/ic_owl_yoda.png");
        this.icParentMacaroon = require("../images/ic_parent_macaroon.png");
        this.icParentsPremium = require("../images/ic_parents_premium.png");
        this.icPath = require("../images/ic_path.png");
        this.icPeriscolaire = require("../images/ic_periscolaire.png");
        this.icPlanet = require("../images/ic_planet.png");
        this.icPremium = require("../images/ic_premium.png");
        this.icSuperParentsRoom = require("../images/ic_super_parents_room.png");
        this.icSuperTeachersRoom = require("../images/ic_super_teachers_room.png");
        this.icSuperpowerPoster = require("../images/ic_superpower_poster.png");
        this.icTalk = require("../images/ic_talk.png");
        this.icTalkEyes = require("../images/ic_talk_eyes.png");
        this.icTeacherMacaroon = require("../images/ic_teacher_macaroon.png");
        this.icTeacherProfs = require("../images/ic_teacher_profs.png");
        this.icTeacherSuperProgrammes = require("../images/ic_teacher_super_programmes.png");
        this.icTeacherTogether = require("../images/ic_teacher_together.png");
        this.icTeachersWorkshops = require("../images/ic_teachers_workshops.png");
        this.icTneMap = require("../images/ic_tne_map.png");
        this.icTraining = require("../images/ic_training.png");
        this.icWarmUp = require("../images/ic_warm_up.png");
        this.icWellBeing = require("../images/ic_well_being.png");
        this.illversiontwocoming = require("../images/illVersionTwoComing.png");
        this.illAnimatorSignUpFinal = require("../images/ill_animator_sign_up_final.png");
        this.illAnimatorSignUpFinalBis = require("../images/ill_animator_sign_up_final_bis.png");
        this.illDayGround = require("../images/ill_day_ground.png");
        this.illFamilyWave = require("../images/ill_family_wave.png");
        this.illGrow = require("../images/ill_grow.png");
        this.illHeaderAnimatorsRoom = require("../images/ill_header_animators_room.png");
        this.illHeaderApprivoiserMesEmotions = require("../images/ill_header_apprivoiser_mes_emotions.png");
        this.illHeaderCroireEnMoi = require("../images/ill_header_croire_en_moi.png");
        this.illHeaderDompterErreur = require("../images/ill_header_dompter_erreur.png");
        this.illHeaderLaJouerCollectif = require("../images/ill_header_la_jouer_collectif.png");
        this.illHeaderMeditationsPourSEndormir = require("../images/ill_header_meditations_pour_s_endormir.png");
        this.illHeaderMusclerSaConcentration = require("../images/ill_header_muscler_sa_concentration.png");
        this.illHeaderMusiquesPourSEndormir = require("../images/ill_header_musiques_pour_s_endormir.png");
        this.illHeaderOserALOral = require("../images/ill_header_oser_a_l_oral.png");
        this.illHeaderParentsRoom = require("../images/ill_header_parents_room.png");
        this.illHeaderReflechirSurLaVie = require("../images/ill_header_reflechir_sur_la_vie.png");
        this.illHeaderTeacherRoom = require("../images/ill_header_teacher_room.png");
        this.illHomeBeamApprivoiserMesEmotions = require("../images/ill_home_beam_apprivoiser_mes_emotions.png");
        this.illHomeBeamCroireEnMoi = require("../images/ill_home_beam_croire_en_moi.png");
        this.illHomeBeamDompterErreur = require("../images/ill_home_beam_dompter_erreur.png");
        this.illHomeBeamLaJouerCollectif = require("../images/ill_home_beam_la_jouer_collectif.png");
        this.illHomeBeamMusclerMaConcentration = require("../images/ill_home_beam_muscler_ma_concentration.png");
        this.illHomeBeamOserAOral = require("../images/ill_home_beam_oser_a_oral.png");
        this.illHomeBeamReflechirSurLaVie = require("../images/ill_home_beam_reflechir_sur_la_vie.png");
        this.illHomeSupercat = require("../images/ill_home_supercat.png");
        this.illNefle = require("../images/ill_nefle.png");
        this.illNightGround = require("../images/ill_night_ground.png");
        this.illParentSignUpFinal = require("../images/ill_parent_sign_up_final.png");
        this.illParentSignUpFinalBis = require("../images/ill_parent_sign_up_final_bis.png");
        this.illPosterCollective = require("../images/ill_poster_collective.png");
        this.illPosterCritical = require("../images/ill_poster_critical.png");
        this.illPosterError = require("../images/ill_poster_error.png");
        this.illPosterFocus = require("../images/ill_poster_focus.png");
        this.illPosterSelfEstemm = require("../images/ill_poster_self_estemm.png");
        this.illPosterSpeak = require("../images/ill_poster_speak.png");
        this.illPremiumCard = require("../images/ill_premium_card.png");
        this.illSevenSteps = require("../images/ill_seven_steps.png");
        this.illSheetCollective = require("../images/ill_sheet_collective.png");
        this.illSheetCritic = require("../images/ill_sheet_critic.png");
        this.illSheetEmotions = require("../images/ill_sheet_emotions.png");
        this.illSheetError = require("../images/ill_sheet_error.png");
        this.illSheetFocus = require("../images/ill_sheet_focus.png");
        this.illSheetSelfEsteem = require("../images/ill_sheet_self_esteem.png");
        this.illSheetSpeak = require("../images/ill_sheet_speak.png");
        this.illSleepingMeditations = require("../images/ill_sleeping_meditations.png");
        this.illStepAdvicesByActivityType = require("../images/ill_step_advices_by_activity_type.png");
        this.illStepAdvicesByLevel = require("../images/ill_step_advices_by_level.png");
        this.illStepFilterByAge = require("../images/ill_step_filter_by_age.png");
        this.illStepFilterForAge46 = require("../images/ill_step_filter_for_age_4_6.png");
        this.illStepFilterForAge69 = require("../images/ill_step_filter_for_age_6_9.png");
        this.illStepFilterForAge912 = require("../images/ill_step_filter_for_age_9_12.png");
        this.illStepNotice = require("../images/ill_step_notice.png");
        this.illStepPath = require("../images/ill_step_path.png");
        this.illStepPoster = require("../images/ill_step_poster.png");
        this.illStepTutorial = require("../images/ill_step_tutorial.png");
        this.illStepWebinar = require("../images/ill_step_webinar.png");
        this.illStill = require("../images/ill_still.png");
        this.illSupercat = require("../images/ill_supercat.png");
        this.illSupercatStanding = require("../images/ill_supercat_standing.png");
        this.illTalk = require("../images/ill_talk.png");
        this.illTeacherCat = require("../images/ill_teacher_cat.png");
        this.illTeacherDocs = require("../images/ill_teacher_docs.png");
        this.illTeacherSignUpFinal = require("../images/ill_teacher_sign_up_final.png");
        this.illTeacherSignUpFinalBis = require("../images/ill_teacher_sign_up_final_bis.png");
        this.illTeacherSignUpFinalTne = require("../images/ill_teacher_sign_up_final_tne.png");
        this.illTeacherWave = require("../images/ill_teacher_wave.png");
        this.illWhiteWave = require("../images/ill_white_wave.png");
        this.jac01Letremsdesemotions = require("../images/jac_01_letremsdesemotions.png");
        this.jac02Yogamemory = require("../images/jac_02_yogamemory.png");
        this.jac03Lilipourlavie = require("../images/jac_03_lilipourlavie.png");
        this.jac04Memorydesemotions = require("../images/jac_04_memorydesemotions.png");
        this.jat01Leverredeau = require("../images/jat_01_leverredeau.png");
        this.jat02Leraisinsec = require("../images/jat_02_leraisinsec.png");
        this.jat03Jeudememoire = require("../images/jat_03_jeudememoire.png");
        this.jat04Lechocolat = require("../images/jat_04_lechocolat.png");
        this.jat05Laboissonchaude = require("../images/jat_05_laboissonchaude.png");
        this.jco01Alphabetvisuel = require("../images/jco_01_alphabetvisuel.png");
        this.jco03Lesstatues = require("../images/jco_03_lesstatues.png");
        this.jec01Lechmurtsse = require("../images/jec_01_lechmurtsse.png");
        this.jec02Cadavreexquis = require("../images/jec_02_cadavreexquis.png");
        this.jec07Les3fontlapaire = require("../images/jec_07_les3fontlapaire.png");
        this.jem01Lavalsedesemotions = require("../images/jem_01_lavalsedesemotions.png");
        this.jem02Blablamotion = require("../images/jem_02_blablamotion.png");
        this.jem03Emotionverite = require("../images/jem_03_emotionverite.png");
        this.jim01Devinequoi = require("../images/jim_01_devinequoi.png");
        this.jim02Quiquoiou = require("../images/jim_02_quiquoiou.png");
        this.jim03Despetitstrous = require("../images/jim_03_despetitstrous.png");
        this.jim04Attentionalachute = require("../images/jim_04_attentionalachute_.png");
        this.jim05MetsToiamaplace = require("../images/jim_05_mets_toiamaplace_.png");
        this.jim06Ouiet = require("../images/jim_06_ouiet.png");
        this.jim07Superjairate = require("../images/jim_07_superjairate.png");
        this.jim08ALAttaque = require("../images/jim_08_a_l_attaque.png");
        this.jim09LePetitBac = require("../images/jim_09_le_petit_bac.png");
        this.jim11IlEtaitMilleEtUneFois = require("../images/jim_11_il_etait_mille_et_une_fois.png");
        this.jmi01Mimemotion = require("../images/jmi_01_mimemotion.png");
        this.jmi02Ledoublage = require("../images/jmi_02_ledoublage.png");
        this.jmi04Photomaton = require("../images/jmi_04_photomaton.png");
        this.jmi06AlphabetVisuelPasserelles = require("../images/jmi_06_alphabet_visuel_passerelles.png");
        this.jmo01Bimcarime = require("../images/jmo_01_bimcarime.png");
        this.jmo02Doricacastra = require("../images/jmo_02_doricacastra.png");
        this.keyTable = require("../images/key_table.png");
        this.liliBadgeAsian = require("../images/lili_badge_asian.png");
        this.liliBadgeAsianLevel1 = require("../images/lili_badge_asian_level_1.png");
        this.liliBadgeAsianLevel2 = require("../images/lili_badge_asian_level_2.png");
        this.liliBadgeAsianLevel3 = require("../images/lili_badge_asian_level_3.png");
        this.liliBadgeAsianLevel4 = require("../images/lili_badge_asian_level_4.png");
        this.liliBadgeAsianLevel5 = require("../images/lili_badge_asian_level_5.png");
        this.liliBadgeBase = require("../images/lili_badge_base.png");
        this.liliBadgeBaseLevel1 = require("../images/lili_badge_base_level_1.png");
        this.liliBadgeBaseLevel2 = require("../images/lili_badge_base_level_2.png");
        this.liliBadgeBaseLevel3 = require("../images/lili_badge_base_level_3.png");
        this.liliBadgeBaseLevel4 = require("../images/lili_badge_base_level_4.png");
        this.liliBadgeBaseLevel5 = require("../images/lili_badge_base_level_5.png");
        this.liliBadgeCatwoman = require("../images/lili_badge_catwoman.png");
        this.liliBadgeCatwomanLevel1 = require("../images/lili_badge_catwoman_level_1.png");
        this.liliBadgeCatwomanLevel2 = require("../images/lili_badge_catwoman_level_2.png");
        this.liliBadgeCatwomanLevel3 = require("../images/lili_badge_catwoman_level_3.png");
        this.liliBadgeCatwomanLevel4 = require("../images/lili_badge_catwoman_level_4.png");
        this.liliBadgeCatwomanLevel5 = require("../images/lili_badge_catwoman_level_5.png");
        this.liliBadgeChevalier = require("../images/lili_badge_chevalier.png");
        this.liliBadgeChevalierLevel1 = require("../images/lili_badge_chevalier_level_1.png");
        this.liliBadgeChevalierLevel2 = require("../images/lili_badge_chevalier_level_2.png");
        this.liliBadgeChevalierLevel3 = require("../images/lili_badge_chevalier_level_3.png");
        this.liliBadgeChevalierLevel4 = require("../images/lili_badge_chevalier_level_4.png");
        this.liliBadgeChevalierLevel5 = require("../images/lili_badge_chevalier_level_5.png");
        this.liliBadgeCowboy = require("../images/lili_badge_cowboy.png");
        this.liliBadgeCowboyLevel1 = require("../images/lili_badge_cowboy_level_1.png");
        this.liliBadgeCowboyLevel2 = require("../images/lili_badge_cowboy_level_2.png");
        this.liliBadgeCowboyLevel3 = require("../images/lili_badge_cowboy_level_3.png");
        this.liliBadgeCowboyLevel4 = require("../images/lili_badge_cowboy_level_4.png");
        this.liliBadgeCowboyLevel5 = require("../images/lili_badge_cowboy_level_5.png");
        this.liliBadgeFleur = require("../images/lili_badge_fleur.png");
        this.liliBadgeFleurLevel1 = require("../images/lili_badge_fleur_level_1.png");
        this.liliBadgeFleurLevel2 = require("../images/lili_badge_fleur_level_2.png");
        this.liliBadgeFleurLevel3 = require("../images/lili_badge_fleur_level_3.png");
        this.liliBadgeFleurLevel4 = require("../images/lili_badge_fleur_level_4.png");
        this.liliBadgeFleurLevel5 = require("../images/lili_badge_fleur_level_5.png");
        this.liliBadgeGaulois = require("../images/lili_badge_gaulois.png");
        this.liliBadgeGauloisLevel1 = require("../images/lili_badge_gaulois_level_1.png");
        this.liliBadgeGauloisLevel2 = require("../images/lili_badge_gaulois_level_2.png");
        this.liliBadgeGauloisLevel3 = require("../images/lili_badge_gaulois_level_3.png");
        this.liliBadgeGauloisLevel4 = require("../images/lili_badge_gaulois_level_4.png");
        this.liliBadgeGauloisLevel5 = require("../images/lili_badge_gaulois_level_5.png");
        this.liliBadgeIndien = require("../images/lili_badge_indien.png");
        this.liliBadgeIndienLevel1 = require("../images/lili_badge_indien_level_1.png");
        this.liliBadgeIndienLevel2 = require("../images/lili_badge_indien_level_2.png");
        this.liliBadgeIndienLevel3 = require("../images/lili_badge_indien_level_3.png");
        this.liliBadgeIndienLevel4 = require("../images/lili_badge_indien_level_4.png");
        this.liliBadgeIndienLevel5 = require("../images/lili_badge_indien_level_5.png");
        this.liliBadgeLicorne = require("../images/lili_badge_licorne.png");
        this.liliBadgeLicorneLevel1 = require("../images/lili_badge_licorne_level_1.png");
        this.liliBadgeLicorneLevel2 = require("../images/lili_badge_licorne_level_2.png");
        this.liliBadgeLicorneLevel3 = require("../images/lili_badge_licorne_level_3.png");
        this.liliBadgeLicorneLevel4 = require("../images/lili_badge_licorne_level_4.png");
        this.liliBadgeLicorneLevel5 = require("../images/lili_badge_licorne_level_5.png");
        this.liliBadgePirate = require("../images/lili_badge_pirate.png");
        this.liliBadgePirateLevel1 = require("../images/lili_badge_pirate_level_1.png");
        this.liliBadgePirateLevel2 = require("../images/lili_badge_pirate_level_2.png");
        this.liliBadgePirateLevel3 = require("../images/lili_badge_pirate_level_3.png");
        this.liliBadgePirateLevel4 = require("../images/lili_badge_pirate_level_4.png");
        this.liliBadgePirateLevel5 = require("../images/lili_badge_pirate_level_5.png");
        this.liliBadgePlongeur = require("../images/lili_badge_plongeur.png");
        this.liliBadgePlongeurLevel1 = require("../images/lili_badge_plongeur_level_1.png");
        this.liliBadgePlongeurLevel2 = require("../images/lili_badge_plongeur_level_2.png");
        this.liliBadgePlongeurLevel3 = require("../images/lili_badge_plongeur_level_3.png");
        this.liliBadgePlongeurLevel4 = require("../images/lili_badge_plongeur_level_4.png");
        this.liliBadgePlongeurLevel5 = require("../images/lili_badge_plongeur_level_5.png");
        this.liliBadgePrincesse = require("../images/lili_badge_princesse.png");
        this.liliBadgePrincesseLevel1 = require("../images/lili_badge_princesse_level_1.png");
        this.liliBadgePrincesseLevel2 = require("../images/lili_badge_princesse_level_2.png");
        this.liliBadgePrincesseLevel3 = require("../images/lili_badge_princesse_level_3.png");
        this.liliBadgePrincesseLevel4 = require("../images/lili_badge_princesse_level_4.png");
        this.liliBadgePrincesseLevel5 = require("../images/lili_badge_princesse_level_5.png");
        this.liliBadgeRasta = require("../images/lili_badge_rasta.png");
        this.liliBadgeRastaLevel1 = require("../images/lili_badge_rasta_level_1.png");
        this.liliBadgeRastaLevel2 = require("../images/lili_badge_rasta_level_2.png");
        this.liliBadgeRastaLevel3 = require("../images/lili_badge_rasta_level_3.png");
        this.liliBadgeRastaLevel4 = require("../images/lili_badge_rasta_level_4.png");
        this.liliBadgeRastaLevel5 = require("../images/lili_badge_rasta_level_5.png");
        this.liliBadgeTeacher = require("../images/lili_badge_teacher.png");
        this.liliBadgeTeacherLevel1 = require("../images/lili_badge_teacher_level_1.png");
        this.liliBadgeTeacherLevel2 = require("../images/lili_badge_teacher_level_2.png");
        this.liliBadgeTeacherLevel3 = require("../images/lili_badge_teacher_level_3.png");
        this.liliBadgeTeacherLevel4 = require("../images/lili_badge_teacher_level_4.png");
        this.liliBadgeTeacherLevel5 = require("../images/lili_badge_teacher_level_5.png");
        this.liliBadgeVampire = require("../images/lili_badge_vampire.png");
        this.liliBadgeVampireLevel1 = require("../images/lili_badge_vampire_level_1.png");
        this.liliBadgeVampireLevel2 = require("../images/lili_badge_vampire_level_2.png");
        this.liliBadgeVampireLevel3 = require("../images/lili_badge_vampire_level_3.png");
        this.liliBadgeVampireLevel4 = require("../images/lili_badge_vampire_level_4.png");
        this.liliBadgeVampireLevel5 = require("../images/lili_badge_vampire_level_5.png");
        this.liliPoster = require("../images/lili_poster.png");
        this.liliPosterEcole = require("../images/lili_poster_ecole.png");
        this.lpc01LectureAVoixHaute = require("../images/lpc_01_lecture_a_voix_haute.png");
        this.lpc02ParolesDePro = require("../images/lpc_02_paroles_de_pro.png");
        this.lpc03LiliEtLesPetitsChampions = require("../images/lpc_03_lili_et_les_petits_champions.png");
        this.mas01AutoMassagedesepaules = require("../images/mas_01_auto_massagedesepaules.png");
        this.mas02AutoMassagedestempes = require("../images/mas_02_auto_massagedestempes.png");
        this.mas03AutoMassagedesmains = require("../images/mas_03_auto_massagedesmains.png");
        this.mas04AutoMassagedelamachoire = require("../images/mas_04_auto_massagedelamachoire.png");
        this.med01Lamontagne = require("../images/med_01_lamontagne.png");
        this.med02Lameteointerieure = require("../images/med_02_lameteointerieure.png");
        this.med03Lessons = require("../images/med_03_lessons.png");
        this.med04Merci = require("../images/med_04_merci.png");
        this.med05Lesourire = require("../images/med_05_lesourire.png");
        this.med06Lerayondesoleil = require("../images/med_06_lerayondesoleil.png");
        this.med07Lelac = require("../images/med_07_lelac.png");
        this.med08Lerefuge = require("../images/med_08_lerefuge.png");
        this.med09Lexplorateur = require("../images/med_09_lexplorateur.png");
        this.med10Labienveillance = require("../images/med_10_labienveillance.png");
        this.med11Lileinterieure = require("../images/med_11_lileinterieure.png");
        this.med12Meditationlibre = require("../images/med_12_meditationlibre.png");
        this.med13MeditationPourSendormirLesEtoiles = require("../images/med_13_meditation_pour_sendormir_les_etoiles.png");
        this.med14MeditationPourSendormirLeWagonMagique = require("../images/med_14_meditation_pour_sendormir_le_wagon_magique.png");
        this.med15MeditationPourSendormirLaForetDeLumiere = require("../images/med_15_meditation_pour_sendormir_la_foret_de_lumiere.png");
        this.med16MeditationPourSendormirLoasis = require("../images/med_16_meditation_pour_sendormir_loasis.png");
        this.med17MeditationPourSendormirLOiseau = require("../images/med_17_meditation_pour_sendormir_l_oiseau.png");
        this.med18MeditationPourSendormirLesTroisPortes = require("../images/med_18_meditation_pour_sendormir_les_trois_portes.png");
        this.med19MeditationPourSendormirLAlbumPhoto = require("../images/med_19_meditation_pour_sendormir_l_album_photo.png");
        this.med20MeditationPourSEndormirAuCoinDuFeu = require("../images/med_20_meditation_pour_s_endormir_au_coin_du_feu.png");
        this.med21MeditationPourSEndormirAuPaysDesNeiges = require("../images/med_21_meditation_pour_s_endormir_au_pays_des_neiges.png");
        this.med22MeditationPourSEndormirLaLicorneVolante = require("../images/med_22_meditation_pour_s_endormir_la_licorne_volante.png");
        this.med23MeditationPourSEndormirLaLampeDesReves = require("../images/med_23_meditation_pour_s_endormir__la_lampe_des_reves.png");
        this.med24MeditationPourSEndormirLesBullesDeSavon = require("../images/med_24_meditation_pour_s_endormir_les_bulles_de_savon.png");
        this.med25MeditationPourSEndormirLeSacADosInvisible = require("../images/med_25_meditation_pour_s_endormir_le_sac_a_dos_invisible.png");
        this.med26MeditationPourSEndormirLaStarDeCinema = require("../images/med_26_meditation_pour_s_endormir_la_star_de_cinema.png");
        this.med27MeditationPourSEndormirLePianoEnchante = require("../images/med_27_meditation_pour_s_endormir_le_piano_enchante.png");
        this.med28MeditationPourSEndormirLesMilleCouleurs = require("../images/med_28_meditation_pour_s_endormir_les_mille_couleurs.png");
        this.med29MeditationPourSEndormirLaPlongeeFeerique = require("../images/med_29_meditation_pour_s_endormir_la_plongee_feerique.png");
        this.med30MeditationPourSEndormirLePhareDesReves = require("../images/med_30_meditation_pour_s_endormir_le_phare_des_reves.png");
        this.med31MeditationPourSEndormirLescalierDeFleurs = require("../images/med_31_meditation_pour_s_endormir_lescalier_de_fleurs.png");
        this.med32MeditationPourSEndormirLaFluteAerienne = require("../images/med_32_meditation_pour_s_endormir_la_flute_aerienne.png");
        this.mon01Onesttouspareils = require("../images/mon_01_onesttouspareils.png");
        this.mon02Caveutdirequoinormal = require("../images/mon_02_caveutdirequoinormal.png");
        this.mon03Aidercesttoujoursbien = require("../images/mon_03_aidercesttoujoursbien.png");
        this.mon04Questcequiestbeau = require("../images/mon_04_questcequiestbeau.png");
        this.mon05Fautiltoujoursdirelaverite = require("../images/mon_05_fautiltoujoursdirelaverite.png");
        this.mon06Mourircachangequoi = require("../images/mon_06_mourircachangequoi.png");
        this.mon07Cestquoilebonheur = require("../images/mon_07_cestquoilebonheur.png");
        this.noMultipleKeys = require("../images/no_multiple_keys.png");
        this.objet013Portes = require("../images/objet_01_3_portes.png");
        this.objet02BaguetteMagique = require("../images/objet_02_baguette_magique.png");
        this.objet03BallonCoeur = require("../images/objet_03_ballon_coeur.png");
        this.objet04Bougie = require("../images/objet_04_bougie.png");
        this.objet05BouleAFacette = require("../images/objet_05_boule_a_facette.png");
        this.objet06BrosseADent = require("../images/objet_06_brosse_a_dent.png");
        this.objet07Cadeau = require("../images/objet_07_cadeau.png");
        this.objet08Cafetiere = require("../images/objet_08_cafetiere.png");
        this.objet09Cartable = require("../images/objet_09_cartable.png");
        this.objet10CartePostale = require("../images/objet_10_carte_postale.png");
        this.objet11Clef = require("../images/objet_11_clef.png");
        this.objet12Coquelicot = require("../images/objet_12_coquelicot.png");
        this.objet13CoupeChampion = require("../images/objet_13_coupe_champion.png");
        this.objet14DeA6Faces = require("../images/objet_14_de_a_6_faces.png");
        this.objet15DrapeauCourse = require("../images/objet_15_drapeau_course.png");
        this.objet16DrapeauPirate = require("../images/objet_16_drapeau_pirate.png");
        this.objet17Echelle = require("../images/objet_17_echelle.png");
        this.objet18Fauteuil = require("../images/objet_18_fauteuil.png");
        this.objet19FeuDeCamp = require("../images/objet_19_feu_de_camp.png");
        this.objet20Fleurs = require("../images/objet_20_fleurs.png");
        this.objet21Fusee = require("../images/objet_21_fusee.png");
        this.objet22GalereViking = require("../images/objet_22_galere_viking.png");
        this.objet23Gateau = require("../images/objet_23_gateau.png");
        this.objet24JeuDeCarte = require("../images/objet_24_jeu_de_carte.png");
        this.objet25Journal = require("../images/objet_25_journal.png");
        this.objet26LampeMagique = require("../images/objet_26_lampe_magique.png");
        this.objet27LitDefait = require("../images/objet_27_lit_defait.png");
        this.objet28MaletteDocteur = require("../images/objet_28_malette_docteur.png");
        this.objet29Micro = require("../images/objet_29_micro.png");
        this.objet30Piano = require("../images/objet_30_piano.png");
        this.objet31Plume = require("../images/objet_31_plume.png");
        this.objet32PotionMagique = require("../images/objet_32_potion_magique.png");
        this.objet33Pyjama = require("../images/objet_33_pyjama.png");
        this.objet34Regle = require("../images/objet_34_regle.png");
        this.objet35Sablier = require("../images/objet_35_sablier.png");
        this.objet36SacADos = require("../images/objet_36_sac_a_dos.png");
        this.objet37Scooter = require("../images/objet_37_scooter.png");
        this.objet38Sucette = require("../images/objet_38_sucette.png");
        this.objet39Tresor = require("../images/objet_39_tresor.png");
        this.objet40Trousse = require("../images/objet_40_trousse.png");
        this.objet41Valise = require("../images/objet_41_valise.png");
        this.objet42Voiture = require("../images/objet_42_voiture.png");
        this.objet43WagonMagique = require("../images/objet_43_wagon_magique.png");
        this.onboardingPhotos = require("../images/onboarding_photos.png");
        this.pac01PosterLiliEcole = require("../images/pac_01_poster_lili_ecole.png");
        this.pac02ConseilsPourBienCommencer = require("../images/pac_02_conseils_pour_bien_commencer.png");
        this.pac03ConseilsPourLaMaternelle = require("../images/pac_03_conseils_pour_la_maternelle.png");
        this.pac04ConseilsPourLElementaire = require("../images/pac_04_conseils_pour_l_elementaire.png");
        this.pac05ConseilsPourLeCollege = require("../images/pac_05_conseils_pour_le_college.png");
        this.pac06PosterEmotions = require("../images/pac_06_poster_emotions.png");
        this.pac11LiliSurOrdinateur = require("../images/pac_11_lili_sur_ordinateur.png");
        this.pac12PaletteMeteo = require("../images/pac_12_palette_meteo.png");
        this.pac13Webinar = require("../images/pac_13_webinar.png");
        this.pac15NoticeSuperProf = require("../images/pac_15_notice_super_prof.png");
        this.pac18SuperPouvoirsPoster = require("../images/pac_18_super_pouvoirs_poster.png");
        this.personnage012PersonnagesCourent = require("../images/personnage_01_2_personnages_courent.png");
        this.personnage022PersonnagesSEnlacent = require("../images/personnage_02_2_personnages_s_enlacent.png");
        this.personnage032PersonnagesSeDisputent = require("../images/personnage_03_2_personnages_se_disputent.png");
        this.personnage04Cameleon = require("../images/personnage_04_cameleon.png");
        this.personnage05ChatEnColere = require("../images/personnage_05_chat_en_colere.png");
        this.personnage06ChatSouriant = require("../images/personnage_06_chat_souriant.png");
        this.personnage07ChatTriste = require("../images/personnage_07_chat_triste.png");
        this.personnage08Chevalier = require("../images/personnage_08_chevalier.png");
        this.personnage09Coccinelle = require("../images/personnage_09_coccinelle.png");
        this.personnage10Concert = require("../images/personnage_10_concert.png");
        this.personnage11Cowboy = require("../images/personnage_11_cowboy.png");
        this.personnage12Crabe = require("../images/personnage_12_crabe.png");
        this.personnage13Crocodiles = require("../images/personnage_13_crocodiles.png");
        this.personnage14Dindon = require("../images/personnage_14_dindon.png");
        this.personnage15Docteur = require("../images/personnage_15_docteur.png");
        this.personnage16Dragon = require("../images/personnage_16_dragon.png");
        this.personnage17Elephant = require("../images/personnage_17_elephant.png");
        this.personnage18Extraterrestre = require("../images/personnage_18_extraterrestre.png");
        this.personnage19KimonoChapeauAsiatique = require("../images/personnage_19_kimono_chapeau_asiatique.png");
        this.personnage20LeChatEnParachute = require("../images/personnage_20_le_chat_en_parachute.png");
        this.personnage21Licorne = require("../images/personnage_21_licorne.png");
        this.personnage22LiliEnVelo = require("../images/personnage_22_lili_en_velo.png");
        this.personnage23Meduse = require("../images/personnage_23_meduse.png");
        this.personnage24Oie = require("../images/personnage_24_oie.png");
        this.personnage25Pie = require("../images/personnage_25_pie.png");
        this.personnage26Pirate = require("../images/personnage_26_pirate.png");
        this.personnage27Plongeur = require("../images/personnage_27_plongeur.png");
        this.personnage28PoissonRouge = require("../images/personnage_28_poisson_rouge.png");
        this.personnage29Princesse = require("../images/personnage_29_princesse.png");
        this.personnage30RaieTachetee = require("../images/personnage_30_raie_tachetee.png");
        this.personnage31Requin = require("../images/personnage_31_requin.png");
        this.personnage32Sorciere = require("../images/personnage_32_sorciere.png");
        this.personnage33Squelette = require("../images/personnage_33_squelette.png");
        this.personnage34Tortue = require("../images/personnage_34_tortue.png");
        this.personnage35Vampire = require("../images/personnage_35_vampire.png");
        this.photoKo = require("../images/photo_KO.png");
        this.photoOk = require("../images/photo_ok.png");
        this.pictoApprivoiserMesEmotions = require("../images/picto_apprivoiser_mes_emotions.png");
        this.pictoCollectives = require("../images/picto_collectives.png");
        this.pictoCriticalThinking = require("../images/picto_critical_thinking.png");
        this.pictoCroireEnMoi = require("../images/picto_croire_en_moi.png");
        this.pictoDompterErreur = require("../images/picto_dompter_erreur.png");
        this.pictoEmotionsTaming = require("../images/picto_emotions_taming.png");
        this.pictoErrorTaming = require("../images/picto_error_taming.png");
        this.pictoExercerMonSensCritique = require("../images/picto_exercer_mon_sens_critique.png");
        this.pictoFocus = require("../images/picto_focus.png");
        this.pictoLaJouerCollectif = require("../images/picto_la_jouer_collectif.png");
        this.pictoOserAOral = require("../images/picto_oser_a_oral.png");
        this.pictoPoserMonAttention = require("../images/picto_poser_mon_attention.png");
        this.pictoSelfEsteem = require("../images/picto_self_esteem.png");
        this.pictoSpeak = require("../images/picto_speak.png");
        this.pro01Nefaispasdebetises = require("../images/pro_01_nefaispasdebetises.png");
        this.pro02Jefaiscequejeveux = require("../images/pro_02_jefaiscequejeveux.png");
        this.pro03Donnelemoi = require("../images/pro_03_donnelemoi.png");
        this.pro04Depechetoi = require("../images/pro_04_depechetoi.png");
        this.pro05Faisattentionacequetudis = require("../images/pro_05_faisattentionacequetudis.png");
        this.pro06Arretezdevousdisputer = require("../images/pro_06_arretezdevousdisputer.png");
        this.pro07Frereetsoeurcestfacile = require("../images/pro_07_frereetsoeurcestfacile.png");
        this.reg01Grandircaveutdirequoi = require("../images/reg_01_grandircaveutdirequoi.png");
        this.reg02Caveutdirequoietrefort = require("../images/reg_02_caveutdirequoietrefort.png");
        this.reg03Caveutdirequoietreintelligent = require("../images/reg_03_caveutdirequoietreintelligent.png");
        this.reg04Mincejaifaituneerreur = require("../images/reg_04_mincejaifaituneerreur.png");
        this.reg05Limportantcestdeparticiper = require("../images/reg_05_limportantcestdeparticiper.png");
        this.resp01Respirationdelamoustache = require("../images/resp_01_respirationdelamoustache.png");
        this.resp02Leballon = require("../images/resp_02_leballon.png");
        this.resp03Respirationdelamontgolfiere = require("../images/resp_03_respirationdelamontgolfiere.png");
        this.resp04Respirationdusoleil = require("../images/resp_04_respirationdusoleil.png");
        this.rou01NoticeSuperParent = require("../images/rou_01_notice_super_parent.png");
        this.rou04ConseilExpert = require("../images/rou_04_conseil_expert.png");
        this.sch01Lesemotions = require("../images/sch01_lesemotions.png");
        this.sch02Laconfianceensoi = require("../images/sch02_laconfianceensoi.png");
        this.sch03Labienveillance = require("../images/sch03_labienveillance.png");
        this.sch04Lamoquerie = require("../images/sch04_lamoquerie.png");
        this.sch05Apprendredeseserreurs = require("../images/sch05_apprendredeseserreurs.png");
        this.she00ParcoursEntrainement = require("../images/she_00_parcours_entrainement.png");
        this.she01ApprivoiserMesEmotions = require("../images/she_01_apprivoiser_mes_emotions.png");
        this.she02MusclerMaConcentration = require("../images/she_02_muscler_ma_concentration.png");
        this.she03DompterLErreur = require("../images/she_03_dompter_l_erreur.png");
        this.she04OserALOral = require("../images/she_04_oser_a_l_oral.png");
        this.she05ReflechirSurLaVie = require("../images/she_05_reflechir_sur_la_vie.png");
        this.she06LaJouerCollectif = require("../images/she_06_la_jouer_collectif.png");
        this.she07CroireEnSoi = require("../images/she_07_croire_en_soi.png");
        this.slm01DormirLesEtoiles = require("../images/slm_01_dormir_les_etoiles.png");
        this.slm02LeWagonMagique = require("../images/slm_02_le_wagon_magique.png");
        this.slm03LaForetDeLumiere = require("../images/slm_03_la_foret_de_lumiere.png");
        this.slm04Loasis = require("../images/slm_04_loasis.png");
        this.slm05Loiseau = require("../images/slm_05_loiseau.png");
        this.slm06LalbumPhotos = require("../images/slm_06_lalbum_photos.png");
        this.slm07LesTroisPortes = require("../images/slm_07_les_trois_portes.png");
        this.slm08AuCoinDuFeu = require("../images/slm_08_au_coin_du_feu.png");
        this.slm09AuPaysDesNeiges = require("../images/slm_09_au_pays_des_neiges.png");
        this.slm10LaLicorneVolante = require("../images/slm_10_la_licorne_volante.png");
        this.slm11LaLampeDesReves = require("../images/slm_11_la_lampe_des_reves.png");
        this.slm12LesBullesDeSavon = require("../images/slm_12_les_bulles_de_savon.png");
        this.slm13LeSacADosInvisible = require("../images/slm_13_le_sac_a_dos_invisible.png");
        this.slm14LaStarDeCinema = require("../images/slm_14_la_star_de_cinema.png");
        this.slm15LePianoEnchante = require("../images/slm_15_le_piano_enchante.png");
        this.slm16LesMilleCouleurs = require("../images/slm_16_les_mille_couleurs.png");
        this.slm17LaPlongeeFeerique = require("../images/slm_17_la_plongee_feerique.png");
        this.slm18LePhareDesReves = require("../images/slm_18_le_phare_des_reves.png");
        this.slm19LescalierDeFleurs = require("../images/slm_19_lescalier_de_fleurs.png");
        this.slm20LaFluteAerienne = require("../images/slm_20_la_flute_aerienne.png");
        this.spc19LaPhiloDesEnfantsIntroductionGenerale = require("../images/spc_19_la_philo_des_enfants_introduction_generale.png");
        this.spc20LaPhiloDesEnfantsConcevoir = require("../images/spc_20_la_philo_des_enfants_concevoir.png");
        this.spc21LaPhiloDesEnfantsDerivesPointsAttention = require("../images/spc_21_la_philo_des_enfants_derives_points_attention.png");
        this.spc22LaPhiloDesEnfantsAnimerAtelier = require("../images/spc_22_la_philo_des_enfants_animer_atelier.png");
        this.spc23LaPhiloDesEnfantsProcessusPensee = require("../images/spc_23_la_philo_des_enfants_processus_pensee.png");
        this.spc24LaPhiloDesEnfantsQuestionnement = require("../images/spc_24_la_philo_des_enfants_questionnement.png");
        this.twk01LesEmotions = require("../images/twk_01_les_emotions.png");
        this.twk02ApprendreDeSesErreurs = require("../images/twk_02_apprendre_de_ses_erreurs.png");
        this.twk03LaBienveillance = require("../images/twk_03_la_bienveillance.png");
        this.twk04LaConfianceEnSoi = require("../images/twk_04_la_confiance_en_soi.png");
        this.vir01ChansonSansSon = require("../images/vir_01_chanson_sans_son.png");
        this.vir02TroisTortues = require("../images/vir_02_trois_tortues.png");
        this.vir03DodusDindons = require("../images/vir_03_dodus_dindons.png");
        this.vir04SixSaucissons = require("../images/vir_04_six_saucissons.png");
        this.vir05LaPieNicheHaut = require("../images/vir_05_la_pie_niche_haut.png");
        this.vir06ExquisesExcuses = require("../images/vir_06_exquises_excuses.png");
        this.vir07ChatEchappent = require("../images/vir_07_chat_echappent.png");
        this.vir08Totor = require("../images/vir_08_totor.png");
        this.vir09TonThe = require("../images/vir_09_ton_the.png");
        this.vir10SachezSoigner = require("../images/vir_10_sachez_soigner.png");
        this.vir11UnGenereuxDejeuner = require("../images/vir_11_un_genereux_dejeuner.png");
        this.vir12DarioRadieux = require("../images/vir_12_dario_radieux.png");
        this.vir13UnMarinRomain = require("../images/vir_13_un_marin_romain.png");
        this.vir14Passerelles = require("../images/vir_14_passerelles.png");
        this.vir15Matemamoto = require("../images/vir_15_matemamoto.png");
        this.vir16LiliAuLit = require("../images/vir_16_lili_au_lit.png");
        this.vqp01OserSeLancer = require("../images/vqp_01_oser_se_lancer.png");
        this.vqp01OserSeLancerAnimator = require("../images/vqp_01_oser_se_lancer_animator.png");
        this.vqp02GererUnEnfant = require("../images/vqp_02_gerer_un_enfant.png");
        this.vqp02GererUnEnfantAnimator = require("../images/vqp_02_gerer_un_enfant_animator.png");
        this.vqp03GererLaClasse = require("../images/vqp_03_gerer_la_classe.png");
        this.vqp03GererLeGroupeAnimator = require("../images/vqp_03_gerer_le_groupe_animator.png");
        this.vqp04GererLeDebat = require("../images/vqp_04_gerer_le_debat.png");
        this.vqp04GererLeDebatAnimator = require("../images/vqp_04_gerer_le_debat_animator.png");
        this.vqp05AllerPlusLoin = require("../images/vqp_05_aller_plus_loin.png");
        this.vqp06Intro = require("../images/vqp_06_intro.png");
        this.vqp06IntroAnimator = require("../images/vqp_06_intro_animator.png");
        this.vqp07Decouverte = require("../images/vqp_07_decouverte.png");
        this.vqp07DecouverteAnimator = require("../images/vqp_07_decouverte_animator.png");
        this.vqp08FocusMaternelle = require("../images/vqp_08_focus_maternelle.png");
        this.vqp08FocusMaternelleAnimator = require("../images/vqp_08_focus_maternelle_animator.png");
        this.vqp09FocusAdolescents = require("../images/vqp_09_focus_adolescents.png");
        this.vqp09FocusAdolescentsAnimator = require("../images/vqp_09_focus_adolescents_animator.png");
        this.vqp10SensibilisationPpe = require("../images/vqp_10_sensibilisation_PPE.png");
        this.wlb01IntroductionAuProgrammeBienEtre = require("../images/wlb_01_introduction_au_programme_bien_etre.png");
        this.wlb02ProgrammeDecouverte = require("../images/wlb_02_programme_decouverte.png");
        this.wlb03RespirerEtSeDetendre = require("../images/wlb_03_respirer_et_se_detendre.png");
        this.wlb04SAncrerDansLePresent = require("../images/wlb_04_s_ancrer_dans_le_present.png");
        this.wlb05VisualiserPourSeSentirMieux = require("../images/wlb_05_visualiser_pour_se_sentir_mieux.png");
        this.wlb06CroireEnSoi = require("../images/wlb_06_croire_en_soi.png");
        this.wlb07ReduireSonStress = require("../images/wlb_07_reduire_son_stress.png");
        this.wlb08TrouverLeSommeil = require("../images/wlb_08_trouver_le_sommeil.png");
        this.wlb09SeRelaxerEnMusique = require("../images/wlb_09_se_relaxer_en_musique.png");
        this.ydd01Lachouette = require("../images/ydd_01_lachouette.png");
        this.ydd02Lecobra = require("../images/ydd_02_lecobra.png");
        this.ydd03Lechat = require("../images/ydd_03_lechat.png");
        this.ydd04Arbre = require("../images/ydd_04_arbre.png");
        this.ydd05Lamontgolfiere = require("../images/ydd_05_lamontgolfiere.png");
        this.ydd06Lelion = require("../images/ydd_06_lelion.png");
        this.ydd07Arcenciel = require("../images/ydd_07_arcenciel.png");
        this.ydd08Oeuf = require("../images/ydd_08_oeuf.png");
        this.ydd09Flamandrose = require("../images/ydd_09_flamandrose.png");
        this.ydd10LaMaison = require("../images/ydd_10_la_maison.png");
        this.ydd11ACoeurOuvert = require("../images/ydd_11_a_coeur_ouvert.png");
        this.yog01Lachouette = require("../images/yog_01_lachouette.png");
        this.yog02Lechienteteenbas = require("../images/yog_02_lechienteteenbas.png");
        this.yog03Lechat = require("../images/yog_03_lechat.png");
        this.yog04Lecobra = require("../images/yog_04_lecobra.png");
        this.yog05Larbre = require("../images/yog_05_larbre.png");
        this.yog06Bebe = require("../images/yog_06_bebe.png");
        this.yog07Lechameau = require("../images/yog_07_lechameau.png");
        this.yog08Arcenciel = require("../images/yog_08_arcenciel.png");
        this.yog09Lachaiseimaginaire = require("../images/yog_09_lachaiseimaginaire.png");
        this.yog10Lependule = require("../images/yog_10_lependule.png");
        this.yog11Lespetitsvolets = require("../images/yog_11_lespetitsvolets.png");
        this.yog12Latablerenversee = require("../images/yog_12_latablerenversee.png");
        this.yog13Bebeheureux = require("../images/yog_13_bebeheureux.png");
        this.yog14Lespaghetti = require("../images/yog_14_lespaghetti.png");
        this.yog15Loeuf = require("../images/yog_15_loeuf.png");
        this.yog16Lechelle = require("../images/yog_16_lechelle.png");
        this.yog17Lesoleil = require("../images/yog_17_lesoleil.png");
        this.yog18Lamarmotte = require("../images/yog_18_lamarmotte.png");
        this.yog19Leyogadeschampions = require("../images/yog_19_leyogadeschampions.png");
        this.yog20Leyogadescreatifs = require("../images/yog_20_leyogadescreatifs.png");
        this.yog21Leyogadescurieux = require("../images/yog_21_leyogadescurieux.png");
        this.yog22LeYogaDesEmotions = require("../images/yog_22_le_yoga_des_emotions.png");
        this.yog23LeYogaDeLAmitie = require("../images/yog_23_le_yoga_de_l_amitie.png");
        this.yog24LeYogaDeLaNature = require("../images/yog_24_le_yoga_de_la_nature.png");
        this.yog25LeYogaDesCourageux = require("../images/yog_25_le_yoga_des_courageux.png");
        this.yog26LeYogaDuCorps = require("../images/yog_26_le_yoga_du_corps.png");

        return this;
    }
}

export default Images;
