import React, { useEffect, useState, useRef } from "react";

import {
    Text,
    View,
    SafeAreaView,
    FlatList,
    TouchableOpacity,
    Platform,
    StyleSheet,
    ActivityIndicator,
    Modal
} from "react-native";

import Main from "../../../specific/components/Main/Main";
import DynamicBackground from "../DynamicBackground/DynamicBackground";
import MainOptions from "../../models/MainOptions";
import Images from "../../../specific/utils/Images";
import Image from "../../designSystem/OTBImage";

import AppTheme from "../../utils/Theme";
import Style from "../../utils/Style";
import Label from "../../utils/Localization/Label";
import * as DateUtils from "../../utils/DateUtils";

import i18n from "../../utils/Localization/Localization";

import SVGView from "../../utils/SvgView";
import { default as IcHeart } from "../../assets/svg/icons/solid/heart.svg";
import { default as IcGift } from "../../assets/svg/icons/solid/gift.svg";
import { default as IcDownload } from "../../assets/svg/icons/solid/download.svg";
import { default as IcClose } from "../../assets/svg/icons/solid/times.svg";
import { default as IcBan } from "../../assets/svg/icons/solid/ban.svg";
import { default as IcMask } from "../../assets/svg/icons/solid/mask.svg";
import { default as IcSignOut } from "../../assets/svg/lili/ic_logout.svg";
import { default as IcLiliStar } from "../../assets/svg/lili/ic_star.svg";
import { default as IcRainbow } from "../../assets/svg/lili/ic_rainbow.svg";
import { default as IcFilter } from "../../assets/svg/lili/ic_filter.svg";


// DATA
import * as Constants from "../../utils/Constants";

// Services
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import { signOut } from "../../services/Auth";
import { addSubToUser, deleteUser, getCardWithCode, redeemedPremiumCardInDb, revokeDBListeners } from "../../services/Database";
import { wipeData } from "../../utils/LocalStorage";
import { EventRegister } from "../../utils/EventListeners";

import AvatarView from "../Gamification/AvatarView";

import User from "../../data/user/User";
import { useNavigate } from "react-router-dom";
import Alert from "../Notification/Alert";
import AsyncStorage from "@react-native-async-storage/async-storage";
import RainbowPoints from "../UserRewards/RainbowPoints";
import { wipeAsyncStorage, wipeLocalStorage } from "../../../specific/utils/LocalStorage";
import { analyctisEventPageViewed, analyticsEventPaywallDisplayed, deleteAnalyticsUserData, propertiesEventProfile, propertiesEventScreenLabel, propertiesEventSource, trackEvent } from "../../services/Tracking/Analytics";
import { logoutUser } from "../Splashcreen/Splashscreen";
import ActivityFilterByLevel from "../Filters/ActivityFilterByLevel";
import { AppTarget, getAppTarget } from "../../../specific/utils/Navigation/Host";
import Button from "../../designSystem/Button";
import TextInput from "../../designSystem/TextInput/RoundCornerTextInput";
import { addDataToUser } from "../../../specific/services/Specific_Database";
import { PremiumCard } from "../LiliHQ/LHQCreateCode";

const myCardsCell = {
    id: 'gift',
    title: i18n.t('profile.subCards'),
    icon: IcGift,
    color: "#42bc99"
};

const myAccountCell = {
    id: 'account',
    title: i18n.t('profile.myAccount'),
    icon: IcLiliStar,
    color: "#FFB000"
};

const gamificationCell = {
    id: 'badges',
    title: i18n.t('profile.myVictories'),
    icon: IcRainbow,
    color: "#FFB000"
};

const moodCell = {
    id: 'mood',
    title: i18n.t('profile.myWeather'),
    icon: IcRainbow,
    color: "#FFB000"
};

const favoritesCell = {
    id: 'favorites',
    title: i18n.t('profile.myFavorites'),
    icon: IcHeart,
    color: "#bba9fc"
};

const emptyCell = { id: "empty" };

const downloadCell = {
    id: 'downloads',
    title: i18n.t('profile.myDownloads'),
    icon: IcDownload,
    color: "#42bc99"
};

const logoutCell = {
    id: 'logout',
    title: i18n.t('profile.logout'),
    icon: IcSignOut,
    color: "#1C3656"
};

const deleteAccountCell = {
    id: 'delete',
    title: i18n.t('profile.deleteMyAccount'),
    icon: IcBan,
    color: "#DC143C"
};



const UserProfile = (props: any) => {
    const { componentId = "" } = props;
    let navigate: any = null;
    let host = "app.lili.cool";
    if (Platform.OS === "web") {
        navigate = useNavigate();
        host = window.location.hostname;
    }
    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const userInstance: User = User.getInstance();
    const label: Label = new Label();
    const alertRef = useRef<Alert>(null);

    const [viewDidAppear, setViewDidAppear] = useState(false);
    const isTeacher = userInstance.isTeacher();
    const [user, setUser] = useState(userInstance.getUserData());
    const [refresh, setRefresh] = useState(0);
    const [mood, setMood] = useState("happy");
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [filterByLevel, setFilterByLevel] = useState<null | { filters: string[], last_update: number }>(null);
    const [showPremiumCardForm, setShowPremiumCardForm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [premiumCode, setPremiumCode] = useState<string>("");
    const [premiumCodeError, setPremiumCodeError] = useState<string>("");
    const [premiumCodeRedeemed, setPremiumCodeRedeemed] = useState<boolean>(false);

    const headerWidth = appTheme.getFlexDirectionForSplitScreen() === "row" ? appTheme.getFullAppWidth() / 3 : appTheme.getBlockWidth();
    const listWidth = appTheme.getFlexDirectionForSplitScreen() === "row" ? appTheme.getFullAppWidth() - headerWidth : appTheme.getBlockWidth();

    let userDownloadCell = Platform.OS === "web" ? emptyCell : downloadCell;
    let menuData = Platform.OS === "web" ? [
        [
            myAccountCell
        ],
        [
            myCardsCell
        ],
        [
            emptyCell
        ],
        [
            logoutCell
        ],
        [
            emptyCell
        ],
        [
            deleteAccountCell
        ]
    ] : [
        [
            myAccountCell
        ],
        [
            myCardsCell
        ],
        [
            gamificationCell,
            moodCell
        ],
        [
            favoritesCell,
            userDownloadCell
        ],
        [
            emptyCell
        ],
        [
            logoutCell
        ],
        [
            emptyCell
        ],
        [
            deleteAccountCell
        ]
    ]

    if ((host === "app-tne.lili.cool") || (host === "app-tne-demo.lili.cool")) {
        menuData = [[myAccountCell]];
    }

    menuData.splice(2, 0, [{
        id: 'filtersByLevel',
        title: i18n.t('profile.filtersByLevel'),
        icon: IcFilter,
        color: appTheme.schoolColor
    }]);

    if ((userInstance.admin === true) || (userInstance.bizdev === true)) {
        menuData.push([{
            id: 'admin',
            title: i18n.t('profile.admin'),
            icon: IcMask,
            color: "#B11313"
        }])
    }

    // MainOptions
    const mainOptions: MainOptions = new MainOptions({
        key: "user_profile",
        componentId,
        showNavBar: false,
        showMenu: false,
        canBeHidden: false,
        safeArea: false,
        canGoBack: false,
        backgroundColor: isTeacher === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor,
        navigationColor: isTeacher === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor,
        canBeDismissed: false
    });

    const imagesByMood = {
        "happy": images.icMoodHappy,
        "angry": images.icMoodAngry,
        "annoyed": images.icMoodAnnoyed,
        "calme": images.icMoodCalme,
        "sad": images.icMoodSad,
        "stressed": images.icMoodStressed
    }

    useEffect(() => {
        EventRegister.addEventListener(Constants.EventFiltersByLevelChanged, () => {
            getActiveFiltersByLevel();
        });
        EventRegister.addEventListener(Constants.EventUserAuthDB, () => {
            const userData = userInstance.getUserData();
            setUser(userData);
            setRefresh(new Date().getTime());
        });
        EventRegister.addEventListener(Constants.EventMoodFromProfileSelected, () => {
            getMood();
        });
        return () => {
            EventRegister.removeEventListener(Constants.EventUserAuthDB);
            EventRegister.removeEventListener(Constants.EventMoodFromProfileSelected);
            EventRegister.removeEventListener(Constants.EventFiltersByLevelChanged);
        };
    }, []);

    const getMood = async () => {
        const userMood = await AsyncStorage.getItem(Constants.keyMood);
        setMood(userMood);
    }

    const checkSub = async () => {
        if (userInstance.getPremiumStatus() === "free") {
            if (Platform.OS === "web") {
                if ((host === "app-tne.lili.cool") || (host === "app-tne-demo.lili.cool")) {
                    // To do : Check sub from GAR
                }
            } else {
                await userInstance.getUserSubForRC();
                const userData = userInstance.getUserData();
                setUser(userData);
                setRefresh(new Date().getTime());
            }
        }
    }

    const getUserData = async (userAuthData: { email: string | null | undefined, uid: string | undefined } | null) => {

    }

    const openItem = (item: any, isUserSubscriber: boolean) => {
        if (item.id === "account") {
            let showSubPage: boolean = true;
            let isCas = false;
            if (Platform.OS === 'web') {
                const appTarget: AppTarget = getAppTarget();
                if (appTarget !== "public") {
                    showSubPage = false;
                    isCas = true;
                }
            }
            if (showSubPage === true) {
                trackEvent(analyticsEventPaywallDisplayed, { [propertiesEventSource]: "user_profile", [propertiesEventProfile]: user.userProfile() });
                if (props.stripe !== undefined) {
                    Navigation.push({
                        componentId,
                        navigate,
                        name: Constants.ScreenStripeCallback,
                        passProps: {
                            status: props.stripe.status,
                            subId: props.stripe.subId
                        }
                    });
                } else {
                    Navigation.push({
                        componentId,
                        navigate,
                        name: Constants.ScreenSubscription,
                        passProps: {
                            isTeacher,
                            status: "profile"
                        }
                    });
                }
            }
        } else if (item.id === "delete") {
            alertRef.current?.showAlert({
                title: "Suppression de votre compte",
                body: "Vous allez supprimer votre compte, ainsi que tout votre historique.\nVoulez-vous continuer ?",
                primaryButtonTitle: "Confirmer",
                onPressPrimaryButton: deleteUserAccount,
                secondaryButtonTitle: "Annuler"
            });
        } else if (item.id === "logout") {
            alertRef.current?.showAlert({
                title: "Déconnexion",
                body: "Voulez-vous continuer ?",
                primaryButtonTitle: "Confirmer",
                onPressPrimaryButton: onSignOutRequest,
                secondaryButtonTitle: "Annuler"
            });
        } else if (item.id === "mood") {
            Navigation.showModalInView({
                componentId,
                name: Constants.ScreenMood,
                passProps: {
                    context: "userProfile"
                }
            });
        } else if (item.id === "favorites") {
            Navigation.push({
                componentId,
                navigate,
                name: Constants.ScreenUserFavorites
            });
        } else if (item.id === "badges") {
            Navigation.push({
                componentId,
                navigate,
                name: Constants.ScreenUserRewards
            });
        } else if (item.id === "downloads") {
            if (isUserSubscriber === false) {
                let title = "Compte premium seulement";
                let body = "Le téléchargement des activités est réservé aux comptes Premium.";
                alertRef.current?.showAlert({
                    title,
                    body,
                    onPressPrimaryButton: () => openItem({ id: "account" }, isUserSubscriber)
                });
            } else {
                Navigation.push({
                    componentId,
                    navigate,
                    name: Constants.ScreenUserDownloads
                });
            }
        } else if (item.id === "gift") {
            setShowPremiumCardForm(true);
        } else if (item.id === "admin") {
            Navigation.push({
                componentId,
                navigate,
                name: Constants.ScreenSuperAdmin
            });
        } else if (item.id === "filtersByLevel") {
            setShowFilters(true);
        }
    }

    const deleteUserAccount = async () => {
        try {
            await deleteUser({ user_id: user.uid });
            await deleteAnalyticsUserData();
            logout();
        } catch (error) {
            if (error.toString().includes("auth/requires-recent-login") === true) {
                alertRef.current?.showAlert({
                    title: "Suppression de votre compte",
                    body: "Par mesure de sécurité, nous avons besoin que vous confirmier l'e-mail et le mot de passe du compte à supprimer, en vous connectant à nouveau.",
                    onPressPrimaryButton: logout
                });
            } else {
                alertRef.current?.showAlert({
                    title: "Erreur lors de la suppression de votre compte",
                    body: "Veuillez réessayer s'il vous plaît ou non contacter à : support@lili.cool"
                });
            }
        }
    }

    const keyExtractor = (item, index) => {
        return (index.toString());
    }

    const aboutCell = ({ item, index }) => {

        const fullWidthForCell = listWidth - appTheme.pixelPerfect(20);

        let cell = [];
        for (const aCellIndex in item) {
            if (Object.prototype.hasOwnProperty.call(item, aCellIndex)) {
                const aCell = item[parseInt(aCellIndex)];
                const cellWidth = item.length === 1 ? fullWidthForCell : fullWidthForCell / 2 - appTheme.pixelPerfect(5);
                let cellPaddingBottom = 0;
                let isUserSubscriber = userInstance.getPremiumStatus() !== "free";
                if (aCell.id === "empty") {
                    cell.push(<View style={{ width: cellWidth, marginBottom: appTheme.pixelPerfect(20), justifyContent: 'center', alignItems: 'center', borderRadius: appTheme.pixelPerfect(20), marginHorizontal: appTheme.pixelPerfect(5) }} />);
                } else if (aCell.id === "delete") {
                    cell.push(<TouchableOpacity accessibilityRole="button"
                        accessible={true}
                        accessibilityLabel={aCell.title}
                        onPress={() => openItem(aCell, isUserSubscriber)}>
                        <Text style={{ textDecorationLine: "underline", color: "#990000", fontSize: appTheme.pixelPerfectForFont(8), fontFamily: appTheme.primaryBoldFont }}>
                            {aCell.title.toLocaleUpperCase()}
                        </Text>
                    </TouchableOpacity>)
                } else {
                    let cellHeight = item.length === 1 ? appTheme.pixelPerfect(60) : appTheme.pixelPerfect(100);
                    let horizontalAlign = item.length === 1 ? "flex-start" : "flex-end";
                    let iconStyle = { flex: 1, justifyContent: "center" };
                    if (item.length === 1) {
                        iconStyle = { width: appTheme.pixelPerfect(40), justifyContent: "center" };
                    }
                    const cellFlexDirection = item.length === 1 ? "row" : "column";
                    const cellPaddingTop = item.length === 1 ? appTheme.pixelPerfect(5) : appTheme.pixelPerfect(10);
                    cellPaddingBottom = item.length === 1 ? appTheme.pixelPerfect(5) : 0;
                    const cellTextAlign = item.length === 1 ? "left" : "center";
                    const cellTextFontSize = item.length === 1 ? appTheme.pixelPerfectForFont(12) : appTheme.pixelPerfectForFont(6);
                    const cellIconSize = item.length === 1 ? appTheme.pixelPerfect(24) : appTheme.pixelPerfect(24);
                    let subtitleView = <View />;
                    let subscribeButton = <View />;

                    let iconView = <View style={iconStyle}>
                        <View style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), backgroundColor: aCell.color, justifyContent: "center", alignItems: "center" }}>
                            <SVGView Component={aCell.icon} size={cellIconSize} color={appTheme.white} />
                        </View>
                    </View>;

                    if (aCell.id === "account") {
                        cellHeight = appTheme.pixelPerfect(90);
                        let subExpireDateView = <View />;
                        let subscriptionDescription = userInstance.demo !== undefined ? i18n.t("profile.demoVersion") : i18n.t("profile.freeVersion");

                        if (userInstance.getPremiumStatus() !== "free") {
                            const expireDate: number = ((userInstance.sub !== undefined) && (userInstance.sub.expires_date !== undefined)) ? userInstance.sub.expires_date : new Date().getTime();
                            let subExpireDateText = i18n.t("profile.subAvailability", { date: DateUtils.getFullDateForTimestamp(expireDate) });
                            if (userInstance.sub.origin === "gar") {
                                const endYear = new Date(expireDate).getFullYear();
                                const startYear = endYear - 1;
                                const schoolYear = startYear.toString() + " / " + endYear.toString();
                                subExpireDateText = i18n.t("profile.subYearAvailability", { year: schoolYear });
                            }
                            subExpireDateView = <Text style={[{ fontSize: appTheme.pixelPerfectForFont(5), fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue }]}>{subExpireDateText}</Text>;
                            subscriptionDescription = "Version Premium";
                        } else {
                            cellHeight = userInstance.isTeacher() === true ? appTheme.pixelPerfect(110) : appTheme.pixelPerfect(110);
                            const subscriptionButtonTitle: string = ((userInstance.profile === "teacher") || (userInstance.profile === "animator")) ? "Passer Premium" : "Abonner mon super-héros";
                            subscribeButton = <View style={{ width: "100%", alignItems: "flex-end" }}>
                                <View style={{ width: appTheme.pixelPerfect(160), height: appTheme.pixelPerfect(20), borderRadius: appTheme.pixelPerfect(10), backgroundColor: appTheme.premium, justifyContent: "center", alignItems: "center", paddingHorizontal: appTheme.pixelPerfect(10) }}>
                                    <Text style={{ color: appTheme.white, fontFamily: appTheme.primaryBoldFont, textTransform: "uppercase" }}>{subscriptionButtonTitle}</Text>
                                </View>
                            </View>;
                        }
                        let emailAndNameString = "";
                        if (((user.firstname.length > 0) && (user.lastname.length > 0))) {
                            emailAndNameString = user.firstname + " " + user.lastname.toLocaleUpperCase();
                        }
                        if ((user.email.length > 0) && (user.email !== "noreply")) {
                            if (emailAndNameString.length > 0) {
                                emailAndNameString = emailAndNameString + " - ";
                            }
                            emailAndNameString = emailAndNameString + user.email;
                        }
                        let nameView = (emailAndNameString.length > 0) ? <Text style={[{ fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(8) }]}>{emailAndNameString}</Text> : <View />;
                        subtitleView = <View style={{ flex: 1 }}>
                            {nameView}
                            <Text style={[{ fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(8) }]}>{subscriptionDescription}</Text>
                            {subExpireDateView}
                        </View>;
                    }
                    if (aCell.id === "gift") {
                        const cellSize = (cellWidth - cellIconSize - appTheme.pixelPerfect(60));
                        cellHeight = appTheme.pixelPerfect(80);
                        subtitleView = <View>
                            <Text numberOfLines={2} style={[{ width: cellSize, fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(8) }]}>{i18n.t("profile.subCardsBody")}</Text>
                        </View>
                    }
                    if (aCell.id === "filtersByLevel") {
                        if (filterByLevel === null) {
                            subtitleView = <ActivityIndicator color={appTheme.schoolColor} />;
                        } else {
                            const { filters } = filterByLevel;
                            const levelNameByKey = Constants.filtres;
                            const cellSize = (cellWidth - cellIconSize - appTheme.pixelPerfect(60))
                            let filtersSelectedText = "Tous les niveaux sélectionnés";
                            if ((filters.length > 0) && (filters.length < 3)) {
                                if (filters.length === 1) {
                                    filtersSelectedText = levelNameByKey[filters[0]];
                                } else {
                                    filtersSelectedText = "";
                                    if (filters.indexOf("cycle1") !== -1) {
                                        filtersSelectedText = levelNameByKey["cycle1"];
                                    }
                                    if (filters.indexOf("cycle2") !== -1) {
                                        if (filtersSelectedText.length > 0) {
                                            filtersSelectedText = filtersSelectedText + ", ";
                                        }
                                        filtersSelectedText = filtersSelectedText + levelNameByKey["cycle2"];
                                    }
                                    if (filters.indexOf("cycle3") !== -1) {
                                        if (filtersSelectedText.length > 0) {
                                            filtersSelectedText = filtersSelectedText + ", ";
                                        }
                                        filtersSelectedText = filtersSelectedText + levelNameByKey["cycle3"];
                                    }
                                }
                            }
                            subtitleView = <View >
                                <Text style={[{ fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(8), width: cellSize }]}>{filtersSelectedText}</Text>
                            </View>
                        }
                    }
                    let textColor = appTheme.darkBlue;
                    let backgroundColor = appTheme.white;
                    if (aCell.id === "mood") {
                        iconView = <View style={{ width: appTheme.pixelPerfect(56), height: appTheme.pixelPerfect(56), borderRadius: appTheme.pixelPerfect(28), backgroundColor: appTheme.daySkyColor, justifyContent: "center", alignItems: "center" }}>
                            <Image source={imagesByMood[mood]} style={{ width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30) }} resizeMode="contain" />
                        </View>;
                    } else if (aCell.id === "badges") {
                        iconView = <RainbowPoints onPress={() => openItem(aCell, isUserSubscriber)} user={user} userProfile={true} />
                    } else if ((aCell.id === "downloads") && (isUserSubscriber === false)) {
                        iconView = <View style={{ flex: 1, justifyContent: "center" }}><View style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), backgroundColor: aCell.color, justifyContent: "center", alignItems: "center" }}><SVGView Component={IcLiliStar} size={cellIconSize} color={appTheme.white} /></View></View>;
                    } else if (aCell.id === "admin") {
                        iconView = <View style={{ width: appTheme.pixelPerfect(56), height: appTheme.pixelPerfect(56), borderRadius: appTheme.pixelPerfect(28), backgroundColor: appTheme.daySkyColor, justifyContent: "center", alignItems: "center" }}>
                            <Image source={images.illSupercatStanding} style={{ width: appTheme.pixelPerfect(52), height: appTheme.pixelPerfect(52) }} resizeMode="contain" />
                        </View>;
                    }
                    cell.push(
                        <TouchableOpacity
                            accessibilityRole="button"
                            accessible={true}
                            accessibilityLabel={aCell.title}
                            onPress={() => openItem(aCell, isUserSubscriber)}
                            style={[Style.shadowed, { width: cellWidth, justifyContent: "center", alignItems: 'center', borderRadius: appTheme.pixelPerfect(20), marginHorizontal: appTheme.pixelPerfect(5) }]}>
                            <View style={{ flexDirection: cellFlexDirection, width: cellWidth, height: cellHeight, backgroundColor: backgroundColor, borderRadius: appTheme.pixelPerfect(20), alignItems: 'center', justifyContent: horizontalAlign, paddingHorizontal: appTheme.pixelPerfect(10), paddingTop: cellPaddingTop, paddingBottom: cellPaddingBottom }}>
                                {iconView}
                                <View style={{ padding: appTheme.pixelPerfect(10), justifyContent: 'center', flex: 1 }}>
                                    <Text style={{ textAlign: cellTextAlign, fontFamily: appTheme.secondaryFont, fontSize: cellTextFontSize, color: textColor }}>
                                        {aCell.title.toLocaleUpperCase()}
                                    </Text>
                                    {subtitleView}
                                    {subscribeButton}
                                </View>
                            </View>
                        </TouchableOpacity>
                    )
                }
            }
        }
        return <View style={{ flexDirection: "row", width: listWidth, justifyContent: "center", alignItems: "center", marginBottom: appTheme.pixelPerfect(10) }}>
            {cell}
        </View>;
    }

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }

    const onCloseRequest = () => {
        Navigation.dismissModalInView({
            componentId
        });
    }

    const onSignOutRequest = () => {
        logout();
    }

    const logout = async () => {
        logoutUser();
        await wipeLocalStorage();
        revokeDBListeners();
        await signOut();
        await wipeData();
        Navigation.relaunchApp({
            componentId,
            navigate
        });
    }

    useEffect(() => {
        if (premiumCode.length > 0) {
            if (showPremiumCardForm === false) {
                setShowPremiumCardForm(true);
            }
        }
    }, [premiumCode]);

    const onViewDidAppear = async () => {
        if (viewDidAppear === false) {
            trackEvent(analyctisEventPageViewed, { [propertiesEventScreenLabel]: "user_profile" })
        }
        checkSub();
        getMood();
        await getActiveFiltersByLevel();
        await getUserData({ email: user.email, uid: user.uid });
        if ((props.path !== undefined) && (props.path.length > 0)) {
            if (props.path === "sub") {
                openItem({ id: "account" }, false);
            } else if (props.path.includes("cardActivation")) {
                const pathArray: string[] = props.path.split("/");
                if (pathArray[pathArray.length-1] !== "cardActivation") {
                    setPremiumCode(pathArray[pathArray.length-1]);
                } else {
                    setShowPremiumCardForm(true);
                }
            }
        }
        setViewDidAppear(true);
    }

    const getActiveFiltersByLevel = () => {
        setFilterByLevel({ filters: user.filter_by_level, last_update: new Date().getTime() });
    }

    const mainViewAlignItems = appTheme.getFlexDirectionForSplitScreen() === "row" ? "flex-start" : "center";

    const getProfileContent = () => {
        if (viewDidAppear === false) {
            return <ActivityIndicator color={appTheme.darkBlue} />
        }
        const titleColor = user.isTeacher() === true ? appTheme.darkBlue : appTheme.getLiliAtHomeTitleColor();
        return <View style={{ flex: 1, alignItems: mainViewAlignItems, width: appTheme.getFullAppWidth(), paddingTop: appTheme.pixelPerfect(30), flexDirection: appTheme.getFlexDirectionForSplitScreen() }}>
            <View style={{ width: headerWidth, justifyContent: "flex-start", alignItems: "center" }}>
                <AvatarView refresh={refresh} />
                <Text
                    accessible={true}
                    accessibilityLabel={i18n.t("profile.title")}
                    accessibilityRole="text"
                    style={[{ textAlign: 'center', fontSize: appTheme.pixelPerfectForFont(14) }, { paddingTop: appTheme.pixelPerfect(10), color: titleColor }]}>{i18n.t("profile.title").toLocaleUpperCase()}</Text>
            </View>
            <FlatList
                data={menuData}
                extraData={filterByLevel}
                renderItem={aboutCell}
                keyExtractor={keyExtractor}
                style={{ flex: 1, width: listWidth, marginTop: appTheme.pixelPerfect(10) }} />
        </View>
    }

    const redeemedCard = async () => {
        // On va récupérer le détail de la carte et la valider
        setPremiumCode("");
        try {
            const card: PremiumCard = await getCardWithCode({ code: premiumCode });
            if (card === undefined) {
                setPremiumCodeError("Aucune carte PREMIUM ne correspond à ce code.");
            } else {
                if (card.status !== "toBeRedeemed") {
                    setPremiumCodeError("Ce code a déjà été utilisé.");
                } else {
                    // On va ajouter l'abonnement à l'utilisateur
                    await addSubToUser({ user_id: userInstance.uid, sub_id: card.product_id, duration_in_month: card.duration, origin: card.origin, card_id: card.code, offer: card.offer });
                    await redeemedPremiumCardInDb({ card, user: userInstance });
                    setPremiumCodeRedeemed(true);
                }
            }
        } catch (error) {
            setPremiumCodeError("Une erreur est survenue")
        }
    }

    const getCardDetailsModalContent = () => {
        if (showPremiumCardForm === false) {
            return <View />
        }
        const cardTitle = i18n.t("subCards.modal.form.title");
        if (premiumCodeRedeemed === true) {
            return <View style={{ flex: 1, width: appTheme.getFullAppWidth(), backgroundColor: "#00000090", alignItems: "center", justifyContent: "flex-end" }}>
                <View style={[Style.shadowed, { paddingVertical: appTheme.pixelPerfect(30), paddingHorizontal: appTheme.pixelPerfect(20), width: appTheme.getFullAppWidth(), backgroundColor: appTheme.white, alignItems: "center", justifyContent: "flex-end", borderTopEndRadius: appTheme.pixelPerfect(10), borderTopStartRadius: appTheme.pixelPerfect(10) }]}>
                    <Text style={{ fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(12), color: appTheme.darkBlue, paddingVertical: appTheme.pixelPerfect(10), textAlign: "center" }}>{cardTitle}</Text>
                    <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: appTheme.darkBlue, paddingBottom: appTheme.pixelPerfect(10), textAlign: "center" }}>{i18n.t("subCards.modal.form.redeemed")}</Text>
                    <Button
                        link={true}
                        isLoading={isLoading}
                        onPress={() => setShowPremiumCardForm(false)}
                        title={i18n.t("subCards.modal.form.close")}
                        style={{ minWidth: appTheme.pixelPerfect(200), marginBottom: appTheme.pixelPerfect(20) }} />
                </View>
            </View>;
        }
        if (userInstance.getPremiumStatus() === "free") {
            let cardSubtitle = i18n.t("subCards.modal.form.body");
            let closeButtonTitle = i18n.t("subCards.modal.form.nothingToDo");
            const errorTextView = premiumCodeError.length === 0 ? <View /> : <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: appTheme.errorColor, paddingBottom: appTheme.pixelPerfect(10), textAlign: "center" }}>{premiumCodeError}</Text>;
            return <View style={{ flex: 1, width: appTheme.getFullAppWidth(), backgroundColor: "#00000090", alignItems: "center", justifyContent: "flex-end" }}>
                <View style={[Style.shadowed, { paddingVertical: appTheme.pixelPerfect(30), paddingHorizontal: appTheme.pixelPerfect(20), width: appTheme.getFullAppWidth(), backgroundColor: appTheme.white, alignItems: "center", justifyContent: "flex-end", borderTopEndRadius: appTheme.pixelPerfect(10), borderTopStartRadius: appTheme.pixelPerfect(10) }]}>
                    <Text style={{ fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(12), color: appTheme.darkBlue, paddingVertical: appTheme.pixelPerfect(10), textAlign: "center" }}>{cardTitle}</Text>
                    <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: appTheme.darkBlue, paddingBottom: appTheme.pixelPerfect(10), textAlign: "center" }}>{cardSubtitle}</Text>
                    <TextInput value={premiumCode} onChangeText={(text) => setPremiumCode(text)} style={{ width: appTheme.pixelPerfect(200) }} />
                    {errorTextView}
                    <Button
                        isLoading={isLoading}
                        onPress={() => redeemedCard()}
                        title={i18n.t("subCards.modal.form.active")}
                        style={{ width: appTheme.pixelPerfect(200), marginBottom: appTheme.pixelPerfect(20) }} />
                    <Button
                        link={true}
                        isLoading={isLoading}
                        onPress={() => setShowPremiumCardForm(false)}
                        title={closeButtonTitle}
                        style={{ minWidth: appTheme.pixelPerfect(200), marginBottom: appTheme.pixelPerfect(20) }} />
                </View>
            </View>;
        }
        return <View style={{ flex: 1, width: appTheme.getFullAppWidth(), backgroundColor: "#00000090", alignItems: "center", justifyContent: "flex-end" }}>
            <View style={[Style.shadowed, { paddingVertical: appTheme.pixelPerfect(30), paddingHorizontal: appTheme.pixelPerfect(20), width: appTheme.getFullAppWidth(), backgroundColor: appTheme.white, alignItems: "center", justifyContent: "flex-end", borderTopEndRadius: appTheme.pixelPerfect(10), borderTopStartRadius: appTheme.pixelPerfect(10) }]}>
                <Text style={{ fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(12), color: appTheme.darkBlue, paddingVertical: appTheme.pixelPerfect(10), textAlign: "center" }}>{cardTitle}</Text>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: appTheme.darkBlue, paddingBottom: appTheme.pixelPerfect(10), textAlign: "center" }}>{i18n.t("subCards.modal.already")}</Text>
                <Button
                    link={true}
                    isLoading={isLoading}
                    onPress={() => setShowPremiumCardForm(false)}
                    title={i18n.t("subCards.modal.form.close")}
                    style={{ minWidth: appTheme.pixelPerfect(200), marginBottom: appTheme.pixelPerfect(20) }} />
            </View>
        </View>;
    }

    const illustrationPosition = Platform.OS === "web" ? "fixed" : "absolute";
    const illustrationHeight = Platform.OS === "web" ? appTheme.getFullAppWidth() / 9 : appTheme.getFullAppWidth() * 2 / 9;
    const pictoIllustrationHeight = Platform.OS === "web" ? appTheme.pixelPerfect(160) : appTheme.pixelPerfect(80) + appTheme.topInsets;
    const illustationPaddingBottom = Platform.OS === "web" ? appTheme.pixelPerfect(160) : appTheme.pixelPerfect(80) + appTheme.topInsets;
    const backgroundPosition: string = Platform.OS === "web" ? "fixed" : "absolute";

    return (
        <Main mainOptions={mainOptions}>
            <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), position: backgroundPosition, backgroundColor: appTheme.daySkyColor }}>
                <Image source={images.bgDayClouds} style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), position: "absolute", opacity: 1 }} resizeMode="contain" />
            </View>
            <View style={{ position: illustrationPosition, bottom: 0, right: 0, left: 0, paddingBottom: illustationPaddingBottom }}>
                <View style={{ width: appTheme.getFullAppWidth(), height: pictoIllustrationHeight + appTheme.pixelPerfect(10), backgroundColor: "#00BC98", position: "absolute", bottom: 0 }} />
                <View style={{ width: appTheme.getFullAppWidth(), height: illustrationHeight }}>
                    <Image source={images.illDayGround} style={{ position: "absolute", width: appTheme.getFullAppWidth(), height: illustrationHeight, opacity: 1 }} resizeMode="cover" />
                </View>
            </View>
            <SafeAreaView onLayout={onViewDidAppear} style={{ flex: 1, alignItems: "center" }}>
                {getProfileContent()}
            </SafeAreaView>
            <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.topInsets + appTheme.pixelPerfect(44), position: "absolute", top: 0, alignItems: "center", flexDirection: "row", justifyContent: "flex-end", paddingHorizontal: appTheme.pixelPerfect(10), paddingTop: appTheme.topInsets }}>
                <TouchableOpacity onPress={onCloseRequest} style={[styles.logoutButton, { backgroundColor: appTheme.white, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(15) }]}>
                    <SVGView Component={IcClose} size={appTheme.pixelPerfect(18)} color={appTheme.darkBlue} />
                </TouchableOpacity>
            </View>
            <Modal
                transparent={true}
                animationType="fade"
                visible={showFilters}
                style={{ flex: 1, justifyContent: "flex-end" }}>
                <ActivityFilterByLevel backgroundColor={appTheme.schoolColor} toggleFilters={toggleFilters} selected={filterByLevel?.filters} />
            </Modal>
            <Modal
                style={{ flex: 1 }}
                transparent={true}
                presentationStyle="overFullScreen"
                animationType="slide"
                visible={showPremiumCardForm}>
                <View style={{ flex: 1, width: appTheme.getFullScreenWidth(), alignItems: "center", justifyContent: "center" }}>
                    {getCardDetailsModalContent()}
                </View>
            </Modal>
            <Alert ref={alertRef} />
        </Main>
    )
}

export default UserProfile;

const styles = StyleSheet.create({
    logoutButton: {
        justifyContent: "center",
        alignItems: "center"
    }
});