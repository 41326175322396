import React, { useEffect, useState } from "react";

import {
    Text,
    View,
    SafeAreaView,
    FlatList,
    TouchableOpacity,
    Platform,
    Linking
} from "react-native";

import Image from "../../designSystem/OTBImage";

import Main from "../../../specific/components/Main/Main";
import DynamicBackground from "../DynamicBackground/DynamicBackground";
import MainOptions from "../../models/MainOptions";
import Images from "../../../specific/utils/Images";
import AppTheme from "../../utils/Theme";
import Label from "../../utils/Localization/Label";

import i18n from "../../utils/Localization/Localization";

import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import * as Constants from "../../utils/Constants";

import SVGView from "../../utils/SvgView";
import { default as IcChevronRight } from "../../assets/svg/icons/solid/chevron-right.svg";

import { version, build } from "../../../../package.json";
import User from "../../data/user/User";
import { useNavigate, useParams } from "react-router-dom";
import { getAppTarget } from "../../../specific/utils/Navigation/Host";
import AboutDescription from "./AboutDescription";
import Modal from "../../../specific/components/Modal/Modal";

import config from "../../config/index";

const menuData = [
    {
        id: 'legal',
        name: 'legal',
        title: i18n.t('about.legalMentions')
    },
    {
        id: 'personal_data',
        name: 'personal_data',
        title: i18n.t('about.personalData')
    },
    {
        id: 'contact',
        name: 'contact',
        title: i18n.t('about.contact')
    }
]

const About = (props: any) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const label: Label = new Label();
    const user: User = User.getInstance();

    const [isTeacher, setIsTeacher] = useState(props.isTeacher);
    const [showFullDescription, setShowFullDescription] = useState<boolean>(false);

    let propsData = props;
    let navigate = null;
    if (Platform.OS === "web") {
        propsData = useParams();
        navigate = useNavigate();
    }

    // MainOptions
    const { componentId = "" } = props;
    const navigationColor = isTeacher === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor;
    const mainOptions: MainOptions = new MainOptions({
        key: "about",
        componentId,
        navigationColor,
        showNavBar: false,
        showMenu: false,
        canBeHidden: true,
        safeArea: false,
        canGoBack: false,
        backgroundColor: isTeacher === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor,
        canBeDismissed: false
    });

    useEffect(() => {

    }, []);



    const openItem = (item) => {
        const appTarget = getAppTarget();
        if (item.id === "contact") {
            if (appTarget === "gar-prod") {
                const contactUrlForGar = "https://sortie-confiance.gar.education.fr?idRessource=ark%3A%2F19153%2Frcqbq94w21%2Fs3&IDO=" + user.tne_ido + "&urlDest=https%3A%2F%2Fwww.lili.cool%2Ffr%2Fcontact%2F";
                Linking.openURL(contactUrlForGar);
            } else {
                Navigation.push({
                    componentId,
                    navigate,
                    name: Constants.ScreenContact
                });
            }
        } else if (item.name === "legal") {
            if (appTarget === "public") {
                Navigation.showModalInView({
                    componentId,
                    name: Constants.ScreenLegalMentions,
                    passProps: {
                        reference: "legal"
                    }
                });
            } else {
                Linking.openURL("https://www.lili.cool/tne/Lili_mentions_legales_GAR.pdf");
            }
        } else {
            if (appTarget === "public") {
                const name = Constants.ScreenConsentNotification;
                const passProps = {
                    context: "about"
                };
                Navigation.showModalInView({
                    componentId,
                    name,
                    passProps,
                    options: {
                        modalPresentationStyle: 'overCurrentContext',
                        layout: {
                            componentBackgroundColor: 'transparent'
                        }
                    }
                });
            } else {
                Linking.openURL("https://lili.cool/tne/Lili_politique_de_confidentialite_GAR.pdf");
            }
        }
    }

    const keyExtractor = (item, index) => {
        return (index.toString());
    }

    const onViewDidAppear = () => {
    }

    const aboutCell = ({ item, index }) => {
        if (item.id === 'space') {
            return (
                <View style={{ height: appTheme.pixelPerfect(0) }} />
            )
        }
        return (
            <TouchableOpacity
                accessibilityRole="button"
                accessible={true}
                accessibilityLabel={item.title}
                onPress={() => openItem(item)}
                style={{ width: appTheme.getFullAppWidth(), paddingBottom: appTheme.pixelPerfect(10), justifyContent: 'center', alignItems: 'center' }}>
                <View style={{ flexDirection: 'row', width: appTheme.pixelPerfect(230), height: appTheme.pixelPerfect(42), backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(21), alignItems: 'center', paddingHorizontal: appTheme.pixelPerfect(10) }}>
                    <View style={{ flex: 1, paddingLeft: appTheme.pixelPerfect(10), justifyContent: 'space-around' }}>
                        <Text style={{ color: appTheme.darkBlue, fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(10) }}>
                            {item.title.toLocaleUpperCase()}
                        </Text>
                    </View>
                    <View style={{ marginLeft: appTheme.pixelPerfect(5), backgroundColor: appTheme.daySkyColor + "50", height: appTheme.pixelPerfect(26), width: appTheme.pixelPerfect(26), borderRadius: appTheme.pixelPerfect(13), justifyContent: 'center', alignItems: 'center' }}>
                        <SVGView Component={IcChevronRight} size={appTheme.pixelPerfect(10)} color={appTheme.darkBlue} />
                    </View>
                </View>
            </TouchableOpacity>
        )
    }

    const toggleDescription = () => {
        setShowFullDescription(true);
    }

    const textColor = user.profile === "family" ? appTheme.white : appTheme.darkBlue;
    console.log({textColor});
    return (
        <Main mainOptions={mainOptions}>
            <DynamicBackground isTeacher={isTeacher} />
            <SafeAreaView onLayout={onViewDidAppear} style={{ flex: 1, alignItems: "center" }}>
                <Image source={images.icAbout} style={{ width: appTheme.pixelPerfect(70), height: appTheme.pixelPerfect(70) }} resizeMode="contain" />
                <Text
                    accessible={true}
                    accessibilityLabel={label.about.title}
                    accessibilityRole="text"
                    style={[{ textAlign: 'center', fontSize: appTheme.pixelPerfectForFont(14), color: textColor }]}>{label.about.title.toLocaleUpperCase()}</Text>
                <View style={{alignItems:"center", paddingTop:appTheme.pixelPerfect(20)}}>
                    <Text
                        numberOfLines={4}
                        accessible={true}
                        accessibilityRole="text"
                        style={[{ textAlign: 'center', paddingHorizontal:appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(8), color: textColor }]}>{i18n.t("about.description")[0]}</Text>
                    <TouchableOpacity onPress={toggleDescription} style={{ padding: appTheme.pixelPerfect(10) }}>
                        <Text style={[{ paddingTop: 0, fontSize:appTheme.pixelPerfectForFont(8), color: appTheme.darkBlue, fontFamily: appTheme.secondaryFont, textDecorationLine: "underline", marginBottom: appTheme.pixelPerfect(10) }]}>
                            {i18n.t("subscription.buttons.more")}
                        </Text>
                    </TouchableOpacity>
                </View>
                <FlatList
                    data={menuData}
                    renderItem={aboutCell}
                    keyExtractor={keyExtractor}
                    style={{ flex: 1, paddingVertical: appTheme.pixelPerfect(10) }} />
                <View style={{ marginBottom: appTheme.bottomInsets + appTheme.pixelPerfect(10) }}>
                    <Text style={{ color: textColor }}>
                        {i18n.t("app.title", { app_name:config.appName, version, build })}
                    </Text>
                </View>
                <Modal
                    style={{ flex: 1, width: appTheme.getFullScreenWidth(), justifyContent: 'center', alignItems: "center" }}
                    transparent={true}
                    animationType={Platform.OS === "web" ? "fade" : "slide"}
                    visible={showFullDescription}>
                    <AboutDescription onCloseRequest={() => setShowFullDescription(false)} />
                </Modal>
            </SafeAreaView>
        </Main>
    )
}

export default About;